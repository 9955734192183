import { useContext, useState } from "react";
import { AccountContext, ModalProfile } from "@svt/duo-shared-components";
import duoCom from "@svt/duo-communication";
import { UserType } from "@svt/duo-communication/dist/api/highscore-types";

import {
    AccountContextType,
    LoggedInAmigoType,
    ToplistRowType,
    ToplistUserRowType,
} from "../../../types";

export default function Row({
    user,
    rank,
    modifyPoints,
}: {
    user:
        | ToplistRowType
        | (ToplistUserRowType & LoggedInAmigoType & { isTeam: never })
        | (UserType & { isTeam: never } & {
              firstName: never;
              lastname: never;
          } & {
              avatar: never;
          });
    rank: number;
    modifyPoints?: boolean;
}) {
    const accountContext: AccountContextType = useContext(AccountContext);

    const [modalProfileState, setModalProfileState] = useState<{
        show: boolean;
        userData: ToplistRowType | null;
    }>({
        show: false,
        userData: null,
    });

    function showProfile(profile: ToplistRowType) {
        setModalProfileState({
            show: true,
            userData: profile,
        });
    }

    function hide() {
        setModalProfileState({ show: false, userData: null });
    }

    const isMe = accountContext.userData.amigo?.userId === user.userId;

    if (isMe) {
        //@ts-expect-error - FIX type
        user = { ...user, ...accountContext.userData.amigo };
    }
    return (
        <>
            <li
                className={`history-toplist__list-item ${
                    isMe ? "-is-me" : "-is-not-me"
                } ${rank === 1 ? "-first" : ""}`}
                key={rank}
            >
                <button
                    className="history-toplist__list-button"
                    type="button"
                    aria-label="visa användarinformation"
                    onClick={() => {
                        if (
                            !user.isTeam &&
                            accountContext.userData.isLoggedIn
                        ) {
                            showProfile(user);
                        }
                    }}
                >
                    <div className="history-toplist__list-col-user">
                        <div className="history-toplist__rank">{rank}</div>
                        <div
                            style={{
                                backgroundColor:
                                    user.profile?.color ?? "#45346B",
                            }}
                            className="history-toplist__list-image-wrapper"
                        >
                            <img
                                alt=""
                                src={
                                    user.avatar ??
                                    duoCom.Amigo.getAvatarUrlForUnoId({
                                        unoId: user.userId,
                                    })
                                }
                                className="history-toplist__image-list"
                            />
                        </div>

                        <div className="history-toplist__list-names">
                            <div
                                className={`history-toplist__list-username ${
                                    isMe ? "-is-me" : "-is-not-me"
                                }`}
                            >
                                {user.username}
                            </div>

                            {(user.firstName || user.lastName) && (
                                <div className="history-toplist__list-real-name">
                                    {user.firstName} {user.lastName}
                                </div>
                            )}
                        </div>
                    </div>

                    <div
                        className={`history-toplist__list-col-total-score ${
                            modifyPoints && "-modifyPoints"
                        }`}
                    >
                        {modifyPoints
                            ? (user.score / 1000).toFixed(2)
                            : user.score}
                    </div>
                </button>
            </li>
            {modalProfileState.show && (
                <ModalProfile
                    closeModal={() => {
                        hide();
                    }}
                    stateModalShow={modalProfileState.show}
                    userData={modalProfileState.userData}
                />
            )}
        </>
    );
}
