import { useSelector } from "react-redux";

import { AchievementType } from "../../../redux/reducers/unlockedAchievements";
import { getQuestionIndex } from "../../../helpers/questionHelpers";
import { isEpisodeAchievement } from "../../useUnlockAchievement";
const seenAchievement: Record<string, number> = {};

export default function QuestionAnswerPointsUnlockedAchievements() {
    const unlockedAchievements = useSelector(
        (state: {
            unlockedAchievements: {
                [key: string]: AchievementType;
            };
        }) => state.unlockedAchievements,
    );

    const questionIndex = getQuestionIndex();

    const episodeAchievementsToShow = Object.keys(unlockedAchievements).reduce(
        (accumulator: string[], achievementCode) => {
            if (
                questionIndex !== null &&
                isEpisodeAchievement(achievementCode) &&
                !seenAchievementBefore(achievementCode)
            ) {
                accumulator.push(achievementCode);
            }

            return accumulator;
        },
        [],
    );

    function seenAchievementBefore(achievementCode: string) {
        if (
            !seenAchievement[achievementCode] ||
            seenAchievement[achievementCode] === questionIndex
        ) {
            return false;
        } else {
            return true;
        }
    }

    if (episodeAchievementsToShow.length === 0) {
        return null;
    }

    return (
        <div className="answerPointsUnlockedAchievements__wrapper">
            <div className="answerPointsUnlockedAchievements__heading">
                Nya prestationer du låst upp
            </div>
            <ul className="answerPointsUnlockedAchievements__list">
                {episodeAchievementsToShow.map((achievementCode) => {
                    seenAchievement[achievementCode] = questionIndex as number; //We check before that it is not null

                    return (
                        <li
                            className="answerPointsUnlockedAchievements__list-item"
                            key={achievementCode}
                        >
                            <div>
                                {unlockedAchievements[achievementCode]
                                    .image && (
                                    <div>
                                        <img
                                            className="answerPointsUnlockedAchievements__image"
                                            src={
                                                unlockedAchievements[
                                                    achievementCode
                                                ].image
                                            }
                                        />
                                    </div>
                                )}

                                <div className="answerPointsUnlockedAchievements__text">
                                    {
                                        unlockedAchievements[achievementCode]
                                            .title
                                    }
                                </div>
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}
