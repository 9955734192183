import React from "react";
import PropTypes from "prop-types";

import AnswerBoxMarkup from "../../shared/answerBox";

export default class CityWaiting extends React.Component {
    render() {
        const { answer } = this.props.questionState;

        return (
            <div className="svt_city-waiting">
                <h1 className="svt_city-waiting__header">
                    Väntar på svar från Fredrik...
                </h1>

                <AnswerBoxMarkup
                    headText="Du svarade"
                    modifier="-waiting"
                    hasAnswered={false}
                    bodyText={answer}
                    isCorrect={null}
                    correctAnswer={null}
                />
            </div>
        );
    }
}

CityWaiting.propTypes = {
    questionState: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
};
