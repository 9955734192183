import React, {
    useEffect,
    useContext,
    useState,
    useRef,
    useCallback,
} from "react";
import duoCom from "@svt/duo-communication";
import LViS from "@monterosa/lvis-api";
import { AccountContext } from "@svt/duo-shared-components";
import { HighscoreFetchResponseType } from "@svt/duo-communication/dist/api/highscore-types";
import * as Sentry from "@sentry/react";
import { useDispatch, useSelector } from "react-redux";

import useGeography from "../startScreen/history/useGeography";
import { AccountContextType } from "../../../types";
import ContentStatus from "../../shared/contentStatus";
import Episode from "../../shared/toplist/episode";
import useGetScore from "../../../helpers/useGetScore";

const GeographyToplist = () => {
    const isSendingHighscoreRef = useRef(false);
    const dispatch = useDispatch();
    const hasSentHighscore = useSelector(
        (state: { hasSentHighscore: boolean }) => state.hasSentHighscore,
    );
    const score = useGetScore();

    const accountContext: AccountContextType = useContext(AccountContext);
    const [stateHighscore, setStateHighscore] = useState<{
        episodeHighscore:
            | HighscoreFetchResponseType["episodes"][0]
            | Record<string, never>;
        loaded: boolean;
        error: boolean;
    }>({
        episodeHighscore: {},
        loaded: false,
        error: false,
    });

    const [
        sateShowPostalCodeNotSubmittedMessage,
        setSateShowPostalCodeNotSubmittedMessage,
    ] = useState(false);

    const fetchHighscore = useCallback(() => {
        duoCom.Highscore.fetch({
            season: LViS.Project.getSettings().season_number.toString(),
            episode: LViS.getEvent()
                .getCustomFields()
                .episode_number.toString(),
            postal_code:
                accountContext.userData.amigo?.demographic?.postal_code ??
                undefined,
        })
            .then((response: HighscoreFetchResponseType) => {
                setStateHighscore((currentState) => ({
                    ...currentState,
                    loaded: true,
                    episodeHighscore: response.episodes[0],
                }));
            })
            .catch((error) => {
                if (import.meta.env.VITE_SENTRY_ENABLED === "true") {
                    Sentry.captureException(error);
                }
                setStateHighscore((currentState) => ({
                    ...currentState,
                    loaded: true,
                    error: true,
                    data: null,
                }));
            });
    }, [accountContext.userData.amigo?.demographic?.postal_code]);

    useEffect(() => {
        async function sendHighscore() {
            if (
                accountContext.userData.amigo?.demographic?.postal_code &&
                isSendingHighscoreRef.current === false
            ) {
                isSendingHighscoreRef.current = true;
                duoCom.Highscore.submit({
                    season: LViS.Project.getSettings().season_number.toString(),
                    episode: LViS.getEvent()
                        .getCustomFields()
                        .episode_number.toString(),
                    score: score,
                    postal_code:
                        accountContext.userData.amigo?.demographic
                            ?.postal_code ?? undefined,
                })
                    .catch((error) => {
                        Sentry.captureException(error);
                        setSateShowPostalCodeNotSubmittedMessage(true);
                    })
                    .then(() => {
                        fetchHighscore();
                    })
                    .finally(() => {
                        isSendingHighscoreRef.current = false;
                    });
            }
        }

        duoCom.events.addEventListener(
            "amigodemographics:change",
            sendHighscore,
        );

        return () => {
            duoCom.events.removeEventListener(
                "amigodemographics:change",
                sendHighscore,
            );
        };
    }, [
        accountContext.userData.amigo?.demographic?.postal_code,
        dispatch,
        fetchHighscore,
        score,
    ]);

    useEffect(() => {
        if (hasSentHighscore) {
            fetchHighscore();
        }
    }, [fetchHighscore, hasSentHighscore]);

    const geography = useGeography({
        county: stateHighscore.episodeHighscore.county,
        municipality: stateHighscore.episodeHighscore.municipality,
        view: "slutvy",
        showPostalCodeNotSubmittedMessage:
            sateShowPostalCodeNotSubmittedMessage,
    });

    return (
        <ContentStatus
            errorHTMLText={
                "<span>Det gick tyvärr inte att hämta topplistan</span>"
            }
            loaded={stateHighscore.loaded}
            error={stateHighscore.error}
        >
            {() => {
                if (Object.keys(stateHighscore.episodeHighscore).length === 0) {
                    return (
                        <span>
                            Det gick tyvärr inte att hämta kvällens resultat för
                            topplistan
                        </span>
                    );
                }
                return (
                    <Episode
                        episodeNumber={stateHighscore.episodeHighscore.episode}
                        geography={geography}
                        list={stateHighscore.episodeHighscore.max.list}
                        user={stateHighscore.episodeHighscore.max.user}
                    />
                );
            }}
        </ContentStatus>
    );
};

export default GeographyToplist;
