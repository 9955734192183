import HouseFilled from "@svt/duo-shared-assets/assets/icons/houseFilled.svg?react";
import HouseHollow from "@svt/duo-shared-assets/assets/icons/houseHollow.svg?react";
import Goblet2Filled from "@svt/duo-shared-assets/assets/icons/goblet2Filled.svg?react";
import Goblet2Hollow from "@svt/duo-shared-assets/assets/icons/goblet2Hollow.svg?react";
import ListFilled from "@svt/duo-shared-assets/assets/icons/listFilled.svg?react";
import ListHollow from "@svt/duo-shared-assets/assets/icons/listHollow.svg?react";
import { useSelector, useDispatch } from "react-redux";

import { ViewType } from "../startScreen";
import { GO_TO_TAB } from "../../../../redux/actions";

export default function TabBar() {
    const tabBar = useSelector((state: { tabBar: ViewType }) => state.tabBar);
    const dispatch = useDispatch();

    function showStart() {
        dispatch({ type: GO_TO_TAB, payload: "start" });
    }

    function showHistory() {
        dispatch({ type: GO_TO_TAB, payload: "history" });
    }

    function showAchievements() {
        dispatch({ type: GO_TO_TAB, payload: "achievements" });
    }

    return (
        <div className="tabBar__wrapper">
            <button
                className="tabBar__button"
                type="button"
                onClick={showStart}
                disabled={tabBar.view === "start"}
            >
                {tabBar.view === "start" ? <HouseFilled /> : <HouseHollow />}
                <div className="tabBar__text">Start</div>
            </button>
            <button
                className="tabBar__button"
                type="button"
                onClick={showHistory}
                disabled={tabBar.view === "history"}
            >
                {tabBar.view === "history" ? <ListFilled /> : <ListHollow />}

                <div className="tabBar__text">Spelhistorik</div>
            </button>
            <button
                className="tabBar__button"
                type="button"
                onClick={showAchievements}
                disabled={tabBar.view === "achievements"}
            >
                {tabBar.view === "achievements" ? (
                    <Goblet2Filled />
                ) : (
                    <Goblet2Hollow />
                )}
                <div className="tabBar__text">Prestationer</div>
            </button>
        </div>
    );
}
