import React from "react";
import PropTypes from "prop-types";

export default class AnswerBoxMultiAnswer extends React.Component {
    render() {
        let { correctAnswer, answer } = this.props;

        const multiAnswerBodyText = [];

        correctAnswer.forEach((singleCorrectAnswer, i) => {
            multiAnswerBodyText.push(
                <span key={i}>
                    {i > 0 && ", "}
                    <span
                        className={`svt_answer-box__body_text ${
                            correctAnswer.includes(answer[i])
                                ? "-correct"
                                : "-incorrect"
                        }`}
                    >
                        {answer[i] ? answer[i] : "-"}
                    </span>
                </span>,
            );
        });

        return multiAnswerBodyText;
    }
}

AnswerBoxMultiAnswer.propTypes = {
    correctAnswer: PropTypes.array.isRequired,
    answer: PropTypes.array,
};
