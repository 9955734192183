import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
    AccountContext,
    analytics,
    toplistInit,
} from "@svt/duo-shared-components";
import * as Sentry from "@sentry/react";
import LViS from "@monterosa/lvis-api";
import duoCom from "@svt/duo-communication";

import Waiting from "./views/waiting/waiting";
import City from "./views/city/city";
import MultiChoice from "./views/multiChoice/multiChoice";
import Dnd from "./views/dnd/dnd";
import TheList from "./views/theList/theList";
import ClosestWinsAnswer from "./views/closestWins/closestWinsAnswer";
import ClosestWinsWaiting from "./views/closestWins/closestWinsWaiting";
import EpisodeEnd from "./views/episodeEnd/episodeEnd";
import Info from "./views/info/info";
import questionTypes from "../helpers/questionTypes";
import { COUNTDOWN_TIMER_START } from "../redux/actions";
import { getQuestionIndex } from "../helpers/questionHelpers";
import pointsSender from "../helpers/pointsSender";
import QuestionAnswerPoints from "./views/questionAnswerPoints/questionAnswerPoints";
import useGetPlayedEpisodes from "./useGetPlayedEpisodes";
import { elementsNotToInclude } from "../index";

const ActiveElement = ({ activeElement, dispatch }) => {
    const accountContext = React.useContext(AccountContext);
    useGetPlayedEpisodes();

    useEffect(() => {
        if (accountContext.userData.isLoggedIn) {
            const lvisEvent = LViS.getEvent();
            const eventCustomFields = lvisEvent.getCustomFields();
            toplistInit({
                season: LViS.Project.getSettings().season_number,
                episode: eventCustomFields.episode_number,
                callback: () => {
                    pointsSender.sendAll(lvisEvent);
                },
            });
        }
    }, [accountContext.userData.isLoggedIn]);

    const startClock = useCallback(() => {
        if (activeElement) {
            let type = activeElement.type;

            if (questionTypes[type]) {
                dispatch({
                    type: COUNTDOWN_TIMER_START,
                    duration: activeElement.duration,
                    durationLeft: activeElement.durationLeft,
                    elapsedPercent:
                        100 *
                        (activeElement.durationLeft / activeElement.duration),
                });
            }
        }
    }, [activeElement, dispatch]);

    useEffect(() => {
        startClock();
    }, [startClock]);

    useEffect(() => {
        function scrollToTop() {
            const scrollPosition = {
                x: window.scrollX,
                y: window.scrollY,
            };
            if (scrollPosition.y !== 0 && scrollPosition.y !== 0) {
                window.scrollTo(0, 0);
            }
        }

        scrollToTop();
    }, []);

    if (!activeElement) {
        return <Waiting />;
    }

    let type = activeElement.type;
    let Component;

    if (import.meta.env.VITE_DEBUG === "true") {
        duoCom.log({
            message: type,
            data: activeElement,
        });
    }

    switch (type) {
        case "city": {
            analytics.trackViewEvent(`fråga-${getQuestionIndex()}`);
            Component = City;
            break;
        }
        case "multi_choice": {
            analytics.trackViewEvent(`fråga-${getQuestionIndex()}`);
            Component = MultiChoice;
            break;
        }
        case "dnd": {
            analytics.trackViewEvent(`fråga-${getQuestionIndex()}`);
            Component = Dnd;
            break;
        }
        case "the_list": {
            analytics.trackViewEvent(`fråga-${getQuestionIndex()}`);
            Component = TheList;
            break;
        }
        case "closest_wins": {
            analytics.trackViewEvent(`fråga-${getQuestionIndex()}`);
            Component = ClosestWinsAnswer;
            break;
        }
        case "closest_wins_waiting": {
            analytics.trackViewEvent(`fråga-${getQuestionIndex()}-väntar`);
            Component = ClosestWinsWaiting;
            break;
        }
        case "question_answers_points": {
            analytics.trackViewEvent(
                `fråga-${getQuestionIndex()}-rättning-poäng`,
            );
            Component = QuestionAnswerPoints;
            break;
        }
        case "episode_end": {
            analytics.trackViewEvent("resultat");
            analytics.helpers.endGame();

            Component = EpisodeEnd;
            break;
        }
        case "info_screen": {
            analytics.trackViewEvent(`info-${getQuestionIndex()}`);
            Component = Info;
            break;
        }
        default: {
            if (elementsNotToInclude.includes(type)) {
                return;
            }

            try {
                Sentry.captureMessage(
                    `No activeElement type found ${JSON.stringify(
                        activeElement,
                    )}`,
                );
            } catch {
                //Do nothing
            }
            Component = null;
        }
    }

    if (Component) {
        return <Component element={activeElement} />;
    }

    return <Waiting />;
};

ActiveElement.propTypes = {
    activeElement: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
};

export default connect((state) => ({
    activeElement: state.activeElement,
}))(ActiveElement);
