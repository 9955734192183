import { useContext, useEffect, useState } from "react";
import DuoCom from "@svt/duo-communication";
import {
    AccountContext,
    FriendsContext,
    TopListCategory,
    ToplistHeading,
    ToplistUsers,
    ToplistUsersHeaders,
    ToplistWrapper,
} from "@svt/duo-shared-components";
import LViS from "@monterosa/lvis-api";
import LoaderImg from "@svt/duo-shared-assets/assets/icons/loader.svg?react";
import orderBy from "lodash/orderBy";

import CustomStarScoreToplistColumn from "../../../shared/toplist/customStarScoreToplistColumn";
import {
    AccountContextLoggedInType,
    FriendsContextType,
} from "../../../../types";
import {
    outer,
    toplistUsers,
    toplistUsersHeaders,
} from "../../../shared/toplist/toplistwrapper";

type ToplistItemType = {
    username: string;
    userId: string;
    isOnline: boolean;
    me: boolean;
    avatar: string;
    totalScore: number;
    lastQuestionScore: number;
    lastAnsweredQuestionNumber: number;
};

type CustomComponentType = {
    me?: JSX.Element;
    friends?: { [key: string]: JSX.Element };
};

type ToplistStateType = {
    toplist: ToplistItemType[];
    custom: CustomComponentType;
    isLoading: boolean;
};

export default function TopListEpisodeFriends({
    episodeNumber,
}: {
    episodeNumber: string;
}) {
    const friendsContext = useContext<FriendsContextType>(FriendsContext);
    const accountContext =
        useContext<AccountContextLoggedInType>(AccountContext);

    const [toplistEpisodeDataState, setToplistEpisodeDataState] =
        useState<ToplistStateType>({
            toplist: [],
            custom: {},
            isLoading: true,
        });

    useEffect(() => {
        async function getTopList() {
            const interactionsResponse = (await DuoCom.Interactions.get({
                season: LViS.Project.getSettings().season_number,
                episode: episodeNumber,
                type: "TOPLIST-POINTS",
                selection: "all",
                sortAscending: true,
            })) as {
                items: {
                    unoId: string | number;
                    segment: number;
                    data: { points: number; starLevel: number };
                }[];
            };

            //Get the latest(by date) points and star level for each user by segment. Can be multiple points and star level per segment.
            const latestPointsAndStarLevelByUserId =
                interactionsResponse.items.reduce(
                    (
                        pointsObj: {
                            [userID: string]: {
                                [segment: string]: {
                                    points: number;
                                    starLevel: number;
                                };
                            };
                        },
                        pointsItem,
                    ) => {
                        if (!pointsObj[pointsItem.unoId]) {
                            pointsObj[pointsItem.unoId] = {};
                        }

                        pointsObj[pointsItem.unoId][pointsItem.segment] =
                            pointsItem.data;

                        return pointsObj;
                    },
                    {},
                );

            const usersPointStarLevelData = Object.keys(
                latestPointsAndStarLevelByUserId,
            ).reduce(
                (
                    all: {
                        toplist: ToplistItemType[];
                        custom: CustomComponentType;
                    },
                    unoId,
                ) => {
                    const userTotalPointStarLevel = Object.keys(
                        latestPointsAndStarLevelByUserId[unoId],
                    ).reduce(
                        (
                            total: {
                                [key: string]: {
                                    points: number;
                                    starLevel: number;
                                };
                            },
                            segment,
                        ) => {
                            const points =
                                latestPointsAndStarLevelByUserId[unoId][segment]
                                    .points ?? 0;

                            const starLevel =
                                latestPointsAndStarLevelByUserId[unoId][segment]
                                    .starLevel ?? 0;

                            if (!total[unoId]) {
                                total[unoId] = { points: 0, starLevel: 0 };
                            }

                            total[unoId] = {
                                points: total[unoId].points + points,
                                starLevel: total[unoId].starLevel + starLevel,
                            };
                            return total;
                        },
                        {},
                    );
                    const isMe = unoId === accountContext.userData.uno.user.id;

                    if (isMe) {
                        all.custom = {
                            ...all.custom,
                            me: (
                                <CustomStarScoreToplistColumn
                                    totalStars={
                                        userTotalPointStarLevel[unoId].starLevel
                                    }
                                    shouldShowTotalStarLevel
                                />
                            ),
                        };
                        all.toplist.push({
                            userId: accountContext.userData.uno.user.id,
                            username: accountContext.userData.amigo.username,
                            isOnline: true,
                            me: isMe,
                            avatar: DuoCom.Amigo.getAvatarUrlForUnoId({
                                unoId: unoId,
                            }),
                            totalScore: userTotalPointStarLevel[unoId].points,
                            lastQuestionScore:
                                userTotalPointStarLevel[unoId].points,
                            lastAnsweredQuestionNumber: 1,
                        });
                    } else {
                        all.custom = {
                            ...all.custom,
                            friends: {
                                ...(all.custom.friends ?? {}),
                                [unoId]: (
                                    <CustomStarScoreToplistColumn
                                        totalStars={
                                            userTotalPointStarLevel[unoId]
                                                .starLevel
                                        }
                                        shouldShowTotalStarLevel
                                    />
                                ),
                            },
                        };
                        if (!friendsContext.friends[unoId]) {
                            return all;
                        }
                        all.toplist.push({
                            userId: friendsContext.friends[unoId].userId,
                            username: friendsContext.friends[unoId].username,
                            isOnline: friendsContext.friends[unoId].isOnline,
                            avatar: DuoCom.Amigo.getAvatarUrlForUnoId({
                                unoId: unoId,
                            }),
                            me: false,
                            totalScore: userTotalPointStarLevel[unoId].points,
                            lastQuestionScore:
                                userTotalPointStarLevel[unoId].points,
                            lastAnsweredQuestionNumber: 1,
                        });
                    }

                    return {
                        ...all,
                        toplist: orderBy(
                            all.toplist,
                            [({ totalScore }) => totalScore || "", "username"],
                            ["desc"],
                        ), // When sorting with null value on totalScore they get first, use empty string instead;
                    };
                },
                { toplist: [], custom: {} },
            );
            setToplistEpisodeDataState({
                isLoading: false,
                ...usersPointStarLevelData,
            });
        }

        if (accountContext.userData.loaded && friendsContext.loaded) {
            getTopList();
        }
    }, [
        accountContext.userData.amigo.username,
        accountContext.userData.loaded,
        accountContext.userData.uno.user.id,
        episodeNumber,
        friendsContext,
    ]);

    if (toplistEpisodeDataState.isLoading) {
        return <LoaderImg />;
    }

    if (toplistEpisodeDataState.toplist.length < 2) {
        //No friends played this episode
        return null;
    }

    toplistEpisodeDataState.toplist = orderBy(
        toplistEpisodeDataState.toplist,
        [({ totalScore }) => totalScore || "", "username"],
        ["desc"],
    ); // When sorting with null value on totalScore they get first, use empty string instead

    return (
        <TopListCategory
            categories={[
                {
                    toplist: (
                        <>
                            <ToplistWrapper
                                inlineStyles={{
                                    ...outer,
                                    toplistUsersHeaders,
                                    toplistUsers,
                                }}
                            >
                                <ToplistHeading
                                    headerH1Text={"Väntopplistan"}
                                    inlineStyles={{
                                        ...outer,
                                        toplistUsersHeaders,
                                        toplistUsers,
                                    }}
                                />
                                {/* TODO: remove require for questionIds */}
                                {/* TODO: remove require for justOneQuestion */}
                                <ToplistUsersHeaders
                                    toplist={toplistEpisodeDataState.toplist}
                                    questionIds={["1"]}
                                    justOneQuestion={false}
                                    inlineStyles={toplistUsersHeaders}
                                    leftColumnHeading={"Stjärnor"}
                                    rightColumnHeading={"Poäng"}
                                />
                                {/* TODO: remove require for questionIds */}
                                {/* TODO: remove require for justOneQuestion */}
                                <ToplistUsers
                                    toplist={toplistEpisodeDataState.toplist}
                                    questionIds={["1"]}
                                    modal={false}
                                    justOneQuestion={false}
                                    inlineStyles={toplistUsers}
                                    customComponent={
                                        toplistEpisodeDataState.custom
                                    }
                                />
                            </ToplistWrapper>
                        </>
                    ),
                },
            ]}
        />
    );
}
