import React from "react";
import duoCom from "@svt/duo-communication";

import { LoggedInType } from "../../../../types";

const numberFormatter = new Intl.NumberFormat("sv-SE");

export default function BarChart({
    allPlayerPoints,
    highestBarHeight,
    labels,
    userData,
    userPoints,
    hasAnswered,
}: {
    allPlayerPoints: number[];
    highestBarHeight: number;
    labels: string[];
    userData: LoggedInType;
    userPoints: number;
    hasAnswered: boolean;
}) {
    const avatarUrl = duoCom.Amigo.getAvatarUrlForUnoId({
        unoId: userData.uno.user.id,
    });
    const maxUserPoints = Math.max(...allPlayerPoints);
    const scalingFactor = highestBarHeight / maxUserPoints;

    const bars = allPlayerPoints.map((allPlayerPointsAtIndex, index) => {
        const barHeight = allPlayerPointsAtIndex * scalingFactor;
        const barStyle = {
            height: barHeight + "px",
        };

        const shouldShowAvatar =
            (hasAnswered && userPoints / 2 - 1 === index) || false;

        return (
            <div className="bar-chart__bar-wrapper" key={index}>
                <div
                    style={{
                        backgroundColor: shouldShowAvatar
                            ? userData.amigo.profile.color
                            : "transparent",
                    }}
                    className="bar-chart__avatar-wrapper"
                >
                    {shouldShowAvatar && (
                        <img
                            className="bar-chart__avatar"
                            alt=""
                            src={avatarUrl}
                        />
                    )}
                </div>
                {allPlayerPointsAtIndex !== 0 && (
                    <div className="bar-chart__bar-label">
                        {numberFormatter.format(allPlayerPointsAtIndex)}
                    </div>
                )}
                <div className="bar-chart__bar" style={barStyle}></div>
            </div>
        );
    });

    return (
        <div>
            <div className="bar-chart__bars">{bars}</div>
            <div className="bar-chart__line"></div>
            <div className="bar-chart__x-axis">
                {labels.map((label, index) => (
                    <div className="bar-chart__x-axis-label" key={index}>
                        {label}
                    </div>
                ))}
            </div>
        </div>
    );
}
