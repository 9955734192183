import { delay, take, put, race, select } from "redux-saga/effects";
import {
    CLEAR_EVENT,
    COUNTDOWN_TIMER_START,
    TIMERS_STOP,
    COUNTDOWN_TIMER_CLEAR,
    COUNTDOWN_TIMER_TICK,
    COUNTDOWN_ANIMATION_SET,
    COUNTDOWN_ANIMATION_CLEAR,
} from "../actions";

const getCountdown = (state) => state.countdown;

function* countdownTimer() {
    while (yield take(COUNTDOWN_TIMER_START)) {
        const countDownState = yield select(getCountdown);
        yield put({
            type: COUNTDOWN_ANIMATION_SET,
            durationLeft: countDownState.durationLeft,
            elapsedPercent: countDownState.elapsedPercent,
        });

        while (true) {
            const winner = yield race({
                clearAll: take(CLEAR_EVENT), // New event sync
                stopped: take(TIMERS_STOP), // New time sync
                tick: yield delay(1000), // Tick
            });

            if (winner.clearAll) {
                break;
            } else if (winner.stopped) {
                yield put({ type: COUNTDOWN_TIMER_CLEAR });
                yield put({ type: COUNTDOWN_ANIMATION_CLEAR });
                break;
            } else {
                const state = yield select(getCountdown);

                if (state.now > 0) {
                    yield put({ type: COUNTDOWN_TIMER_TICK });
                } else {
                    yield put({ type: COUNTDOWN_TIMER_CLEAR });
                    yield put({ type: COUNTDOWN_ANIMATION_CLEAR });
                    break;
                }
            }
        }
    }
}

export default countdownTimer;
