import { useContext } from "react";
import PropTypes from "prop-types";
import { AccountContext, ContentStatus } from "@svt/duo-shared-components";

import LoggedIn from "./loggedIn";
import LoggedOut from "./loggedOut";
import ErrorBoundary from "../../../errorBoundary";
import ProblemInfo from "../../../shared/problemInfo";
import onSeen from "../../../shared/onSeen";

const Index = () => {
    const accountContext = useContext(AccountContext);

    return (
        <ErrorBoundary
            errorTextComponent={
                <span>
                    Det gick tyvärr inte att ladda din historik. Försök igen
                    senare
                </span>
            }
            showCloseGame={false}
        >
            <ContentStatus
                loaded={accountContext.userData.loaded}
                error={accountContext.userData.error}
                errorComponent={
                    <p>
                        Det gick tyvärr inte att ladda din historik. Försök igen
                        senare.
                    </p>
                }
            >
                {() =>
                    accountContext.userData.isLoggedIn ? (
                        <LoggedIn />
                    ) : (
                        <LoggedOut />
                    )
                }
            </ContentStatus>
            <ProblemInfo />
        </ErrorBoundary>
    );
};

Index.propTypes = {
    onSeenComponent: PropTypes.func.isRequired,
    stateOnSeen: PropTypes.object.isRequired,
};

export default onSeen(Index);
