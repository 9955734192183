import { CLEAR_EVENT, IS_TYPING_TRUE, IS_TYPING_FALSE } from "../actions";

const initialState = false;

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case CLEAR_EVENT:
            return initialState;
        case IS_TYPING_TRUE:
            return true;
        case IS_TYPING_FALSE:
            return false;
        default:
            return state;
    }
}
