import { useContext, useEffect, useRef } from "react";
import DuoCom from "@svt/duo-communication";
import LViS from "@monterosa/lvis-api";
import { AccountContext } from "@svt/duo-shared-components";
import { AccountContextType } from "@svt/duo-shared-components/dist/types/types";

import { setHistoryEpisodeSegments } from "./useUnlockAchievement";

export default function useGetPlayedEpisodes() {
    const accountContext = useContext<AccountContextType>(AccountContext);
    const shouldCheckEpisode = useRef(true);

    useEffect(() => {
        async function getHistory() {
            const interactionsResponse = await DuoCom.Interactions.get({
                season: LViS.Project.getSettings().season_number,
                episode: LViS.getEvent().getCustomFields().episode_number,
                type: "TOPLIST-POINTS",
                selection: "self",
            });

            const playedSegments = interactionsResponse.items.reduce(
                (acc: string[], item: { segment: string }) => {
                    if (!acc.includes(item.segment)) {
                        acc.push(item.segment);
                        return acc;
                    }

                    return acc;
                },
                [],
            );

            setHistoryEpisodeSegments(playedSegments);
        }

        if (accountContext.userData.isLoggedIn) {
            if (shouldCheckEpisode.current) {
                getHistory();
                shouldCheckEpisode.current = false;
            }
        }

        return () => {
            shouldCheckEpisode.current = true;
        };
    }, [accountContext.userData.isLoggedIn]);

    return null;
}
