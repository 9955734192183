import ArrowIcon from "@svt/duo-shared-assets/assets/icons/arrow.svg?react";

import { AirTimeType } from "../../../../types";
import UserPlayed from "./userPlayed";
import { ByEpisodeIdType } from "./loggedIn";
import UserPlayedAchievements from "./userPlayedAchievements";
import UserNotPlayed from "./userNotPlayed";

const formatDate = (date: Date): string => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    return `${day}/${month}`;
};

const formatDateTime = (date: Date): string => {
    const dateString = date.toLocaleDateString("sv-SE", {
        day: "numeric",
        month: "numeric",
    });
    const timeString = date.toLocaleTimeString("sv-SE", {
        hour: "2-digit",
        minute: "2-digit",
    });
    return `${dateString} kl ${timeString}`;
};

export default function EpisodeList({
    allEpisodeAirTimesByEpisodeId: allEpisodeAirTimesByEpisodeNumber,
    episodeHighScoreList,
}: {
    allEpisodeAirTimesByEpisodeId: AirTimeType;
    episodeHighScoreList: ByEpisodeIdType;
}) {
    return (
        <div className="episodeList__wrapper">
            <ul className="episodeList__outerList">
                {Object.keys(allEpisodeAirTimesByEpisodeNumber).map(
                    (episodeNumber) => {
                        const airTimeData =
                            allEpisodeAirTimesByEpisodeNumber[episodeNumber];
                        const episodeHighscore =
                            episodeHighScoreList[episodeNumber];
                        const userScore = episodeHighscore?.max.user?.score;
                        const userHasPlayed =
                            userScore !== null && userScore !== undefined
                                ? true
                                : false;
                        const airTimeDate = new Date(
                            airTimeData.airTime * 1000,
                        );
                        const isFuture = airTimeDate > new Date();

                        function episodeText() {
                            return (
                                <div>
                                    <span className="episodeList__episodeText">
                                        Avsnitt {episodeNumber}
                                    </span>{" "}
                                    <span className="episodeList__dot">•</span>{" "}
                                    {formatDate(
                                        new Date(airTimeData.airTime * 1000),
                                    )}
                                </div>
                            );
                        }

                        return isFuture ? (
                            <li
                                className="episodeList__outerListItem -notAired"
                                key={episodeNumber}
                            >
                                <span className="episodeList__episodeText">
                                    Avsnitt {episodeNumber}
                                </span>
                                <div className="episodeList__notAiredText">
                                    Avsnittet släpps{" "}
                                    {formatDateTime(airTimeDate)}
                                </div>
                            </li>
                        ) : userHasPlayed ? (
                            <li
                                className={`episodeList__outerListItem -played`}
                                key={episodeNumber}
                            >
                                <UserPlayed
                                    episodeHighscore={episodeHighscore}
                                    episodeNumber={episodeNumber}
                                    userScore={userScore ?? 0}
                                >
                                    <ArrowIcon className="episodeList__arrow" />
                                    {episodeText()}
                                    <div className="episodeList__pointsText">
                                        {userScore} Poäng
                                    </div>

                                    <UserPlayedAchievements
                                        episodeNumber={episodeNumber}
                                    />
                                </UserPlayed>
                            </li>
                        ) : (
                            <li
                                className="episodeList__outerListItem -notPlayed"
                                key={episodeNumber}
                            >
                                {episodeText()}
                                <UserNotPlayed episodeNumber={episodeNumber} />
                            </li>
                        );
                    },
                )}
            </ul>
        </div>
    );
}
