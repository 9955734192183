import LoaderImg from "@svt/duo-shared-assets/assets/icons/loader.svg?react";

import {
    getTotalNumberOfPlayers,
    getNumberOfPlayersWorseThan,
} from "../../../../helpers/calculatePointsPercentage";
import LineChart from "./lineChart";
import { ResultsType } from "../../../../types";
import { hideToMatchImageSnapshotClass } from "../../../../helpers/imageTest";

export default function StatisticsUserPointsTotal({
    userPoints,
    allPlayerPoints,
}: {
    userPoints: number;
    allPlayerPoints: ResultsType | null;
}) {
    if (!allPlayerPoints || allPlayerPoints.length === 0) {
        return null;
    }

    function getTextSection(allPlayerPoints: ResultsType) {
        if (userPoints >= 35) {
            return (
                <div className="statistics-user-points-total__performance-status">
                    <div className="statistics-user-points-total__performance-status-green">
                        Snyggt!
                    </div>
                    <div>
                        Du var bättre än{" "}
                        {(
                            getNumberOfPlayersWorseThan(
                                userPoints + 1,
                                allPlayerPoints,
                            ) - 1
                        ).toLocaleString("sv-SE")}{" "}
                        av spelarna! 🎉
                    </div>
                </div>
            );
        } else if (userPoints >= 16) {
            return (
                <div className="statistics-user-points-total__performance-status">
                    <div className="statistics-user-points-total__performance-status-blue">
                        Bra
                    </div>
                    <div>
                        Du var bättre än{" "}
                        {(
                            getNumberOfPlayersWorseThan(
                                userPoints + 1,
                                allPlayerPoints,
                            ) - 1
                        ).toLocaleString("sv-SE")}{" "}
                        av spelarna! 🎉
                    </div>
                </div>
            );
        }
        return (
            <div className="statistics-user-points-total__performance-status">
                <div className="statistics-user-points-total__performance-status-gray">
                    Kämpa på
                </div>
                <div>
                    Du var bättre än{" "}
                    {(
                        getNumberOfPlayersWorseThan(
                            userPoints + 1,
                            allPlayerPoints,
                        ) - 1
                    ).toLocaleString("sv-SE")}{" "}
                    av spelarna! 🎉
                </div>
            </div>
        );
    }

    if (getTotalNumberOfPlayers(allPlayerPoints) < 3) {
        return (
            <div className="statistics-user-points-total">
                <div>Hämtar resultat</div>
                <LoaderImg className={hideToMatchImageSnapshotClass} />
            </div>
        );
    }
    return (
        <div className="statistics-user-points-total">
            {getTextSection(allPlayerPoints)}
            <LineChart
                allPlayerPoints={allPlayerPoints}
                userPoints={userPoints}
            />
            <div className="statistics-user-points-total__chart-label">
                Poäng
            </div>
            <div className="statistics-user-points-total__total-players">
                {getTotalNumberOfPlayers(allPlayerPoints).toLocaleString(
                    "sv-SE",
                )}{" "}
                personer har spelat klart avsnittet.
            </div>
        </div>
    );
}
