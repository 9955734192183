var announceTimeout = null;

export default function a11yAnnouncer(message, manners) {
    var announcer;
    var clearAnnouncer;
    manners = manners || "polite";
    announcer = document.getElementById("svt_a11y-announcer");

    if (!announcer) {
        return false;
    }

    announcer.setAttribute("aria-live", "off");
    clearAnnouncer = function clear() {
        announcer.innerHTML = "";
        announceTimeout = null;
    };
    announcer.setAttribute("aria-live", manners);
    announcer.innerHTML = message;
    clearTimeout(announceTimeout);
    announceTimeout = setTimeout(clearAnnouncer, 500);

    return announcer;
}
