import { useSelector } from "react-redux";
import questionTypes from "../../helpers/questionTypes";
import CountdownBar from "./countdownBar";
import StarLevel from "./starLevel";

export default function CountdownHandler() {
    const activeElement = useSelector((state) => state.activeElement);

    if (activeElement) {
        let type = activeElement.type;
        if (questionTypes[type]) {
            return (
                <div className="svt_countdown_handler">
                    <CountdownBar
                        durationLeft={activeElement.durationLeft}
                        duration={activeElement.duration}
                    />
                    <StarLevel />
                </div>
            );
        }
    }

    return null;
}
