import React from "react";
import PropTypes from "prop-types";

import Star from "../star";

const UserScore = ({ userScore, userStars }) => {
    return (
        <>
            <div className="svt_user-score__wrapper">
                <div className="svt_user-score__circle">
                    <div className="svt_user-score__circle-points">
                        {userScore}
                    </div>
                </div>

                {userStars !== null && (
                    <div>
                        <Star modifier="-full-moon" width={25} height={25} />
                        <Star
                            modifier="-full-moon svt_user-score__stars-top-middle"
                            width={40}
                            height={40}
                        />
                        <Star modifier="-full-moon" width={25} height={25} />

                        <div className="svt_user-score__stars-top-header">
                            {userStars} stjärnor
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

UserScore.defaultProps = {
    userStars: null,
};

UserScore.propTypes = {
    userScore: PropTypes.number.isRequired,
    userStars: PropTypes.number,
};

export default UserScore;
