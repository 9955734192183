import React, { useRef, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import {
    QUESTIONS_LOCK_ANSWER,
    QUESTIONS_SET_ANSWER,
    QUESTIONS_RESET_ANSWER,
} from "../../../redux/actions";
import calculateStarLevel from "../../../helpers/calculateStarLevel";
import pointsSender from "../../../helpers/pointsSender";
import { getQuestionIndex } from "../../../helpers/questionHelpers";
import diffInKm from "./helpers/diffInKm";
import ClosestWinsMapShared from "./shared/closestWinsMapShared";
import ClosestWinsWaitingShared from "./shared/closestWinsWaitingShared";
import { getStore } from "../../../redux/configureStore";

function ClosestWinsAnswer(props) {
    const cmsExport = useRef(JSON.parse(props.element.cmsExport));
    const dispatch = useDispatch();
    const store = getStore();
    const questionState = useSelector(
        (state) => state.questions[props.element.id],
    );
    const answerRef = useRef(null);

    const setAnswer = useCallback(
        ({ coords, lock }) => {
            const storeState = store.getState();
            const answerTime =
                storeState.countdown.duration - storeState.countdown.now;
            const diffFromCorrectKm = diffInKm(
                cmsExport.current.questionDestination,
                coords,
            );

            let points = 0;
            if (diffFromCorrectKm <= props.element.threePointsLimit) {
                points = 3;
            } else if (diffFromCorrectKm <= props.element.twoPointsLimit) {
                points = 2;
            } else if (diffFromCorrectKm <= props.element.onePointLimit) {
                points = 1;
            }

            const getsPoints = Boolean(points > 0);
            const starLevel = getsPoints
                ? calculateStarLevel(answerTime, props.element)
                : 0;

            const actions = [
                {
                    type: QUESTIONS_SET_ANSWER,
                    id: props.element.id,
                    answer: coords,
                    isCorrect: getsPoints,
                    allCorrect: getsPoints,
                    points: points,
                    answerTime: answerTime,
                    starLevel: starLevel,
                },
            ];

            if (lock) {
                actions.push({
                    type: QUESTIONS_LOCK_ANSWER,
                    id: props.element.id,
                    points: points,
                    answerTime: answerTime,
                    starLevel: starLevel,
                });
            }

            dispatch(actions);
        },
        [dispatch, props.element, store],
    );

    function lockAnswer() {
        setAnswer({
            coords: answerRef.current,
            lock: true,
        });
    }

    useEffect(() => {
        // Clear previous auto answer
        const questionDataOnMount =
            store.getState().questions[props.element.id];

        if (
            questionDataOnMount?.hasAnswered &&
            !questionDataOnMount?.hasLockedAnswer
        ) {
            dispatch({
                type: QUESTIONS_RESET_ANSWER,
                id: props.element.id,
            });
        }

        return () => {
            // Set auto answer if the user hasn't locked
            const questionDataOnUnmount =
                store.getState().questions[props.element.id];

            if (
                questionDataOnUnmount && //can be undefined if user goes back to start from menu
                !questionDataOnUnmount.hasLockedAnswer
            ) {
                setAnswer({
                    coords: answerRef.current,
                    lock: false,
                });
            }
        };
    }, [dispatch, props.element.id, setAnswer, store]);

    useEffect(() => {
        return () => {
            // Send points to friends
            pointsSender.sendOne(props.element.id, getQuestionIndex());
        };
    }, [props.element.id]);

    if (questionState.hasLockedAnswer) {
        return (
            <ClosestWinsWaitingShared
                question={props.element}
                questionState={questionState}
            />
        );
    }

    return (
        <div className="closest-wins-answer-wrapper">
            <div>
                <h1 className="closest-wins-answer-question">
                    Placera ut platsen på kartan
                </h1>

                <p className="closest-wins-answer-instructions">
                    Flytta och zooma kartan för att placera nålen rätt
                </p>
            </div>

            <div className="closest-wins-answer-map-wrapper">
                <ClosestWinsMapShared
                    version="answer"
                    center={
                        questionState.hasAnswered ? questionState.answer : []
                    }
                    zoomToExtent={!questionState.hasAnswered}
                    extent={cmsExport.current.mapExtent}
                    onModify={(coords) => {
                        answerRef.current = coords;
                    }}
                    lockAnswer={() => {
                        lockAnswer();
                    }}
                />
            </div>
        </div>
    );
}

ClosestWinsAnswer.propTypes = {
    element: PropTypes.object.isRequired,
};

export default ClosestWinsAnswer;
