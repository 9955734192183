import update from "immutability-helper";
import {
    CLEAR_EVENT,
    THE_LIST_INITIAL,
    THE_LIST_TOGGLE_OPTION,
} from "../actions";

const initialState = {
    options: [],
    selected: 0,
    noToSelect: 0,
    id: null,
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case CLEAR_EVENT:
            return initialState;
        case THE_LIST_INITIAL:
            return {
                ...state,
                options: action.options,
                selected: 0,
                noToSelect: action.noToSelect,
                id: action.id,
            };
        case THE_LIST_TOGGLE_OPTION:
            return update(state, {
                options: {
                    [action.index]: {
                        selected: {
                            $set: action.selected,
                        },
                    },
                },
                selected: {
                    $set: state.selected + action.direction,
                },
            });
        default:
            return state;
    }
}
