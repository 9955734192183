import React from "react";
import PropTypes from "prop-types";

import EpisodeEndText from "./roundText";
import PenImage from "../../../assets/icons/pen.svg?react";
import ListImage from "../../../assets/icons/list.svg?react";
import BreakImage from "../../../assets/icons/break.svg?react";
import MusicImage from "../../../assets/icons/music.svg?react";
import WhoThereImage from "../../../assets/icons/who-there.svg?react";
import ReturnImage from "../../../assets/icons/return.svg?react";
import ClosestWinsImage from "../../../assets/icons/closestWins.svg?react";
import TrackBeginningImage from "../../../assets/icons/track-beginning.svg?react";
import TrackMiddleImage from "../../../assets/icons/track-middle.svg?react";
import TrackEndImage from "../../../assets/icons/track-end.svg?react";

export default class Round extends React.Component {
    getIcon(questionType) {
        let icon;

        switch (questionType) {
            case "city": {
                icon = <BreakImage />;
                break;
            }
            case "multi-regular": {
                icon = <PenImage />;
                break;
            }
            case "multi-music": {
                icon = <MusicImage />;
                break;
            }
            case "multi-who-there": {
                icon = <WhoThereImage />;
                break;
            }
            case "multi-return": {
                icon = <ReturnImage />;
                break;
            }
            case "dnd": {
                icon = <PenImage />;
                break;
            }
            case "the_list": {
                icon = <ListImage />;
                break;
            }
            case "closest_wins": {
                icon = <ClosestWinsImage />;
                break;
            }
            default: {
                break;
            }
        }

        return icon;
    }

    render() {
        return (
            <ul className="svt_episode-end-round">
                {this.props.round.questions.map((question, currentQIndex) => {
                    let lastQuestionIndex =
                        this.props.round.questions.length - 1;
                    let questionIsNormalFlow =
                        question.type === "city" ||
                        question.type === "multi-regular" ||
                        question.type === "multi-music" ||
                        question.type === "dnd";

                    return (
                        <li
                            className="svt_episode-end-round__item"
                            key={currentQIndex}
                        >
                            <div className="svt_episode-end-round__question-icons">
                                {question.type === "city" ? (
                                    <TrackBeginningImage className="svt_episode-end-round__track-start" />
                                ) : null}

                                <div className="svt_episode-end-round__icon">
                                    {this.getIcon(question.type)}
                                </div>

                                {currentQIndex !== lastQuestionIndex &&
                                questionIsNormalFlow ? (
                                    <TrackMiddleImage className="svt_episode-end-round__track-middle" />
                                ) : null}

                                {currentQIndex === lastQuestionIndex &&
                                questionIsNormalFlow ? (
                                    <TrackEndImage className="svt_episode-end-round__track-end" />
                                ) : null}
                            </div>
                            <EpisodeEndText
                                questionType={question.type}
                                questionPoints={question.points}
                                questionStars={question.starLevel}
                            />
                        </li>
                    );
                })}
            </ul>
        );
    }
}

Round.propTypes = {
    round: PropTypes.object.isRequired,
};
