export default {
    city: true,
    multi_choice: true,
    dnd: true,
    the_list: true,
    closest_wins: true,
    question_answers_points: false,
    user_points_statistics_total: false,
    user_points_statistics_city_1: false,
    user_points_statistics_city_2: false,
    user_points_statistics_city_3: false,
};
