import React from "react";
import PropTypes from "prop-types";
import ArrowIcon from "@svt/duo-shared-assets/assets/icons/arrow.svg?react";

import Round from "./round";

export default class Rounds extends React.Component {
    constructor() {
        super();

        this.state = {
            show: false,
        };

        this._toggle = this._toggle.bind(this);
    }

    _toggle() {
        this.setState((currentState) => ({
            show: !currentState.show,
        }));
    }

    render() {
        return (
            <div className="svt_episode-end-rounds">
                <div className="svt_episode-end-rounds__toggle-button-wrapper">
                    <button
                        data-testid="showMore"
                        type="button"
                        onClick={this._toggle}
                        className="svt_episode-end-rounds__toggle-button"
                    >
                        {this.state.show
                            ? "Dölj detaljerat resultat"
                            : "Visa detaljerat resultat"}
                        <ArrowIcon
                            className={`svt_episode-end-rounds__arrow${
                                this.state.show ? "-open" : ""
                            }`}
                        />
                    </button>
                </div>

                {this.state.show && (
                    <ul className="svt_episode-end-rounds__list">
                        {this.props.rounds.map((round, i) => {
                            return (
                                <li
                                    className="svt_episode-end-rounds__list-item"
                                    key={i}
                                >
                                    <h2 className="svt_episode-end-rounds__list-item-heading">
                                        {round.roundName}
                                    </h2>

                                    <Round round={round} />
                                </li>
                            );
                        })}
                    </ul>
                )}
            </div>
        );
    }
}

Rounds.propTypes = {
    rounds: PropTypes.array.isRequired,
    score: PropTypes.number.isRequired,
    starData: PropTypes.object,
};
