import React from "react";
import { AccountContext } from "@svt/duo-shared-components";

const ProblemInfo = () => {
    const accountContext = React.useContext(AccountContext);

    if (
        import.meta.env.VITE_PROBLEM_INFO === "true" &&
        accountContext.userData.loaded &&
        !accountContext.userData.isLoggedIn
    ) {
        return (
            <div
                style={{
                    border: "1px solid #ffff",
                    margin: "15px 20px",
                    padding: "5px 10px",
                }}
            >
                <h3 style={{ margin: "10px 0" }}>OBS!</h3>
                <p>
                    Just nu har vi vissa problem med att skapa konton, men du
                    kan fortfarande spela utan. Du kan fortfarande logga in.
                </p>
            </div>
        );
    }

    return null;
};

export default ProblemInfo;
