import React from "react";
import { connect } from "react-redux";
import ldThrottle from "lodash/throttle";
import PropTypes from "prop-types";
import LViS from "@monterosa/lvis-api";
import { localStorageHelper } from "@svt/duo-shared-components";

class CurrentEventSaver extends React.Component {
    constructor() {
        super();

        this.saveThrottled = ldThrottle(this.save, 1000);
    }

    save() {
        const saveObject = {
            dateTime: new Date().getTime(),
            eventId: LViS.getEvent().getUUID(),
            eventData: {
                questions: this.props.questions,
            },
        };
        localStorageHelper.setState("currentEvent", saveObject);
    }

    render() {
        if (LViS.getEvent() !== null) {
            this.saveThrottled();
        }

        return null;
    }
}

CurrentEventSaver.propTypes = {
    questions: PropTypes.object.isRequired,
};

export default connect((state) => ({
    questions: state.questions,
}))(CurrentEventSaver);
