import DuoCom from "@svt/duo-communication";
import { ContentStatus } from "@svt/duo-shared-components";
import { useEffect, useRef, useState } from "react";

import { achievementNameAndCode } from "../../useUnlockAchievement";
import Gauge from "../startScreen/achievements/gauge";
import { Achievement } from "./sharedTypes";

export default function UnlockedAchievementsSeason({
    isVisible,
}: {
    isVisible: boolean;
}) {
    const hasFetched = useRef(false);
    const [achievementDataById, setAchievementDataById] = useState<{
        isLoading: boolean;
        data: { [key: string]: Achievement } | Record<string, never>;
        error: boolean;
    }>({
        isLoading: true,
        data: {},
        error: false,
    });

    useEffect(() => {
        async function getAchievementMapAsync() {
            const achievementMap: { [key: string]: Achievement } =
                await DuoCom.Amigo.getAchievementMap({
                    app: `duo/pa-sparet`,
                    query: { includeProgressStatus: "true" },
                });

            setAchievementDataById({
                isLoading: false,
                data: achievementMap,
                error: false,
            });
        }

        if (isVisible && hasFetched.current === false) {
            hasFetched.current = true;
            getAchievementMapAsync();
        }
    }, [isVisible]);

    const achievementNameAndCodeArray = [
        achievementNameAndCode.FREDAGSTITTAREN,
        achievementNameAndCode["10X10"],
        achievementNameAndCode.BINGESPELAREN,
    ];

    const achievementNameAndCodeArrayPoängsamlaren = [
        achievementNameAndCode.POÄNGSAMLAREN_1,
        achievementNameAndCode.POÄNGSAMLAREN_2,
        achievementNameAndCode.POÄNGSAMLAREN_3,
    ];

    const achievementNameAndCodeArrayStjärnsamlaren = [
        achievementNameAndCode.STJÄRNSAMLAREN_1,
        achievementNameAndCode.STJÄRNSAMLAREN_2,
        achievementNameAndCode.STJÄRNSAMLAREN_3,
    ];

    return (
        <div className="unlockAchievementsSeason__wrapper">
            <div className="unlockAchievementsSeason__heading">
                Dina säsongsprestationer
            </div>
            <ContentStatus
                loaded={!achievementDataById.isLoading}
                error={achievementDataById.error}
            >
                {() => {
                    {
                        const currentPointLevel =
                            achievementNameAndCodeArrayPoängsamlaren.find(
                                (achievementCode) => {
                                    if (
                                        !achievementDataById.data[
                                            achievementCode
                                        ].unlocked
                                    ) {
                                        return true;
                                    }
                                },
                            ) ?? achievementNameAndCode.POÄNGSAMLAREN_3;
                        const currentStarLevel =
                            achievementNameAndCodeArrayStjärnsamlaren.find(
                                (achievementCode) => {
                                    if (
                                        !achievementDataById.data[
                                            achievementCode
                                        ].unlocked
                                    ) {
                                        return true;
                                    }
                                },
                            ) ?? achievementNameAndCode.STJÄRNSAMLAREN_3;

                        const poängsamlaren3 =
                            achievementDataById.data[
                                achievementNameAndCode.POÄNGSAMLAREN_3
                            ];
                        const stjärnsamlaren3 =
                            achievementDataById.data[
                                achievementNameAndCode.STJÄRNSAMLAREN_3
                            ];

                        if (!poängsamlaren3 || !stjärnsamlaren3) {
                            return "Det gick tyvärr inte att hämta data för dina prestationer";
                        }

                        return (
                            <>
                                <ul className="unlockAchievementsSeason__list">
                                    {achievementNameAndCodeArray.map(
                                        (achievementCode) => {
                                            const percentage =
                                                (achievementDataById.data[
                                                    achievementCode
                                                ].progress.status.current /
                                                    achievementDataById.data[
                                                        achievementCode
                                                    ].progress.status.goal) *
                                                100;

                                            return (
                                                <li
                                                    className="unlockAchievementsSeason__list-item"
                                                    key={achievementCode}
                                                >
                                                    <Gauge
                                                        valueEnd={percentage}
                                                        valueStart={0}
                                                        pathColor={
                                                            achievementDataById
                                                                .data[
                                                                achievementCode
                                                            ].unlocked
                                                                ? "#FEFDD9"
                                                                : undefined
                                                        }
                                                    >
                                                        <img
                                                            className="unlockAchievementsSeason__image"
                                                            src={
                                                                achievementDataById
                                                                    .data[
                                                                    achievementCode
                                                                ].unlocked
                                                                    ? achievementDataById
                                                                          .data[
                                                                          achievementCode
                                                                      ].info
                                                                          .image_unlocked
                                                                    : achievementDataById
                                                                          .data[
                                                                          achievementCode
                                                                      ].info
                                                                          .image_locked
                                                            }
                                                        />
                                                    </Gauge>
                                                    <div className="unlockAchievementsSeason__title">
                                                        {
                                                            achievementDataById
                                                                .data[
                                                                achievementCode
                                                            ].progress.status
                                                                .current
                                                        }{" "}
                                                        AV{" "}
                                                        {
                                                            achievementDataById
                                                                .data[
                                                                achievementCode
                                                            ].progress.status
                                                                .goal
                                                        }
                                                    </div>

                                                    <div className="unlockAchievementsSeason__text">
                                                        {achievementDataById
                                                            .data[
                                                            achievementCode
                                                        ].unlocked
                                                            ? achievementDataById
                                                                  .data[
                                                                  achievementCode
                                                              ].info
                                                                  .title_locked
                                                            : achievementDataById
                                                                  .data[
                                                                  achievementCode
                                                              ].info
                                                                  .title_unlocked}
                                                    </div>
                                                </li>
                                            );
                                        },
                                    )}
                                </ul>

                                <div className="unlockAchievementsSeason__space" />

                                <ul className="unlockAchievementsSeason__list">
                                    {[currentPointLevel, currentStarLevel].map(
                                        (achievementCode) => {
                                            const percentage =
                                                (achievementDataById.data[
                                                    achievementCode
                                                ].progress.status.current /
                                                    achievementDataById.data[
                                                        achievementCode
                                                    ].progress.status.goal) *
                                                100;

                                            return (
                                                <li
                                                    className="unlockAchievementsSeason__list-item"
                                                    key={achievementCode}
                                                >
                                                    <Gauge
                                                        valueEnd={percentage}
                                                        valueStart={0}
                                                        pathColor={
                                                            achievementDataById
                                                                .data[
                                                                achievementCode
                                                            ].unlocked
                                                                ? "#FEFDD9"
                                                                : undefined
                                                        }
                                                    >
                                                        <img
                                                            className="unlockAchievementsSeason__image"
                                                            src={
                                                                achievementDataById
                                                                    .data[
                                                                    achievementCode
                                                                ].unlocked
                                                                    ? achievementDataById
                                                                          .data[
                                                                          achievementCode
                                                                      ].info
                                                                          .image_unlocked
                                                                    : achievementDataById
                                                                          .data[
                                                                          achievementCode
                                                                      ].info
                                                                          .image_locked
                                                            }
                                                        />
                                                    </Gauge>
                                                    <div className="unlockAchievementsSeason__title">
                                                        {
                                                            achievementDataById
                                                                .data[
                                                                achievementCode
                                                            ].progress.status
                                                                .current
                                                        }{" "}
                                                        AV{" "}
                                                        {
                                                            achievementDataById
                                                                .data[
                                                                achievementCode
                                                            ].progress.status
                                                                .goal
                                                        }
                                                    </div>

                                                    <div className="unlockAchievementsSeason__text">
                                                        {achievementDataById
                                                            .data[
                                                            achievementCode
                                                        ].unlocked
                                                            ? achievementDataById
                                                                  .data[
                                                                  achievementCode
                                                              ].info
                                                                  .title_locked
                                                            : achievementDataById
                                                                  .data[
                                                                  achievementCode
                                                              ].info
                                                                  .title_unlocked}
                                                    </div>
                                                </li>
                                            );
                                        },
                                    )}
                                </ul>
                            </>
                        );
                    }
                }}
            </ContentStatus>
        </div>
    );
}
