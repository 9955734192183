import React from "react";
import { analytics } from "@svt/duo-shared-components";

export default class Paused extends React.Component {
    componentDidMount() {
        analytics.trackViewEvent("omsynk");
    }

    render() {
        return (
            <div className="svt_syncing">
                <div className="svt_syncing__wrapper-outer">
                    <div className="svt_syncing__wrapper-inner">
                        <div className="svt_syncing__circle" />
                        <div className="svt_syncing__message">
                            Duo tar dig till
                            <br /> rätt plats i spelet
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
