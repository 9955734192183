import React from "react";
import PropTypes from "prop-types";
import AnswerBoxMarkup from "../../../shared/answerBox";
import AnswerBoxMultiAnswer from "./answerBoxMultiAnswer";

export default class AnswerBoxMultiChoice extends React.Component {
    _parseArray(array) {
        return array.length > 1 ? array.join(", ") : array[0];
    }

    _isMultiAnswer() {
        return this.props.correctAnswer.length > 1;
    }

    _numberOfCorrect() {
        let noOfCorrectUserAnswers = 0;
        let noOfCorrectAnswers = 0;

        this.props.correctAnswer.forEach((singleCorrectAnswer, i) => {
            noOfCorrectAnswers++;
            if (this.props.correctAnswer.includes(this.props.answer[i])) {
                noOfCorrectUserAnswers++;
            }
        });

        return { noOfCorrectUserAnswers, noOfCorrectAnswers };
    }

    _getMultiAnswerHeaderText() {
        const { noOfCorrectUserAnswers, noOfCorrectAnswers } =
            this._numberOfCorrect();

        let headerText;
        if (noOfCorrectUserAnswers === noOfCorrectAnswers) {
            headerText = "Rätt svar";
        } else if (noOfCorrectUserAnswers === 0) {
            headerText = "Fel svar";
        } else {
            headerText = `${noOfCorrectUserAnswers} av ${noOfCorrectAnswers} rätt`;
        }

        return headerText;
    }

    render() {
        let modifier;
        let bodyText;
        let headText;
        let { answer, correctAnswer, isCorrect } = this.props;

        if (answer) {
            if (isCorrect) {
                // Has answer and is all correct
                if (this._isMultiAnswer()) {
                    const { noOfCorrectUserAnswers } = this._numberOfCorrect();
                    modifier = noOfCorrectUserAnswers > 0 ? "-correct" : "";
                    headText = this._getMultiAnswerHeaderText();
                    bodyText = (
                        <AnswerBoxMultiAnswer
                            answer={answer}
                            correctAnswer={correctAnswer}
                        />
                    );
                } else {
                    modifier = "-correct";
                    headText = "Rätt svar";
                    bodyText = correctAnswer[0];
                }
            } else if (this._isMultiAnswer()) {
                const { noOfCorrectUserAnswers } = this._numberOfCorrect();
                modifier =
                    noOfCorrectUserAnswers === 0 ? "-incorrect" : "-correct";
                headText = this._getMultiAnswerHeaderText();
                bodyText = (
                    <AnswerBoxMultiAnswer
                        answer={answer}
                        correctAnswer={correctAnswer}
                    />
                );
            } else {
                modifier = "-incorrect";
                headText = "Fel svar";
                bodyText = answer[0];
            }
        } else {
            // Has no answer
            modifier = "-no-answer";
            headText = "Inget svar";
            bodyText = "-";
        }

        return (
            <AnswerBoxMarkup
                headText={headText}
                modifier={modifier}
                hasAnswered={Boolean(answer)}
                bodyText={bodyText}
                isCorrect={isCorrect}
                correctAnswer={this._parseArray(correctAnswer)}
            />
        );
    }
}

AnswerBoxMultiChoice.propTypes = {
    answer: PropTypes.array,
    correctAnswer: PropTypes.array.isRequired,
    isCorrect: PropTypes.bool.isRequired,
};
