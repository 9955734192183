import duoCom from "@svt/duo-communication";

export default function closeApp(isEndScreen, isLoggedIn, signUp) {
    if (duoCom.supports("verify")) {
        if (isEndScreen) {
            if (isLoggedIn) {
                if (duoCom.supports("closeView")) {
                    duoCom.closeView();
                }
            }

            if (!isLoggedIn) {
                duoCom.verify(
                    {
                        title: "Vill du avsluta spelet?",
                        message:
                            "Ditt resultat kommer gå förlorat. Om du skapar ett konto innan du avslutar så sparar vi ditt resultat.",
                        declineButton: "Avsluta spelet",
                        approveButton: "Skapa konto",
                    },
                    (response) => {
                        switch (response.userResponse) {
                            case true: {
                                signUp();
                                break;
                            }
                            case false: {
                                if (duoCom.supports("closeView")) {
                                    duoCom.closeView();
                                }
                                break;
                            }
                            default: {
                                break;
                            }
                        }
                    },
                );
            }
        } else {
            duoCom.verify(
                {
                    title: "Vill du avsluta spelet?",
                    message:
                        "Vi sparar dina poäng i en timme om du vill fortsätta spela.",
                    declineButton: "Avbryt",
                    approveButton: "Avsluta spelet",
                },
                (response) => {
                    if (response.userResponse) {
                        if (duoCom.supports("disableActiveMode")) {
                            duoCom.disableActiveMode();
                        }
                        if (duoCom.supports("closeView")) {
                            duoCom.closeView();
                        }
                    }
                },
            );
        }
    }
}
