import React, {
    useCallback,
    useRef,
    useEffect,
    useState,
    useContext,
} from "react";
import PropTypes from "prop-types";
import { Map as OlMap, View, Feature } from "ol";
import { transform } from "ol/proj";
import { fromExtent, circular } from "ol/geom/Polygon";
import { Point } from "ol/geom";
import { Style, Stroke, Fill, Icon } from "ol/style";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { XYZ, Vector as VectorSource } from "ol/source";
import * as Interactions from "ol/interaction";
import { AccountContext, FriendsContext } from "@svt/duo-shared-components";
import duoCom from "@svt/duo-communication";

import MapPlus from "../../../../assets/icons/mapPlus.svg?react";
import MapMinus from "../../../../assets/icons/mapMinus.svg?react";
import mapPinPurple from "../../../../assets/inline/mapPinPurple.svg";
import mapCircleRed from "../../../../assets/inline/mapCircleRed.svg";
import mapPinMeWithName from "../../../../assets/inline/mapPinMeWithName.png";
import mapPinMeWithoutName from "../../../../assets/inline/mapPinMeWithoutName.png";
import mapPinFriendWithName from "../../../../assets/inline/mapPinFriendWithName.png";
import mapPinFriendWithoutName from "../../../../assets/inline/mapPinFriendWithoutName.png";

const zoomButtonDelta = 0.5;
const minZoom = 3;
const maxZoom = 8;
const answerMarker = "answerMarker";

function getImageObject(imgSrc) {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.crossOrigin = "Anonymous";
        image.onload = () => {
            resolve(image);
        };
        image.onerror = () => {
            reject(new Error());
        };
        image.src = imgSrc;
    });
}

function createRoundImage({ imgSrc, size }) {
    return new Promise((resolve, reject) => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const image = new Image();
        image.crossOrigin = "Anonymous";
        image.src = imgSrc;

        image.onload = function () {
            canvas.width = size;
            canvas.height = size;

            const radius = Math.min(canvas.width, canvas.height) / 2;
            ctx.beginPath();
            ctx.arc(
                canvas.width / 2,
                canvas.height / 2,
                radius,
                0,
                2 * Math.PI,
            );
            ctx.clip();

            ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

            ctx.closePath();
            ctx.restore();

            const dataURL = canvas.toDataURL();

            resolve(dataURL);
        };

        image.onerror = function () {
            reject(new Error("Failed to load image"));
        };
    });
}

async function createRoundImageCanvas(imgSrc) {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const dataURL = await createRoundImage({ imgSrc: imgSrc, size: 80 });

    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "Anonymous";

        img.src = dataURL;
        img.onload = function () {
            ctx.drawImage(img, 0, 0);
            resolve(canvas);
        };
        img.onError = function () {
            reject(new Error("Failed to load image"));
        };
    });
}

async function getMapPin({ showName, isMe, avatarUrl, username }) {
    function shortNameEllipsis(nameToCheck, nameMaxLength) {
        if (nameToCheck.length > nameMaxLength) {
            return `${nameToCheck.substring(0, nameMaxLength)}…`;
        }

        return nameToCheck;
    }

    let backgroundImage;
    let height;
    let width;

    if (showName) {
        if (isMe) {
            backgroundImage = mapPinMeWithName;
        } else {
            backgroundImage = mapPinFriendWithName;
        }
        width = 530;
        height = 275;
    } else {
        if (isMe) {
            backgroundImage = mapPinMeWithoutName;
        } else {
            backgroundImage = mapPinFriendWithoutName;
        }
        width = 98;
        height = 144;
    }

    let canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.height = height;
    canvas.width = width;

    ctx.drawImage(await getImageObject(backgroundImage), 0, 0);

    let avatarX;
    let avatarY;
    if (showName) {
        avatarX = 225;
        avatarY = 140;
    } else {
        avatarX = 9;
        avatarY = 9;
    }

    ctx.drawImage(
        await createRoundImageCanvas(`${avatarUrl}?fixCors`),
        avatarX,
        avatarY,
    );

    if (showName) {
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillStyle = "rgb(255, 255, 255)";
        ctx.font = "700 35px Publik";
        ctx.fillText(shortNameEllipsis(username, 15), 265, 55);
    }

    const dataURL = canvas.toDataURL();
    canvas = null;

    return dataURL;
}

function ClosestWinsMapShared(props) {
    const friendsContext = useContext(FriendsContext);
    const accountContext = useContext(AccountContext);
    const initialPropsRef = useRef(props); // We don't need/want to listen to changes of any props, so we store the initial props in a ref
    const friendsContextRef = useRef(friendsContext); //STORE in ref. Don't want re-renders.
    const elRef = useRef();
    const mapRef = useRef();
    const extentRef = useRef();
    const pointsRef = useRef();
    const [zoomState, setZoomState] = useState(null);
    const zIndexRef = useRef(1);

    const getFeatures = useCallback(async () => {
        const features = {
            extent: [],
            points: [],
        };

        // Extent border
        const extentBorder = new Feature({
            geometry: fromExtent(initialPropsRef.current.extent),
        });

        extentBorder.setStyle(
            new Style({
                stroke: new Stroke({
                    color: "rgba(0, 0, 0, 0.4)",
                    width: 3,
                }),
            }),
        );

        features.extent.push(extentBorder);

        // User answer marker
        if (
            (initialPropsRef.current.version === "waiting" ||
                initialPropsRef.current.version === "result") &&
            initialPropsRef.current.userAnswer
        ) {
            const userAnswerTransformed = transform(
                initialPropsRef.current.userAnswer,
                "EPSG:4326",
                "EPSG:3857",
            );

            const userAnswerMarker = new Feature({
                geometry: new Point(userAnswerTransformed),
                name: answerMarker,
            });

            const avatarUrl = accountContext.userData.isLoggedIn
                ? duoCom.Amigo.getAvatarUrlForUnoId({
                      unoId: accountContext.userData.uno.user.id,
                  })
                : duoCom.Amigo.getDefaultAvatarUrl();

            userAnswerMarker.setProperties({
                avatarUrl: avatarUrl,
                username: accountContext.userData.isLoggedIn
                    ? accountContext.userData.amigo.username
                    : "Jag",
                isOpen: false,
                isMe: true,
            });

            userAnswerMarker.setStyle(
                new Style({
                    image: new Icon({
                        src: await getMapPin({
                            showName: false,
                            isMe: true,
                            avatarUrl: avatarUrl,
                        }),
                        scale: 0.5,
                        anchor: [0.5, 1],
                    }),
                }),
            );

            features.points.push(userAnswerMarker);
        }

        // Friends answers markers
        if (
            initialPropsRef.current.version === "result" &&
            initialPropsRef.current.friendsAnswers.length > 0
        ) {
            for (const friendsAnswer of initialPropsRef.current
                .friendsAnswers) {
                if (friendsContextRef.current.friends[friendsAnswer.unoId]) {
                    const friendsAnswerTransformed = transform(
                        friendsAnswer.answer,
                        "EPSG:4326",
                        "EPSG:3857",
                    );

                    const friendsAnswerMarker = new Feature({
                        geometry: new Point(friendsAnswerTransformed),
                        name: answerMarker,
                    });

                    const avatarUrl = duoCom.Amigo.getAvatarUrlForUnoId({
                        unoId: friendsAnswer.unoId,
                    });

                    friendsAnswerMarker.setProperties({
                        avatarUrl: avatarUrl,
                        username:
                            friendsContextRef.current.friends[
                                friendsAnswer.unoId
                            ].username,
                        isOpen: false,
                        isMe: false,
                    });

                    friendsAnswerMarker.setStyle(
                        new Style({
                            image: new Icon({
                                src: await getMapPin({
                                    showName: false,
                                    isMe: false,
                                    avatarUrl: avatarUrl,
                                }),
                                scale: 0.5,
                                anchor: [0.5, 1],
                            }),
                        }),
                    );

                    features.points.push(friendsAnswerMarker);
                }
            }
        }

        if (
            initialPropsRef.current.version === "result" &&
            initialPropsRef.current.destination
        ) {
            const destinationTransformed = transform(
                initialPropsRef.current.destination,
                "EPSG:4326",
                "EPSG:3857",
            );

            // Destination level circles
            // https://github.com/openlayers/openlayers/issues/12859
            if (
                initialPropsRef.current.pointsLimits &&
                initialPropsRef.current.pointsLimits.length
            ) {
                initialPropsRef.current.pointsLimits.forEach((pointLimit) => {
                    const destinationCircle = new Feature({
                        geometry: new Point(destinationTransformed),
                        radius: pointLimit * 1000,
                    });

                    const destinationCircleCenter = destinationCircle
                        .getGeometry()
                        .clone();
                    destinationCircleCenter.transform("EPSG:3857", "EPSG:4326");

                    const circle = circular(
                        destinationCircleCenter.getCoordinates(),
                        destinationCircle.get("radius"),
                        128,
                    );

                    circle.transform("EPSG:4326", "EPSG:3857");

                    destinationCircle.setStyle(
                        new Style({
                            geometry: circle,
                            fill: new Fill({
                                color: "rgba(103, 66, 185, 0.33)",
                            }),
                        }),
                    );

                    features.points.push(destinationCircle);
                });
            }

            // Destination marker
            const destinationMarker = new Feature({
                geometry: new Point(destinationTransformed),
            });

            destinationMarker.setStyle(
                new Style({
                    image: new Icon({
                        src: mapCircleRed,
                    }),
                }),
            );

            features.points.push(destinationMarker);
        }

        // Return all features
        return features;
    }, [accountContext.userData]);

    useEffect(() => {
        async function initMap() {
            extentRef.current = new VectorSource();
            pointsRef.current = new VectorSource();

            mapRef.current = new OlMap({
                controls: [],
                interactions: Interactions.defaults({ pinchRotate: false }),
                view: new View({
                    projection: "EPSG:3857",
                    center: transform(
                        initialPropsRef.current.center,
                        "EPSG:4326",
                        "EPSG:3857",
                    ),
                    zoom: 4,
                    minZoom: minZoom,
                    maxZoom: maxZoom,
                    extent: initialPropsRef.current.extent,
                    constrainOnlyCenter: true,
                    smoothExtentConstraint: false,
                    smoothResolutionConstraint: false,
                }),
                layers: [
                    new TileLayer({
                        source: new XYZ({
                            url: "https://www.svtstatic.se/narmast-vinner/raster/{z}/{x}/{y}.png",
                            tilePixelRatio: 2,
                        }),
                    }),
                    new VectorLayer({
                        source: extentRef.current,
                    }),
                    new VectorLayer({
                        source: pointsRef.current,
                    }),
                ],
                target: elRef.current,
            });

            const features = await getFeatures();
            extentRef.current.addFeatures(features.extent);
            pointsRef.current.addFeatures(features.points);

            if (initialPropsRef.current.zoomToExtent) {
                mapRef.current.getView().fit(extentRef.current.getExtent());
            } else if (pointsRef.current.getFeatures().length) {
                mapRef.current.getView().fit(pointsRef.current.getExtent(), {
                    padding: [100, 100, 100, 100],
                });
            }
        }

        initMap();

        function onMoveEnd() {
            // Set answer
            if (initialPropsRef.current.version === "answer") {
                const mapCenter = mapRef.current.getView().getCenter();
                const coords = transform(mapCenter, "EPSG:3857", "EPSG:4326");
                initialPropsRef.current.onModify(coords);
            }

            // Get new zoom
            const newZoom = mapRef.current.getView().getZoom();

            setZoomState((currentState) => {
                if (currentState !== newZoom) {
                    return newZoom;
                }

                return currentState;
            });
        }

        function mapClickEvent(clickEvent) {
            mapRef.current.forEachFeatureAtPixel(
                clickEvent.pixel,
                async (feature) => {
                    const featureProps = feature.getProperties();

                    if (featureProps.name === answerMarker) {
                        if (!featureProps.isOpen) {
                            zIndexRef.current = zIndexRef.current + 1;
                        } else {
                            //Reset MapPin to be below mapCircleRed
                            zIndexRef.current = 0;
                        }

                        feature.setStyle(
                            new Style({
                                image: new Icon({
                                    src: await getMapPin({
                                        showName: !featureProps.isOpen,
                                        isMe: featureProps.isMe,
                                        avatarUrl: featureProps.avatarUrl,
                                        username: featureProps.username,
                                    }),
                                    scale: 0.5,
                                    anchor: [0.5, 1],
                                }),
                                zIndex: zIndexRef.current,
                            }),
                        );

                        feature.set("isOpen", !featureProps.isOpen);
                    }
                },
            );
        }

        mapRef.current.on("moveend", onMoveEnd);
        mapRef.current.on("click", mapClickEvent);

        return () => {
            mapRef.current.un("moveend", onMoveEnd);
            mapRef.current.un("click", mapClickEvent);
            mapRef.current.setTarget("");
        };
    }, [getFeatures]);

    function handleZoomClick(delta) {
        const curVal = mapRef.current.getView().getZoom();
        mapRef.current.getView().setZoom(curVal + delta);
    }

    function lockAnswer() {
        if (initialPropsRef.current.lockAnswer) {
            initialPropsRef.current.lockAnswer();
        }
    }

    return (
        <>
            <div className="closest-wins-map" ref={elRef} />

            {initialPropsRef.current.version === "answer" && (
                <img
                    src={mapPinPurple}
                    alt=""
                    className="closest-wins-map-pin"
                />
            )}

            {initialPropsRef.current.version === "answer" && (
                <button
                    type="button"
                    className="closest-wins-map-lock-button"
                    onClick={() => {
                        lockAnswer();
                    }}
                >
                    Lås in svar
                </button>
            )}

            <div className="closest-wins-map-zoom-wrapper">
                <button
                    type="button"
                    aria-label="Zooma in"
                    className="closest-wins-map-zoom-button"
                    onClick={() => handleZoomClick(zoomButtonDelta)}
                    disabled={zoomState === maxZoom}
                >
                    <MapPlus />
                </button>

                <button
                    type="button"
                    aria-label="Zooma ut"
                    className="closest-wins-map-zoom-button"
                    onClick={() => handleZoomClick(-zoomButtonDelta)}
                    disabled={zoomState === minZoom}
                >
                    <MapMinus />
                </button>
            </div>
        </>
    );
}

ClosestWinsMapShared.propTypes = {
    version: PropTypes.string.isRequired,
    center: PropTypes.array.isRequired,
    zoomToExtent: PropTypes.bool.isRequired,
    extent: PropTypes.array.isRequired,
    lockAnswer: PropTypes.func,
    onModify: PropTypes.func,
    userAnswer: PropTypes.array,
    friendsAnswers: PropTypes.array,
    destination: PropTypes.array,
    pointsLimits: PropTypes.array,
};

export default ClosestWinsMapShared;
