import { useEffect, useContext, useRef, useState } from "react";
import duoCom from "@svt/duo-communication";
import LViS from "@monterosa/lvis-api";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { AccountContext } from "@svt/duo-shared-components";

import UserScore from "../../../shared/episodeEnd/userScore.jsx";
import Share from "../../../shared/share.jsx";
import ErrorBoundary from "../../../errorBoundary.jsx";
import ProblemInfo from "../../../shared/problemInfo.jsx";
import EpisodeEndRounds from "../../../shared/episodeEnd/rounds.jsx";
import TopListEpisode from "./topListEpisode.tsx";
import { CLEAR_EVENT, GO_TO_TAB } from "../../../../redux/actions.js";
import { BackToStartTeleporter } from "../../../app.jsx";
import ArrowIcon from "../../../../assets/icons/arrow.svg?react";

function EpisodeSummary({
    score,
    starData,
    rounds,
    episodeNumber,
    episodeHighscore,
}) {
    const dispatch = useDispatch();
    const accountContext = useContext(AccountContext);
    const topElementRef = useRef(null);
    const [highscoreState, setHighscoreState] = useState(episodeHighscore);

    useEffect(() => {
        async function getHighscore() {
            if (accountContext.userData.amigo.demographic?.postal_code) {
                const highscoreResponse = await duoCom.Highscore.fetch({
                    season: LViS.Project.getSettings().season_number.toString(),
                    episode: episodeNumber,
                    postal_code:
                        accountContext.userData.amigo.demographic
                            ?.postal_code ?? undefined,
                });

                setHighscoreState((currentState) => ({
                    ...currentState,
                    municipality: highscoreResponse.episodes[0].municipality,
                    county: highscoreResponse.episodes[0].county,
                }));
            }
        }

        duoCom.events.addEventListener(
            "amigodemographics:change",
            getHighscore,
        );

        return () => {
            duoCom.events.removeEventListener(
                "amigodemographics:change",
                getHighscore,
            );
        };
    }, [accountContext.userData.amigo.demographic?.postal_code, episodeNumber]);

    useEffect(() => {
        topElementRef.current.scrollIntoView();
    }, []);

    function _backToStart() {
        dispatch({
            type: CLEAR_EVENT,
        });
        dispatch({ type: GO_TO_TAB, payload: "history" });
    }

    return (
        <div className="svt_episode-summary" ref={topElementRef}>
            <div className="svt_episode-summary__scroller">
                <BackToStartTeleporter.Source>
                    <button
                        type="button"
                        className="svt_episode-summary__back-to-start-button"
                        onClick={() => {
                            _backToStart();
                        }}
                    >
                        <div className="svt_episode-summary__arrow-wrapper">
                            <ArrowIcon />
                        </div>
                    </button>
                </BackToStartTeleporter.Source>

                <h1 className="svt_episode-summary__header">
                    Ditt resultat för avsnittet
                </h1>

                <UserScore userScore={score} userStars={starData?.bonus} />

                <Share score={score} stars={starData?.bonus} />

                <ErrorBoundary
                    errorTextComponent={
                        <span>
                            Det gick tyvärr inte att hämta resultat för episoden
                        </span>
                    }
                    showCloseGame={false}
                >
                    <TopListEpisode
                        episodeNumber={episodeNumber}
                        episodeHighscore={highscoreState}
                    />
                </ErrorBoundary>

                <ProblemInfo />

                <ErrorBoundary
                    errorTextComponent={
                        <span>
                            Det gick tyvärr inte att hämta rundorna för episoden
                        </span>
                    }
                    showCloseGame={false}
                >
                    {rounds && starData ? (
                        <EpisodeEndRounds
                            rounds={rounds}
                            starData={starData}
                            score={score}
                        />
                    ) : (
                        <div />
                    )}
                </ErrorBoundary>
            </div>
        </div>
    );
}

EpisodeSummary.propTypes = {
    score: PropTypes.number.isRequired,
    episodeNumber: PropTypes.number.isRequired,
    starData: PropTypes.shape({
        bonus: PropTypes.number,
        max: PropTypes.number,
    }),
    rounds: PropTypes.array,
    episodeHighscore: PropTypes.object.isRequired,
};

export default EpisodeSummary;
