import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { CITY_LEVEL_SET } from "../../../redux/actions";

class CityLevelCounter extends React.Component {
    constructor(props) {
        super(props);

        this._setup();
    }

    componentDidMount() {
        this._checkPoints(this.props.countdown);
    }

    shouldComponentUpdate(nextProps) {
        this._checkPoints(nextProps.countdown);

        return false;
    }

    _setup() {
        this.currentPoints = 0;

        this.pointsArray = [
            { elapsed: this.props.element.points_10, points: 10 },
            { elapsed: this.props.element.points_8, points: 8 },
            { elapsed: this.props.element.points_6, points: 6 },
            { elapsed: this.props.element.points_4, points: 4 },
        ];
    }

    _checkPoints(countdown) {
        let points = this._getPoints(countdown);

        if (points !== this.currentPoints) {
            this.currentPoints = points;

            this.props.dispatch({
                type: CITY_LEVEL_SET,
                level: points,
            });
        }
    }

    _getPoints(countdown) {
        let points = 0;

        if (countdown.duration > -1 && countdown.now > -1) {
            let timeElapsed = countdown.duration - countdown.now;

            for (var i = 0; i < this.pointsArray.length; i++) {
                if (timeElapsed <= this.pointsArray[i].elapsed) {
                    points = this.pointsArray[i].points;
                    break;
                }
            }

            if (!points) {
                points = 2;
            }
        }

        return points;
    }

    render() {
        return null;
    }
}

CityLevelCounter.propTypes = {
    element: PropTypes.object.isRequired,
    countdown: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
};

export default connect((state) => ({
    countdown: state.countdown,
}))(CityLevelCounter);
