import React from "react";
import {
    UserRanking,
    UserType,
} from "@svt/duo-communication/dist/api/highscore-types";

import TopListCategory from "./category";
import ToppListShared from "./shared";

export default function Episode({
    episodeNumber,
    list,
    geography,
    user,
}: {
    episodeNumber: string | number;
    list: UserType[];
    geography: {
        header: string;
        name: string;
        toplist: React.JSX.Element;
    }[];
    user?: UserRanking;
}) {
    return (
        <div className="history-episode-toplist">
            <h2 className="history-episode-toplist__title">
                Topplista för avsnitt {episodeNumber}
            </h2>
            <TopListCategory
                categories={[
                    ...geography,
                    {
                        header: "Sverige",
                        name: "Sverige",
                        toplist: <ToppListShared topList={list} user={user} />,
                    },
                ]}
            />
        </div>
    );
}
