import React from "react";
import PropTypes from "prop-types";

export default class AnswerBoxMarkup extends React.Component {
    render() {
        let {
            headText,
            modifier,
            hasAnswered,
            bodyText,
            isCorrect,
            correctAnswer,
        } = this.props;

        let bodyContent;
        if (hasAnswered) {
            if (typeof bodyText === "string") {
                bodyContent = (
                    <span className={`svt_answer-box__body_text ${modifier}`}>
                        {bodyText}
                    </span>
                );
            } else {
                bodyContent = bodyText;
            }
        } else {
            bodyContent = <span>{bodyText}</span>;
        }

        return (
            <div className="svt_answer-box">
                <div className={`svt_answer-box__body ${modifier}`}>
                    {headText && (
                        <div className="svt_answer-box__head">
                            <span>{headText}</span>
                        </div>
                    )}
                    {isCorrect !== null && !isCorrect && (
                        <div className="svt_answer-box__correct">
                            <span>{`Rätt svar: ${correctAnswer}`}</span>
                        </div>
                    )}
                    <span className={`svt_answer-box__userAnswer ${modifier}`}>
                        {bodyContent}
                    </span>
                </div>
            </div>
        );
    }
}

AnswerBoxMarkup.propTypes = {
    modifier: PropTypes.string.isRequired,
    headText: PropTypes.string.isRequired,
    bodyText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    correctAnswer: PropTypes.string,
    isCorrect: PropTypes.bool,
    hasAnswered: PropTypes.bool.isRequired,
};
