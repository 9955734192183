import DuoCom from "@svt/duo-communication";
import { Fragment, useEffect, useState } from "react";
import { ContentStatus } from "@svt/duo-shared-components";

import { achievementNameAndCode } from "../../../useUnlockAchievement";

import Gauge from "./gauge";
import { Achievement } from "../../episodeEnd/sharedTypes";

const gaugeCompleteColorIndex = ["#49A3F1", "#64DD9D", "#ea5d3f"];

export default function Achievements() {
    const [achievementDataById, setAchievementDataById] = useState<{
        isLoading: boolean;
        data: { [key: string]: Achievement } | Record<string, never>;
        error: boolean;
    }>({
        isLoading: true,
        data: {},
        error: false,
    });

    useEffect(() => {
        async function getAchievementMapAsync() {
            const achievementMap: { [key: string]: Achievement } =
                await DuoCom.Amigo.getAchievementMap({
                    app: `duo/pa-sparet`,
                    query: { includeProgressStatus: "true" },
                });

            setAchievementDataById({
                isLoading: false,
                data: achievementMap,
                error: false,
            });
        }

        getAchievementMapAsync();
    }, []);

    const achievementNameAndCodeArray = [
        achievementNameAndCode.FREDAGSTITTAREN,
        achievementNameAndCode["10X10"],
        achievementNameAndCode.BINGESPELAREN,
    ];

    const achievementNameAndCodeArrayPoängsamlaren = [
        achievementNameAndCode.POÄNGSAMLAREN_1,
        achievementNameAndCode.POÄNGSAMLAREN_2,
        achievementNameAndCode.POÄNGSAMLAREN_3,
    ];

    const achievementNameAndCodeArrayStjärnsamlaren = [
        achievementNameAndCode.STJÄRNSAMLAREN_1,
        achievementNameAndCode.STJÄRNSAMLAREN_2,
        achievementNameAndCode.STJÄRNSAMLAREN_3,
    ];

    function getWasPreviousUnlocked({
        index,
        currentAchievementGroup,
    }: {
        index: number;
        currentAchievementGroup: number[];
    }) {
        if (index > 0) {
            return achievementDataById.data[currentAchievementGroup[index - 1]]
                .unlocked;
        } else {
            return true;
        }
    }

    function calculateProgress({
        index,
        currentAchievementGroup,
    }: {
        index: number;
        currentAchievementGroup: number[];
    }) {
        //Only display progress if the previous level is unlocked
        return getWasPreviousUnlocked({
            index,
            currentAchievementGroup,
        })
            ? Math.floor(
                  (achievementDataById.data[currentAchievementGroup[index]]
                      .progress.status.current /
                      achievementDataById.data[currentAchievementGroup[index]]
                          .progress.status.goal) *
                      100,
              )
            : 0;
    }

    function getConnectedBar({
        index,
        currentAchievementGroup,
        isCurrentUnlocked,
    }: {
        index: number;
        currentAchievementGroup: number[];
        isCurrentUnlocked: boolean;
    }) {
        const wasPreviousUnlocked = getWasPreviousUnlocked({
            index,
            currentAchievementGroup,
        });

        return (
            index !== 0 && (
                <li className="unlockedAchievementsStart__listCollector-bar-wrapper">
                    <div
                        className={`unlockedAchievementsStart__listCollector-bar-progress ${index === 1 ? (wasPreviousUnlocked ? (isCurrentUnlocked ? "-bar1Complete" : "-bar1PartialComplete") : "-barNotComplete") : wasPreviousUnlocked ? (isCurrentUnlocked ? "-bar2Complete" : "-bar2PartialComplete") : "-barNotComplete"}`}
                    />
                </li>
            )
        );
    }

    function currentIndexLevel(achievementCodes: number[]) {
        let latestIndex = achievementCodes.length;

        for (let index = 0; index < achievementCodes.length; index++) {
            const achievementCode = achievementCodes[index];
            if (!achievementDataById.data[achievementCode].unlocked) {
                latestIndex = index;
                break;
            }
        }

        return latestIndex;
    }

    function getLeftToNextLevel(achievementCodes: number[]) {
        let currentUnlockedAchievement =
            achievementDataById.data[
                achievementNameAndCodeArrayPoängsamlaren[0]
            ];

        for (const achievementCode of achievementCodes) {
            if (!achievementDataById.data[achievementCode].unlocked) {
                currentUnlockedAchievement =
                    achievementDataById.data[achievementCode];
                break;
            }
        }

        return (
            currentUnlockedAchievement.progress.status.goal -
            currentUnlockedAchievement.progress.status.current
        );
    }

    return (
        <ContentStatus
            loaded={!achievementDataById.isLoading}
            error={achievementDataById.error}
        >
            {() => {
                const poängsamlaren3 =
                    achievementDataById.data[
                        achievementNameAndCode.POÄNGSAMLAREN_3
                    ];
                const stjärnsamlaren3 =
                    achievementDataById.data[
                        achievementNameAndCode.STJÄRNSAMLAREN_3
                    ];

                if (!poängsamlaren3 || !stjärnsamlaren3) {
                    return "Det gick tyvärr inte att hämta data för dina prestationer";
                }

                const leftToNextLevelPoängsamlaren = getLeftToNextLevel(
                    achievementNameAndCodeArrayPoängsamlaren,
                );

                const leftToNextLevelStjärnsamlaren = getLeftToNextLevel(
                    achievementNameAndCodeArrayStjärnsamlaren,
                );

                const currentLevelPoängsamlaren = currentIndexLevel(
                    achievementNameAndCodeArrayPoängsamlaren,
                );

                const currentLevelStjärnsamlaren = currentIndexLevel(
                    achievementNameAndCodeArrayStjärnsamlaren,
                );

                return (
                    <div className="unlockedAchievementsStart__wrapper">
                        <div
                            className={`unlockedAchievementsStart__seasonWrapper ${currentLevelPoängsamlaren === achievementNameAndCodeArrayPoängsamlaren.length ? "-completed" : ""}`}
                        >
                            <h2 className="unlockedAchievementsStart__heading">
                                Säsongspoängsamlaren
                            </h2>

                            <div className="unlockedAchievementsStart__body">
                                {currentLevelPoängsamlaren > 0
                                    ? "Du har låst upp nivå " +
                                      currentLevelPoängsamlaren
                                    : "Du har inte låst upp någon nivå ännu"}
                            </div>
                            <ul className="unlockedAchievementsStart__listCollector">
                                {achievementNameAndCodeArrayPoängsamlaren.map(
                                    (achievementCode, index) => {
                                        const currentPercentage =
                                            calculateProgress({
                                                index,
                                                currentAchievementGroup:
                                                    achievementNameAndCodeArrayPoängsamlaren,
                                            });

                                        const isCurrentUnlocked =
                                            achievementDataById.data[
                                                achievementCode
                                            ].unlocked;

                                        return (
                                            <Fragment key={achievementCode}>
                                                {getConnectedBar({
                                                    index,
                                                    currentAchievementGroup:
                                                        achievementNameAndCodeArrayPoängsamlaren,
                                                    isCurrentUnlocked:
                                                        isCurrentUnlocked,
                                                })}

                                                <li
                                                    className="unlockedAchievementsStart__listCollector-item"
                                                    key={achievementCode}
                                                >
                                                    <Gauge
                                                        trailColor={
                                                            isCurrentUnlocked
                                                                ? gaugeCompleteColorIndex[
                                                                      index
                                                                  ]
                                                                : undefined
                                                        }
                                                        pathColor={
                                                            isCurrentUnlocked
                                                                ? gaugeCompleteColorIndex[
                                                                      index
                                                                  ]
                                                                : undefined
                                                        }
                                                        valueEnd={
                                                            currentPercentage
                                                        }
                                                        valueStart={
                                                            isCurrentUnlocked
                                                                ? currentPercentage
                                                                : 0
                                                        }
                                                    >
                                                        <img
                                                            width="48"
                                                            src={
                                                                achievementDataById
                                                                    .data[
                                                                    achievementCode
                                                                ].unlocked
                                                                    ? achievementDataById
                                                                          .data[
                                                                          achievementCode
                                                                      ].info
                                                                          .image_unlocked
                                                                    : achievementDataById
                                                                          .data[
                                                                          achievementCode
                                                                      ].info
                                                                          .image_locked
                                                            }
                                                        />
                                                    </Gauge>
                                                    <div className="unlockedAchievementsStart__points">
                                                        {
                                                            achievementDataById
                                                                .data[
                                                                achievementCode
                                                            ].progress.status
                                                                .goal
                                                        }{" "}
                                                        poäng
                                                    </div>
                                                </li>
                                            </Fragment>
                                        );
                                    },
                                )}
                            </ul>
                            <div className="unlockedAchievementsStart__pointsLeft">
                                Du har {leftToNextLevelPoängsamlaren} poäng kvar
                                till nästa nivå
                            </div>
                        </div>

                        <div className="unlockedAchievementsStart__space" />

                        <div
                            className={`unlockedAchievementsStart__seasonWrapper ${currentLevelStjärnsamlaren === achievementNameAndCodeArrayPoängsamlaren.length ? "-completed" : ""}`}
                        >
                            <h2 className="unlockedAchievementsStart__heading">
                                Säsongsstjärnsamlaren
                            </h2>
                            <div className="unlockedAchievementsStart__body">
                                {currentLevelStjärnsamlaren > 0
                                    ? "Du har låst upp nivå " +
                                      currentLevelStjärnsamlaren
                                    : "Du har inte låst upp någon nivå ännu"}
                            </div>
                            <ul className="unlockedAchievementsStart__listCollector">
                                {achievementNameAndCodeArrayStjärnsamlaren.map(
                                    (achievementCode, index) => {
                                        const currentPercentage =
                                            calculateProgress({
                                                index,
                                                currentAchievementGroup:
                                                    achievementNameAndCodeArrayStjärnsamlaren,
                                            });

                                        const isCurrentUnlocked =
                                            achievementDataById.data[
                                                achievementCode
                                            ].unlocked;

                                        return (
                                            <Fragment key={achievementCode}>
                                                {getConnectedBar({
                                                    index,
                                                    currentAchievementGroup:
                                                        achievementNameAndCodeArrayStjärnsamlaren,
                                                    isCurrentUnlocked,
                                                })}

                                                <li key={achievementCode}>
                                                    <Gauge
                                                        trailColor={
                                                            isCurrentUnlocked
                                                                ? gaugeCompleteColorIndex[
                                                                      index
                                                                  ]
                                                                : undefined
                                                        }
                                                        pathColor={
                                                            isCurrentUnlocked
                                                                ? gaugeCompleteColorIndex[
                                                                      index
                                                                  ]
                                                                : undefined
                                                        }
                                                        valueEnd={
                                                            currentPercentage
                                                        }
                                                        valueStart={
                                                            isCurrentUnlocked
                                                                ? currentPercentage
                                                                : 0
                                                        }
                                                    >
                                                        <img
                                                            width="48"
                                                            src={
                                                                isCurrentUnlocked
                                                                    ? achievementDataById
                                                                          .data[
                                                                          achievementCode
                                                                      ].info
                                                                          .image_unlocked
                                                                    : achievementDataById
                                                                          .data[
                                                                          achievementCode
                                                                      ].info
                                                                          .image_locked
                                                            }
                                                        />
                                                    </Gauge>
                                                    <div className="unlockedAchievementsStart__points">
                                                        {
                                                            achievementDataById
                                                                .data[
                                                                achievementCode
                                                            ].progress.status
                                                                .goal
                                                        }{" "}
                                                        ST
                                                    </div>
                                                </li>
                                            </Fragment>
                                        );
                                    },
                                )}
                            </ul>

                            <div className="unlockedAchievementsStart__pointsLeft">
                                Du har {leftToNextLevelStjärnsamlaren} stjärnor
                                kvar till nästa nivå
                            </div>
                        </div>

                        <div className="unlockedAchievementsStart__space" />

                        <ul className="unlockedAchievementsStart__list">
                            {achievementNameAndCodeArray.map(
                                (achievementCode) => {
                                    const percentage =
                                        (achievementDataById.data[
                                            achievementCode
                                        ].progress.status.current /
                                            achievementDataById.data[
                                                achievementCode
                                            ].progress.status.goal) *
                                        100;
                                    return (
                                        <li
                                            key={achievementCode}
                                            className={`unlockedAchievementsStart__listItem ${
                                                achievementDataById.data[
                                                    achievementCode
                                                ].unlocked
                                                    ? "-unlocked"
                                                    : ""
                                            }`}
                                        >
                                            <Gauge
                                                pathColor={
                                                    achievementDataById.data[
                                                        achievementCode
                                                    ].unlocked
                                                        ? "#FEFDD9"
                                                        : undefined
                                                }
                                                valueEnd={percentage}
                                                valueStart={0}
                                            >
                                                <img
                                                    width="48"
                                                    src={
                                                        achievementDataById
                                                            .data[
                                                            achievementCode
                                                        ].unlocked
                                                            ? achievementDataById
                                                                  .data[
                                                                  achievementCode
                                                              ].info
                                                                  .image_unlocked
                                                            : achievementDataById
                                                                  .data[
                                                                  achievementCode
                                                              ].info
                                                                  .image_locked
                                                    }
                                                />
                                            </Gauge>

                                            <div className="unlockedAchievementsStart__progress">
                                                {
                                                    achievementDataById.data[
                                                        achievementCode
                                                    ].progress.status.current
                                                }{" "}
                                                av{" "}
                                                {
                                                    achievementDataById.data[
                                                        achievementCode
                                                    ].progress.status.goal
                                                }
                                            </div>

                                            <div className="unlockedAchievementsStart__title">
                                                {
                                                    achievementDataById.data[
                                                        achievementCode
                                                    ].info.title_locked
                                                }
                                            </div>
                                            <div className="unlockedAchievementsStart__description">
                                                {
                                                    achievementDataById.data[
                                                        achievementCode
                                                    ].info.description_unlocked
                                                }
                                            </div>
                                        </li>
                                    );
                                },
                            )}
                        </ul>
                    </div>
                );
            }}
        </ContentStatus>
    );
}
