import update from "immutability-helper";
import { CLEAR_EVENT, DND_INITIAL, DND_SET, DND_UNSET } from "../actions";

const initialState = {
    boxes: [],
    options: [],
    id: null,
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case CLEAR_EVENT:
            return initialState;
        case DND_INITIAL:
            return {
                ...state,
                boxes: action.data.boxes,
                options: action.data.options,
                id: action.id,
            };
        case DND_SET:
            if (state.boxes[action.index].droppedItem) {
                state = update(state, {
                    options: {
                        [state.boxes[action.index].droppedItem.index]: {
                            dropped: {
                                $set: false,
                            },
                        },
                    },
                });
            }

            return update(state, {
                boxes: {
                    [action.index]: {
                        droppedItem: {
                            $set: action.option,
                        },
                    },
                },
                options: {
                    [action.option.index]: {
                        dropped: {
                            $set: true,
                        },
                    },
                },
            });
        case DND_UNSET:
            return update(state, {
                boxes: {
                    [action.index]: {
                        droppedItem: {
                            $set: null,
                        },
                    },
                },
                options: {
                    [action.option.index]: {
                        dropped: {
                            $set: false,
                        },
                    },
                },
            });
        default:
            return state;
    }
}
