import {
    CLEAR_EVENT,
    COUNTDOWN_TIMER_START,
    COUNTDOWN_TIMER_TICK,
    COUNTDOWN_TIMER_CLEAR,
} from "../actions";

const initialState = {
    duration: -1,
    durationLeft: -1,
    elapsedPercent: null,
    now: -1,
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case CLEAR_EVENT:
            return initialState;
        case COUNTDOWN_TIMER_START:
            return {
                ...state,
                duration: action.duration,
                durationLeft: action.durationLeft,
                elapsedPercent: action.elapsedPercent,
                now: action.durationLeft,
            };
        case COUNTDOWN_TIMER_TICK:
            return {
                ...state,
                now: state.now - 1,
            };
        case COUNTDOWN_TIMER_CLEAR:
            return initialState;
        default:
            return state;
    }
}
