import Star from "./star";

export default function StarsRow({
    starLevel,
    width = 16,
    height = 16,
    emptyStarClass,
    inlineStyles,
}: {
    starLevel: number;
    width?: number;
    height?: number;
    emptyStarClass?: string;
    inlineStyles?: { wrapper: Record<string, string> };
}) {
    const renderStars = () => {
        const fullStar = (
            <Star width={width} height={height} modifier="-full-moon" />
        );
        const emptyStar = (
            <Star width={width} height={height} modifier={emptyStarClass} />
        );

        switch (starLevel) {
            case 3:
                return (
                    <span>
                        {fullStar} {fullStar} {fullStar}
                    </span>
                );
            case 2:
                return (
                    <span>
                        {fullStar} {fullStar} {emptyStar}
                    </span>
                );
            case 1:
                return (
                    <span>
                        {fullStar} {emptyStar} {emptyStar}
                    </span>
                );
            default:
                return (
                    <span>
                        {emptyStar} {emptyStar} {emptyStar}
                    </span>
                );
        }
    };

    return (
        <div
            style={{ ...(inlineStyles?.wrapper ?? {}) }}
            className="svt_star__wrapper"
        >
            {renderStars()}
        </div>
    );
}
