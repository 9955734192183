import ReactModal from "react-modal";
import { useSelector } from "react-redux";
import { createTeleporter } from "react-teleporter";
import duoCom from "@svt/duo-communication";
import { localStorageHelper } from "@svt/duo-shared-components";
import { useState } from "react";

import StartScreen from "./views/startScreen/startScreen";
import Paused from "./views/paused/paused";
import Syncing from "./views/syncing/syncing";
import ActiveElement from "./activeElement";
import HeaderGroup from "./headerGroup/headerGroup";
import CurrentEventSaver from "./currentEventSaver";
import Menu from "./headerGroup/menu/menu";
import CityEvents from "./cityEvents";
import EpisodeSummary from "./views/startScreen/history/episodeSummary";
import useUnlockedAchievement from "./useUnlockedAchievement";
import Onboarding, {
    onboardingKey,
} from "./views/startScreen/onboarding/onBoarding";

export const FooterTeleport = createTeleporter();
export const BackToStartTeleporter = createTeleporter();

ReactModal.setAppElement("#svt_app");

const App = () => {
    const seenOnboarding2024 = localStorageHelper.getState(onboardingKey);
    const [showOnboarding, setShowOnboarding] = useState(!seenOnboarding2024);
    useUnlockedAchievement();
    const appState = useSelector((state) => state.appState);

    if (import.meta.env.VITE_DEBUG === "true") {
        duoCom.log({
            message: appState.view,
            data: appState.data,
        });
    }

    let renderScreen;

    switch (appState.view) {
        case "paused": {
            renderScreen = <Paused />;
            break;
        }
        case "syncing": {
            renderScreen = <Syncing />;
            break;
        }
        case "playing": {
            renderScreen = (
                <>
                    <ActiveElement />
                    <CityEvents />
                </>
            );
            break;
        }
        /* episodeSummary from start view */
        case "episodeSummary": {
            renderScreen = <EpisodeSummary {...appState.data} />;
            break;
        }
        default: {
            renderScreen = (
                <StartScreen setShowOnboarding={setShowOnboarding} />
            );
        }
    }

    return (
        <>
            {showOnboarding ? (
                <Onboarding setShowOnboarding={setShowOnboarding} />
            ) : (
                <>
                    {appState.view !== "episodeSummary" && <Menu />}

                    <BackToStartTeleporter.Target />

                    <div id="svt_flex-container" className="svt_flex-container">
                        <HeaderGroup appState={appState} />

                        <div className="svt_flex-content js-selector-app-scrollview">
                            {renderScreen}
                        </div>

                        <div className="svt_footer-group">
                            <FooterTeleport.Target />
                        </div>

                        <CurrentEventSaver />
                    </div>
                </>
            )}
        </>
    );
};

export default App;
