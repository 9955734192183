import { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import ToplistWrapper from "../../shared/toplist/toplistwrapper";
import Waiting from "../waiting/waiting";
import {
    getCityIndex,
    getPreviousQuestion,
} from "../../../helpers/questionHelpers";
import StarsRow from "../../shared/starsRow";
import City from "../questionAnswers/questionAnswersCity";
import MultiChoice from "../questionAnswers/questionAnswersMultiChoice";
import TheList from "../questionAnswers/questionAnswersTheList";
import Dnd from "../questionAnswers/questionAnswersDnd";
import ClosestWinsResult from "../closestWins/closestWinsResult";
import StatisticsCityAnswers from "./statisticsCityAnswers/statisticsCityAnswers";
import { QuestionPropsType } from "../../../redux/reducers/questions";
import { AllCustomFieldsType } from "../../../types";
import ExplainStars from "./explainStars";
import HighScoreSender from "../../shared/highScoreSender";
import QuestionAnswerPointsUnlockedAchievements from "./questionAnswerPointsUnlockedAchievements";
import { useUnlockAchievement } from "../../useUnlockAchievement";

export default function QuestionAnswerPoints({
    element,
}: {
    element: { isLastQuestion: boolean };
}) {
    const { unlockQuestionAnswerPoints } = useUnlockAchievement();
    const dispatch = useDispatch();
    const questionState = useSelector(
        (state: { questions: Record<string, QuestionPropsType> }) =>
            state.questions,
    );
    const currentQuestionRef = useRef<null | {
        id: string;
        type: string;
        innerType: string | null;
        customFields: AllCustomFieldsType;
    }>(null);
    const previousQuestion = getPreviousQuestion();

    if (previousQuestion) {
        const innerType =
            previousQuestion.getContentType() === "multi_choice"
                ? previousQuestion.getCustomFields().multi_choice_type
                : null;

        currentQuestionRef.current = {
            id: previousQuestion.getId(),
            type: previousQuestion.getContentType(),
            innerType: innerType,
            customFields: previousQuestion.getCustomFields(),
        };
    }

    if (!currentQuestionRef.current) {
        return <Waiting />;
    }

    const currentQuestionState = questionState[currentQuestionRef.current.id];

    if (!currentQuestionState) {
        return <Waiting />;
    }

    unlockQuestionAnswerPoints();

    let Component;
    switch (currentQuestionRef.current.type) {
        case "city":
            Component = City;
            break;
        case "multi_choice":
            Component = MultiChoice;
            break;
        case "the_list":
            Component = TheList;
            break;
        case "dnd":
            Component = Dnd;
            break;
        case "closest_wins":
            Component = ClosestWinsResult;
            break;
        default:
            Component = null;
    }

    return (
        <>
            {element.isLastQuestion && <HighScoreSender />}
            <div className="questionAnswerPoints">
                <div className="questionAnswerPoints__points-wrapper">
                    <h1
                        className={`questionAnswerPoints__points ${
                            currentQuestionState.points === 0
                                ? "-no-points"
                                : "-points"
                        }`}
                    >
                        {currentQuestionState.points} poäng
                        <StarsRow
                            starLevel={currentQuestionState.starLevel}
                            emptyStarClass="-empty-star-class"
                            height={20}
                            width={20}
                            inlineStyles={{
                                wrapper: {
                                    display: "inline-block",
                                    marginLeft: "16px",
                                },
                            }}
                        />
                    </h1>
                </div>

                <ExplainStars />

                {currentQuestionState.answer ? (
                    //@ts-expect-error - FIX type
                    <Component
                        question={currentQuestionState}
                        dispatch={dispatch}
                        type={currentQuestionRef.current.innerType}
                        customFields={currentQuestionRef.current.customFields}
                    />
                ) : (
                    <div className="questionAnswerPoints__no-answer">
                        Du hann tyvärr inte svara.
                    </div>
                )}

                <QuestionAnswerPointsUnlockedAchievements />

                {previousQuestion?.getContentType() === "city" &&
                    getCityIndex() === 1 && (
                        <StatisticsCityAnswers
                            userPoints={currentQuestionState.points}
                            hasAnswered={currentQuestionState.hasAnswered}
                        />
                    )}

                <ToplistWrapper listName="Poängställning vänner" />
            </div>
        </>
    );
}
