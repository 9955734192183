import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    ChartOptions,
} from "chart.js";
import { Line } from "react-chartjs-2";
import ChartAnnotation from "chartjs-plugin-annotation";
import LViS from "@monterosa/lvis-api";

import { ResultsType } from "../../../../types";
import questionTypes from "../../../../helpers/questionTypes";
import { getCityElements } from "../../../../helpers/findStatisticsElement";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    ChartAnnotation,
);

export default function LineChart({
    allPlayerPoints,
    userPoints,
}: {
    allPlayerPoints: ResultsType;
    userPoints: number;
}) {
    function createGradient() {
        const ctx = document.createElement("canvas").getContext("2d");
        const gradient = ctx?.createLinearGradient(0, 0, 0, 200);
        gradient?.addColorStop(0, "rgb(152, 206, 253)");
        gradient?.addColorStop(1, "rgb(247, 207, 252)");
        return gradient;
    }

    function getMaxPoints() {
        const nrOfCityElements = getCityElements().length;

        const nrOfQuestionElements = LViS.getEvent()
            .getHistory()
            .filter((el) => questionTypes[el.getContentType()]).length;
        const nrOfQuestionElementsWithoutCity =
            nrOfQuestionElements - nrOfCityElements;

        return nrOfCityElements * 10 + nrOfQuestionElementsWithoutCity * 3;
    }

    function getResults() {
        const maxPoints = getMaxPoints();
        const resultvotes = allPlayerPoints.map((result) => result.votes);
        return resultvotes.slice(0, maxPoints + 1);
    }

    const options: ChartOptions<"line"> = {
        plugins: {
            annotation: {
                annotations: {
                    line1: {
                        type: "line",
                        xMin: userPoints,
                        xMax: userPoints,
                        borderWidth: 4,
                        borderColor: "#EA5D3F",
                    },
                },
            },
        },
        responsive: true,
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    autoSkip: true,
                    maxRotation: 0,
                    minRotation: 0,
                    color: "#ffffff",
                    font: {
                        family: "publik",
                        size: 13,
                        weight: 700,
                    },
                    callback: (_: number | string, index: number) => {
                        return index % 10 === 0 ? index : "";
                    },
                },
            },
            y: {
                display: false,
            },
        },
    };

    const data = {
        labels: getResults(),
        datasets: [
            {
                data: getResults(),
                fill: true,
                backgroundColor: createGradient(),
                pointRadius: 0,
                tension: 0.4,
            },
        ],
    };

    return (
        <Line
            className="hideToMatchImageSnapshot"
            options={options}
            data={data}
        />
    );
}
