import { useEffect, useState } from "react";
import DuoCom from "@svt/duo-communication";
import LoaderImg from "@svt/duo-shared-assets/assets/icons/loaderNoPadding.svg?react";

import { getAchievementsFromEpisode } from "../../../useUnlockAchievement";
import { Achievement } from "../../episodeEnd/sharedTypes";

export default function UserPlayedAchievements({
    episodeNumber,
}: {
    episodeNumber: string;
}) {
    const [achievementDataById, setAchievementDataById] = useState<{
        isLoading: boolean;
        data: { [key: string]: Achievement } | Record<string, never>;
        error: boolean;
    }>({
        isLoading: true,
        data: {},
        error: false,
    });

    useEffect(() => {
        async function getAchievementMapAsync() {
            const achievementMap: { [key: string]: Achievement } =
                await DuoCom.Amigo.getAchievementMap({
                    app: `duo/pa-sparet`,
                    query: { includeProgressStatus: "true" },
                });

            setAchievementDataById({
                isLoading: false,
                data: achievementMap,
                error: false,
            });
        }

        getAchievementMapAsync();
    }, []);

    if (achievementDataById.isLoading) {
        return <LoaderImg style={{ marginTop: "12px", height: "28px" }} />;
    }

    const achievementsFromEpisode = getAchievementsFromEpisode(episodeNumber);

    const achievementsToDisplay: { [key: string]: Achievement } = Object.keys(
        achievementsFromEpisode,
    ).reduce(
        (achievementsByGroup: { [key: string]: Achievement }, groupName) => {
            achievementsByGroup[groupName] =
                achievementDataById.data[
                    achievementsFromEpisode[groupName][0].code
                ];
            achievementsFromEpisode[groupName].forEach((achievement) => {
                if (achievementDataById.data[achievement.code].unlocked) {
                    achievementsByGroup[groupName] =
                        achievementDataById.data[achievement.code];
                }
            });
            return achievementsByGroup;
        },
        {},
    );

    return (
        <>
            <ul className="episodeList__list">
                <li className="episodeList__listItem">
                    {[
                        achievementsToDisplay["Poängsamlaren"],
                        achievementsToDisplay["Stjärnsamlaren"],
                        achievementsToDisplay["Burbräckaren"],
                        achievementsToDisplay["Globetrottern"],
                        achievementsToDisplay["Kartläsaren"],
                        achievementsToDisplay["Vänbräckaren"],
                    ].map((achievement) => {
                        return (
                            <>
                                {achievement.unlocked ? (
                                    <img
                                        width="28"
                                        src={achievement.info.image_unlocked}
                                        alt={achievement.info.title_unlocked}
                                    />
                                ) : (
                                    <img
                                        width="28"
                                        src={achievement.info.image_locked}
                                        alt={achievement.info.title_locked}
                                    />
                                )}
                            </>
                        );
                    })}
                </li>
            </ul>
        </>
    );
}
