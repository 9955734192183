import React, { useState } from "react";

import { AccountContext, analytics } from "@svt/duo-shared-components";

const onSeen = (PassedComponent) => {
    const NewComponent = (props) => {
        const [stateOnSeen, setStateOnSeen] = useState({});
        const accountContext = React.useContext(AccountContext);

        function onSeenComponent(isVisible, stateName, statisticsName, view) {
            if (isVisible === true) {
                setStateOnSeen((currentState) => ({
                    ...currentState,
                    [stateName]: true,
                }));

                const loggedInStatus = accountContext.userData.isLoggedIn
                    ? "inloggad"
                    : "utloggad";

                analytics.trackCustomEvent(view, "visible", {
                    statisticsName: statisticsName,
                    loggedInStatus: loggedInStatus,
                });
            }
        }

        return (
            <PassedComponent
                {...props}
                onSeenComponent={(
                    isVisible,
                    stateName,
                    statisticsName,
                    view,
                ) => {
                    onSeenComponent(isVisible, stateName, statisticsName, view);
                }}
                stateOnSeen={stateOnSeen}
                setStateOnSeen={setStateOnSeen}
                accountContext={accountContext}
            />
        );
    };

    return NewComponent;
};

export default onSeen;
