import update from "immutability-helper";

import { CLEAR_EVENT, ADD_UNLOCKED_ACHIEVEMENT } from "../actions";

export type AchievementType = {
    code: number;
    title: string;
    image: string;
    description: string;
};

const initialState: { [key: number]: AchievementType } = {};

export default function reducer(
    state = initialState,
    action: {
        type: string;
        payload: AchievementType;
    },
) {
    switch (action.type) {
        case CLEAR_EVENT:
            return initialState;
        case ADD_UNLOCKED_ACHIEVEMENT:
            if (state[action.payload.code]) {
                return state;
            }

            return update(state, {
                [action.payload.code]: {
                    $set: action.payload,
                },
            });

        default:
            return state;
    }
}
