import React, { useRef } from "react";
import PropTypes from "prop-types";

import ClosestWinsMapShared from "./closestWinsMapShared";

function ClosestWinsWaitingShared(props) {
    const cmsExport = useRef(JSON.parse(props.question.cmsExport));

    return (
        <div className="closest-wins-waiting-wrapper">
            <h1 className="closest-wins-waiting-header">
                Väntar på svar från Fredrik...
            </h1>

            <div className="closest-wins-waiting-map-wrapper">
                <ClosestWinsMapShared
                    version="waiting"
                    center={props.questionState.answer}
                    zoomToExtent={false}
                    extent={cmsExport.current.mapExtent}
                    userAnswer={props.questionState.answer}
                />
            </div>
        </div>
    );
}

ClosestWinsWaitingShared.propTypes = {
    question: PropTypes.object.isRequired,
    questionState: PropTypes.object.isRequired,
};

export default ClosestWinsWaitingShared;
