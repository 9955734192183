import { useContext } from "react";
import { AccountContext } from "@svt/duo-shared-components";
import { UserType } from "@svt/duo-communication/dist/api/highscore-types";

import Row from "./row";
import {
    AccountContextType,
    LoggedInAmigoType,
    ToplistRowType,
    ToplistUserRowType,
} from "../../../types";
import TopListSharedOutside from "../../views/startScreen/history/topListSharedOutside";

const ToppListShared = ({
    topList,
    modifyPoints,
    user,
}: {
    topList: ToplistRowType[] | UserType[];
    modifyPoints?: boolean;
    user?: ToplistUserRowType;
}) => {
    const accountContext: AccountContextType = useContext(AccountContext);
    if (!topList || topList.length === 0) {
        return <p className="history-toplist__empty">Här var det tomt!</p>;
    }

    const outsideHighScoreList = user && user.rank > 10;
    const outsideHighscoreUserRow = {
        ...user,
        ...accountContext.userData.amigo,
    } as ToplistUserRowType & LoggedInAmigoType;

    let key = 0;

    return (
        <ol className="history-toplist__ordered-list">
            {topList.map((user, userIndex) => {
                key = userIndex;
                return (
                    <Row
                        key={userIndex}
                        modifyPoints={modifyPoints}
                        rank={userIndex + 1}
                        //@ts-expect-error - FIX type
                        user={user}
                    />
                );
            })}
            {outsideHighScoreList && (
                <TopListSharedOutside
                    key={key + 1}
                    user={outsideHighscoreUserRow}
                />
            )}
        </ol>
    );
};

export default ToppListShared;
