import React from "react";
import { DragSource } from "react-dnd";
import PropTypes from "prop-types";

const optionSource = {
    canDrag(props) {
        return !props.isDropped;
    },
    beginDrag(props) {
        return {
            index: props.index,
            label: props.label,
        };
    },
};

class DndOption extends React.Component {
    render() {
        const { label, isDropped, isDragging, connectDragSource } = this.props;

        let modifier = "";

        if (isDragging) {
            modifier = "-is-dragging";
        } else if (isDropped) {
            modifier = "-is-dropped";
        } else {
            modifier = "-can-drag";
        }

        return connectDragSource(
            <li className={"svt_dnd__options-list-item " + modifier}>
                {label}
            </li>,
        );
    }
}

DndOption.propTypes = {
    connectDragSource: PropTypes.func.isRequired,
    isDragging: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    isDropped: PropTypes.bool.isRequired,
};

export default DragSource(
    (props) => props.type,
    optionSource,
    (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
    }),
)(DndOption);
