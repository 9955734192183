import {
    CountyType,
    FriendHighscoreType,
    MunicipalityType,
    UserRanking,
    UserType,
} from "@svt/duo-communication/dist/api/highscore-types";

import useGeography from "./useGeography";
import Episode from "../../../shared/toplist/episode";
import { TeamsType } from "../../../../types";
import TopListEpisodeFriends from "./topListEpisodeFriends";

const TopListEpisode = ({
    episodeHighscore,
    episodeNumber,
}: {
    episodeHighscore: {
        friends: FriendHighscoreType;
        toplist: UserType[];
        user: UserRanking;
        municipality: MunicipalityType;
        county: CountyType;
        teams: TeamsType[];
    };
    episodeNumber: string;
}) => {
    const geography = useGeography({
        county: episodeHighscore.county,
        municipality: episodeHighscore.municipality,
        view: "historik/avsnitt/slutvy",
    });

    return (
        <>
            <Episode
                episodeNumber={episodeNumber}
                geography={geography}
                list={episodeHighscore.toplist}
                user={episodeHighscore.user}
            />
            <TopListEpisodeFriends episodeNumber={episodeNumber} />
        </>
    );
};

export default TopListEpisode;
