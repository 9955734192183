import React, { useContext, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
} from "@reach/disclosure";
import ArrowIcon from "@svt/duo-shared-assets/assets/icons/arrow.svg?react";
import { ToplistContext } from "@svt/duo-shared-components";

import ClosestWinsMapShared from "./shared/closestWinsMapShared";
import diffInKm from "./helpers/diffInKm";
import thousandSeparator from "./helpers/thousandSeparator";
import { getQuestionIndex } from "../../../helpers/questionHelpers";

function ClosestWinsResult(props) {
    const toplistContext = useContext(ToplistContext);

    function getFriendsAnswers() {
        const questionIndex = getQuestionIndex();
        const friendsAnswers = [];
        Object.keys(toplistContext.points).forEach((unoId) => {
            if (
                toplistContext.points[unoId][questionIndex] &&
                toplistContext.points[unoId][questionIndex].answer
            ) {
                friendsAnswers.push({
                    unoId: unoId,
                    answer: toplistContext.points[unoId][questionIndex].answer,
                });
            }
        });

        return friendsAnswers;
    }

    const friendsAnswersRef = useRef(getFriendsAnswers());

    const [pointsInfoIsOpenState, setPointsInfoIsOpenState] = useState(false);
    const cmsExport = useRef(JSON.parse(props.customFields.cmsExport));

    if (!props.question.hasAnswered) {
        return null;
    }

    const diffFromCorrectKm = diffInKm(
        cmsExport.current.questionDestination,
        props.question.answer,
    );

    function togglePointsInfoIsOpen() {
        setPointsInfoIsOpenState(!pointsInfoIsOpenState);
    }

    return (
        <>
            <h2 className="closest-wins-result-header">
                Rätt svar var {props.customFields.destinationName}
            </h2>

            <p className="closest-wins-result-paragraph">
                {props.question.points} poäng: Du var{" "}
                {thousandSeparator(diffFromCorrectKm)} km ifrån
            </p>

            <Disclosure
                open={pointsInfoIsOpenState}
                onChange={() => {
                    togglePointsInfoIsOpen();
                }}
            >
                <div className="closest-wins-result-toggle-button-wrapper">
                    <DisclosureButton className="closest-wins-result-toggle-button">
                        Zonförklaring
                        <ArrowIcon
                            className={
                                pointsInfoIsOpenState
                                    ? "closest-wins-result-toggle-arrow-up"
                                    : "closest-wins-result-toggle-arrow-down"
                            }
                        />
                    </DisclosureButton>
                </div>

                <DisclosurePanel>
                    <p className="closest-wins-result-info-paragraph">
                        Väldigt nära: 3 poäng
                        <br />
                        Ganska nära: 2 poäng
                        <br />
                        Inte så nära: 1 poäng
                        <br />
                        Långt ifrån: 0 poäng
                    </p>
                </DisclosurePanel>
            </Disclosure>

            <div className="closest-wins-result-map-wrapper">
                <ClosestWinsMapShared
                    version="result"
                    center={[]}
                    zoomToExtent={false}
                    extent={cmsExport.current.mapExtent}
                    userAnswer={props.question.answer}
                    destination={cmsExport.current.questionDestination}
                    pointsLimits={[
                        props.customFields.threePointsLimit,
                        props.customFields.twoPointsLimit,
                        props.customFields.onePointLimit,
                    ]}
                    friendsAnswers={friendsAnswersRef.current}
                />
            </div>
        </>
    );
}

ClosestWinsResult.propTypes = {
    customFields: PropTypes.object.isRequired,
    question: PropTypes.object.isRequired,
};

export default ClosestWinsResult;
