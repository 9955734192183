import {
    CLEAR_EVENT,
    CITY_SET,
    CITY_CLEAR,
    CITY_MOVE,
    CITY_INITIAL,
} from "../actions";
import citiesJson from "../../cities/cities.json";
import diacritics from "diacritics";

const initialState = {
    searchText: null,
    result: [],
    selectedIndex: 0,
    id: null,
};

function beginsWith(needle, haystack) {
    return haystack.substr(0, needle.length) === needle;
}

function searchCities(searchText) {
    let foundCities = [];

    if (searchText.length > 0) {
        searchText = diacritics.remove(searchText.toLowerCase().trim());
        let firstLetter = searchText.charAt(0);
        let foundNumber = 0;

        if (citiesJson[firstLetter]) {
            for (
                let i = 0;
                i < citiesJson[firstLetter].length && foundNumber < 10;
                i++
            ) {
                if (
                    beginsWith(
                        searchText,
                        citiesJson[firstLetter][i].searchText,
                    )
                ) {
                    foundCities.push(citiesJson[firstLetter][i]);
                    foundNumber++;
                }
            }
        }
    }

    return { searchText, foundCities };
}

let searchData;

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case CLEAR_EVENT:
            return initialState;
        case CITY_INITIAL:
            return {
                ...state,
                searchText: null,
                result: [],
                selectedIndex: 0,
                id: action.id,
            };
        case CITY_SET:
            searchData = searchCities(action.data);

            return {
                ...state,
                searchText: searchData.searchText,
                result: searchData.foundCities,
                selectedIndex: 0,
            };
        case CITY_MOVE:
            return {
                ...state,
                selectedIndex: action.data,
            };
        case CITY_CLEAR:
            return {
                ...state,
                searchText: null,
                result: [],
                selectedIndex: 0,
            };
        default:
            return state;
    }
}
