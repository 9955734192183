import {
    CLEAR_EVENT,
    ACTIVE_ELEMENT_SET,
    ACTIVE_ELEMENT_CLEAR,
} from "../actions";

const initialState = null;

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case CLEAR_EVENT:
            return initialState;
        case ACTIVE_ELEMENT_SET:
            return action.data;
        case ACTIVE_ELEMENT_CLEAR:
            return initialState;
        default:
            return state;
    }
}
