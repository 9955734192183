import type React from "react";
import { axeConf } from "@svt/duo-shared-components";

const reportAccessibility = async (App: typeof React): Promise<void> => {
    if (import.meta.env.DEV) {
        const axe = await import("@axe-core/react");
        const ReactDOM = await import("react-dom");

        axe.default(App, ReactDOM, 1000, axeConf);
    }
};

export default reportAccessibility;
