import Header from "./header";
import CountdownHandler from "./countdownHandler";
import { appStateObjectType } from "../../helpers/propTypes";
import { useSelector } from "react-redux";

export default function HeaderGroup({ appState }) {
    const showScore = useSelector((state) => state.showScore);
    return (
        <div className="svt_header-group">
            <Header appState={appState} />
            {showScore && <CountdownHandler />}
        </div>
    );
}
HeaderGroup.propTypes = {
    appState: appStateObjectType,
};
