import PropTypes from "prop-types";
import ReactModal from "react-modal";
import duoCom from "@svt/duo-communication";
import { analytics } from "@svt/duo-shared-components";

import CloseIcon from "../../../../assets/icons/close.svg?react";

const PlayModal = ({ hideModalPlay, episodeNumber }) => {
    function _startGame() {
        if (duoCom.supports("audiosyncStart")) {
            duoCom.audiosyncStart();
            analytics.trackClickEvent("history:audiosync:start");
        }
    }

    return (
        <ReactModal
            isOpen
            className="modal__position"
            overlayClassName="modal__overlay"
        >
            <button
                className="modal__button-close"
                type="button"
                onClick={hideModalPlay}
            >
                <CloseIcon />
            </button>
            <h2 className="modal__header">Redo för avgång?</h2>
            <p>{`Sätt på avsnitt ${episodeNumber} innan du trycker "Starta spelet".`}</p>
            <button
                className="modal__button-play"
                type="button"
                onClick={() => {
                    _startGame();
                }}
                aria-label="stäng"
            >
                Starta spelet
            </button>
        </ReactModal>
    );
};

PlayModal.propTypes = {
    hideModalPlay: PropTypes.func.isRequired,
    episodeNumber: PropTypes.number.isRequired,
};

export default PlayModal;
