import update from "immutability-helper";
import {
    CLEAR_EVENT,
    MULTI_CHOICE_INITIAL,
    MULTI_CHOICE_TOGGLE_OPTION,
} from "../actions";

const initialState = {
    id: null,
    questions: {},
    isInitialized: false,
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case CLEAR_EVENT:
            return initialState;
        case MULTI_CHOICE_INITIAL:
            return {
                ...state,
                id: action.id,
                questions: action.data,
                isInitialized: true,
            };
        case MULTI_CHOICE_TOGGLE_OPTION:
            return update(state, {
                questions: {
                    [action.questionIndex]: {
                        options: {
                            [action.optionIndex]: {
                                isSelected: {
                                    $set: !state.questions[action.questionIndex]
                                        .options[action.optionIndex].isSelected,
                                },
                            },
                        },
                        amountSelected: {
                            $set:
                                state.questions[action.questionIndex]
                                    .amountSelected + action.direction,
                        },
                    },
                },
            });
        default:
            return state;
    }
}
