import { useState } from "react";
import PropTypes from "prop-types";

const Category = ({ categories }) => {
    const [stateCategory, setStateCategory] = useState(0);

    const setState = (key) => {
        setStateCategory(key);
    };

    const shouldShowWrapper =
        categories.length > 1 || categories[stateCategory].header;

    return (
        <>
            {shouldShowWrapper && (
                <div className="history-top-list-category__wrapper">
                    {categories.length > 1 && (
                        <div className="history-top-list-category__button-wrapper">
                            <div className="history-top-list-category__button-background">
                                {categories.map((category, index) => {
                                    return (
                                        <button
                                            key={index}
                                            type="button"
                                            disabled={index === stateCategory}
                                            className="history-top-list-category__button"
                                            onClick={() => {
                                                setState(index);
                                            }}
                                        >
                                            <span className="history-top-list-category__button-text">
                                                {category.name}
                                            </span>
                                        </button>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                    {categories[stateCategory].header && (
                        <p className="history-top-list-category__header">
                            {categories[stateCategory].header}
                        </p>
                    )}
                </div>
            )}
            {categories[stateCategory].toplist}
        </>
    );
};

Category.propTypes = {
    categories: PropTypes.arrayOf(
        PropTypes.shape({
            header: PropTypes.node,
            name: PropTypes.string.isRequired,
            toplist: PropTypes.node.isRequired,
        }),
    ),
};

export default Category;
