import React from "react";
import gsap from "gsap";

export default class WaitingAnim extends React.Component {
    constructor() {
        super();

        this.telepolesRef = React.createRef();
        this.trainRef = React.createRef();
        this.moonRef = React.createRef();
    }

    componentDidMount() {
        const tlTelepoles = gsap.timeline({ repeat: -1 });
        const tlTrain = gsap.timeline({ repeat: -1 });
        const tlMoon = gsap.timeline({ repeat: -1 });

        tlMoon.fromTo(
            this.moonRef.current,
            600,
            {
                x: 0,
            },
            {
                x: -230,
                ease: "none",
            },
        );
        // .fromTo(this.moonRef.current, (6 / 2),
        //     {
        //         x: 110
        //     },
        //     {
        //         x: 0,
        //         ease: Power0.easeNone
        //     }
        // );

        tlTrain
            .fromTo(
                this.trainRef.current,
                1,
                {
                    y: 0,
                },
                {
                    y: 3,
                    ease: "none",
                },
            )
            .to(this.trainRef.current, 1, {
                y: 0,
                ease: "none",
            });

        tlTelepoles.fromTo(
            this.telepolesRef.current,
            2,
            {
                x: 0,
            },
            {
                x: -344,
                ease: "none",
            },
        );
    }

    shouldComponentUpdate() {
        return false;
    }

    render() {
        return (
            <svg
                className="svt_waiting__image"
                width="180"
                height="180"
                viewBox="0 0 332.402 332.402"
            >
                <defs>
                    <clipPath id="svt_waiting__image-mask">
                        <circle cx="166.201" cy="166.201" r="166.201" />
                    </clipPath>
                </defs>
                <g clipPath="url(#svt_waiting__image-mask)">
                    <g className="outline">
                        <circle
                            fill="#45346B"
                            cx="166.201"
                            cy="166.201"
                            r="166.201"
                        />
                    </g>
                    <g className="background">
                        <circle
                            fill="#22123F"
                            cx="166.201"
                            cy="166.201"
                            r="160.938"
                        />
                    </g>
                    <path
                        ref={this.moonRef}
                        className="moon"
                        fill="#45346B"
                        d="M233.711,84.28c-17.052,0-30.875-13.823-30.875-30.875c0-7.29,2.532-13.985,6.757-19.266 c-14.121,2.839-24.757,15.309-24.757,30.266c0,17.052,13.823,30.875,30.875,30.875c9.762,0,18.46-4.535,24.118-11.609 C237.851,84.069,235.806,84.28,233.711,84.28z"
                    />
                    <g ref={this.telepolesRef} className="telepoles">
                        <polygon
                            fill="#45346B"
                            points="635.931,111.972 605.077,111.972 605.077,106.041 608.077,106.041 608.077,108.972 632.931,108.972 632.931,106.041 635.931,106.041"
                        />
                        <polygon
                            fill="#45346B"
                            points="635.931,127.165 605.077,127.165 605.077,121.234 608.077,121.234 608.077,124.165 632.931,124.165 632.931,121.234 635.931,121.234"
                        />
                        <rect
                            x="619.004"
                            y="110.472"
                            fill="#45346B"
                            width="3"
                            height="107.401"
                        />
                        <polygon
                            fill="#45346B"
                            points="627.28,184.558 620.504,184.558 620.504,181.558 624.28,181.558 624.28,174.008 620.504,174.008 620.504,171.008 627.28,171.008"
                        />
                        <polygon
                            fill="#45346B"
                            points="464.172,111.972 433.32,111.972 433.32,106.041 436.32,106.041 436.32,108.972 461.172,108.972 461.172,106.041 464.172,106.041"
                        />
                        <polygon
                            fill="#45346B"
                            points="464.172,127.165 433.32,127.165 433.32,121.234 436.32,121.234 436.32,124.165 461.172,124.165 461.172,121.234 464.172,121.234"
                        />
                        <rect
                            x="447.246"
                            y="95.28"
                            fill="#45346B"
                            width="3"
                            height="122.594"
                        />
                        <polygon
                            fill="#45346B"
                            points="455.521,184.558 448.746,184.558 448.746,181.558 452.521,181.558 452.521,174.008 448.746,174.008 448.746,171.008 455.521,171.008"
                        />
                        <path
                            fill="#45346B"
                            d="M536.525,119.835c-44.714,0-73.712-8.796-74-8.885l0.295-0.955c0.286,0.088,29.154,8.84,73.705,8.84 c44.532,0,69.637-8.745,69.885-8.833l0.336,0.942C606.495,111.032,581.248,119.835,536.525,119.835z"
                        />
                        <path
                            fill="#45346B"
                            d="M536.525,135.027c-44.714,0-73.712-8.796-74-8.885l0.295-0.955c0.286,0.088,29.154,8.84,73.705,8.84 c44.532,0,69.637-8.745,69.885-8.833l0.336,0.942C606.495,126.225,581.248,135.027,536.525,135.027z"
                        />
                        <path
                            fill="#45346B"
                            d="M708.283,119.835c-44.713,0-73.711-8.796-73.999-8.885l0.295-0.955c0.286,0.088,29.154,8.84,73.704,8.84 c44.533,0,69.638-8.745,69.886-8.833l0.336,0.942C778.254,111.032,753.007,119.835,708.283,119.835z"
                        />
                        <path
                            fill="#45346B"
                            d="M708.283,135.027c-44.713,0-73.711-8.796-73.999-8.885l0.295-0.955c0.286,0.088,29.154,8.84,73.704,8.84 c44.533,0,69.638-8.745,69.886-8.833l0.336,0.942C778.254,126.225,753.007,135.027,708.283,135.027z"
                        />
                        <polygon
                            fill="#45346B"
                            points="292.414,111.972 261.561,111.972 261.561,106.041 264.561,106.041 264.561,108.972 289.414,108.972 289.414,106.041 292.414,106.041"
                        />
                        <polygon
                            fill="#45346B"
                            points="292.414,127.165 261.561,127.165 261.561,121.234 264.561,121.234 264.561,124.165 289.414,124.165 289.414,121.234 292.414,121.234"
                        />
                        <rect
                            x="275.488"
                            y="110.472"
                            fill="#45346B"
                            width="3"
                            height="107.401"
                        />
                        <polygon
                            fill="#45346B"
                            points="283.763,184.558 276.988,184.558 276.988,181.558 280.763,181.558 280.763,174.008 276.988,174.008 276.988,171.008 283.763,171.008"
                        />
                        <polygon
                            fill="#45346B"
                            points="120.656,111.972 89.803,111.972 89.803,106.041 92.803,106.041 92.803,108.972 117.656,108.972 117.656,106.041 120.656,106.041"
                        />
                        <polygon
                            fill="#45346B"
                            points="120.656,127.165 89.803,127.165 89.803,121.234 92.803,121.234 92.803,124.165 117.656,124.165 117.656,121.234 120.656,121.234"
                        />
                        <rect
                            x="103.729"
                            y="95.28"
                            fill="#45346B"
                            width="3"
                            height="122.594"
                        />
                        <polygon
                            fill="#45346B"
                            points="112.004,184.558 105.229,184.558 105.229,181.558 109.004,181.558 109.004,174.008 105.229,174.008 105.229,171.008 112.004,171.008"
                        />
                        <path
                            fill="#45346B"
                            d="M193.008,119.835c-44.713,0-73.712-8.796-74-8.885l0.295-0.955c0.287,0.088,29.155,8.84,73.705,8.84 c44.533,0,69.637-8.745,69.886-8.833l0.335,0.942C262.979,111.032,237.732,119.835,193.008,119.835z"
                        />
                        <path
                            fill="#45346B"
                            d="M193.008,135.027c-44.713,0-73.712-8.796-74-8.885l0.295-0.955c0.287,0.088,29.155,8.84,73.705,8.84 c44.533,0,69.637-8.745,69.886-8.833l0.335,0.942C262.979,126.225,237.732,135.027,193.008,135.027z"
                        />
                        <path
                            fill="#45346B"
                            d="M23.041,119.835c-44.713,0-73.712-8.796-74-8.885l0.295-0.955c0.287,0.088,29.155,8.84,73.705,8.84 c44.533,0,69.637-8.745,69.886-8.833l0.335,0.942C93.013,111.032,67.765,119.835,23.041,119.835z"
                        />
                        <path
                            fill="#45346B"
                            d="M23.041,135.027c-44.713,0-73.712-8.796-74-8.885l0.295-0.955c0.287,0.088,29.155,8.84,73.705,8.84 c44.533,0,69.637-8.745,69.886-8.833l0.335,0.942C93.013,126.225,67.765,135.027,23.041,135.027z"
                        />
                        <path
                            fill="#45346B"
                            d="M364.766,119.835c-44.712,0-73.712-8.796-74-8.885l0.295-0.955c0.287,0.088,29.155,8.84,73.705,8.84 c44.533,0,69.638-8.745,69.886-8.833l0.336,0.942C434.738,111.032,409.491,119.835,364.766,119.835z"
                        />
                        <path
                            fill="#45346B"
                            d="M364.766,135.027c-44.712,0-73.712-8.796-74-8.885l0.295-0.955c0.287,0.088,29.155,8.84,73.705,8.84 c44.533,0,69.638-8.745,69.886-8.833l0.336,0.942C434.738,126.225,409.491,135.027,364.766,135.027z"
                        />
                    </g>
                    <g ref={this.trainRef} className="train">
                        <path
                            fill="#45346B"
                            d="M-14.799,215.02h261.511c5.286,0,9.875-3.643,11.073-8.791l0.274-1.179 c0.907-3.895-0.455-7.97-3.522-10.536l-27.485-21.361c-3.01-2.519-6.81-3.899-10.735-3.899H-14.799 M49.421,189.026 c0,4.677-3.827,8.504-8.504,8.504H16c-4.677,0-8.504-3.827-8.504-8.504v-1.135c0-4.677,3.827-8.504,8.504-8.504h24.918 c4.677,0,8.504,3.827,8.504,8.504V189.026z M100.419,189.026c0,4.677-3.827,8.504-8.504,8.504H66.997 c-4.677,0-8.504-3.827-8.504-8.504v-1.135c0-4.677,3.827-8.504,8.504-8.504h24.918c4.677,0,8.504,3.827,8.504,8.504V189.026z M151.416,189.026c0,4.677-3.827,8.504-8.504,8.504h-24.918c-4.677,0-8.504-3.827-8.504-8.504v-1.135 c0-4.677,3.827-8.504,8.504-8.504h24.918c4.677,0,8.504,3.827,8.504,8.504V189.026z M202.413,189.026 c0,4.677-3.827,8.504-8.504,8.504h-24.918c-4.677,0-8.504-3.827-8.504-8.504v-1.135c0-4.677,3.827-8.504,8.504-8.504h24.918 c4.677,0,8.504,3.827,8.504,8.504V189.026z"
                        />
                        <polygon
                            fill="#45346B"
                            points="-14.799,214.02 -14.799,220.558 221.413,220.558 227.297,214.02"
                        />
                    </g>
                    <rect
                        className="rail"
                        x="5.512"
                        y="225.791"
                        fill="#45346B"
                        width="320.824"
                        height="3"
                    />
                    <g className="stars">
                        <circle
                            fill="#45346B"
                            cx="294.727"
                            cy="70.673"
                            r="1.029"
                        />
                        <circle
                            fill="#45346B"
                            cx="299.683"
                            cy="75.102"
                            r="1.466"
                        />
                        <circle
                            fill="#45346B"
                            cx="251.058"
                            cy="39.7"
                            r="0.525"
                        />
                        <circle
                            fill="#45346B"
                            cx="238.58"
                            cy="38.677"
                            r="0.528"
                        />
                        <circle
                            fill="#45346B"
                            cx="254.248"
                            cy="48.321"
                            r="0.83"
                        />
                        <circle
                            fill="#45346B"
                            cx="256.2"
                            cy="55.844"
                            r="0.852"
                        />
                        <circle
                            fill="#45346B"
                            cx="231.412"
                            cy="48.763"
                            r="0.92"
                        />
                        <circle
                            fill="#45346B"
                            cx="235.064"
                            cy="62.618"
                            r="0.491"
                        />
                        <circle
                            fill="#45346B"
                            cx="231.011"
                            cy="68.756"
                            r="0.718"
                        />
                        <circle
                            fill="#45346B"
                            cx="229.104"
                            cy="72.026"
                            r="0.596"
                        />
                        <circle
                            fill="#45346B"
                            cx="279.947"
                            cy="72.572"
                            r="1.125"
                        />
                        <circle
                            fill="#45346B"
                            cx="275.187"
                            cy="59.939"
                            r="1.161"
                        />
                        <circle
                            fill="#45346B"
                            cx="265.126"
                            cy="57.302"
                            r="1.359"
                        />
                        <circle
                            fill="#45346B"
                            cx="264.844"
                            cy="51.228"
                            r="1.253"
                        />
                        <circle
                            fill="#45346B"
                            cx="274.13"
                            cy="48.183"
                            r="1.317"
                        />
                        <circle
                            fill="#45346B"
                            cx="250.763"
                            cy="39.539"
                            r="0.69"
                        />
                        <circle
                            fill="#45346B"
                            cx="195.414"
                            cy="16.309"
                            r="0.654"
                        />
                        <circle
                            fill="#45346B"
                            cx="188.33"
                            cy="18.199"
                            r="1.459"
                        />
                        <circle
                            fill="#45346B"
                            cx="180.471"
                            cy="18.293"
                            r="0.632"
                        />
                        <circle
                            fill="#45346B"
                            cx="197.131"
                            cy="30.173"
                            r="1.331"
                        />
                        <circle
                            fill="#45346B"
                            cx="186.836"
                            cy="29.647"
                            r="0.834"
                        />
                        <circle
                            fill="#45346B"
                            cx="183.747"
                            cy="33.578"
                            r="0.668"
                        />
                        <circle
                            fill="#45346B"
                            cx="193.677"
                            cy="47.145"
                            r="0.785"
                        />
                        <circle
                            fill="#45346B"
                            cx="192.691"
                            cy="55.733"
                            r="0.902"
                        />
                        <circle
                            fill="#45346B"
                            cx="188.027"
                            cy="56.098"
                            r="0.874"
                        />
                        <circle
                            fill="#45346B"
                            cx="172.198"
                            cy="55.781"
                            r="0.58"
                        />
                        <circle
                            fill="#45346B"
                            cx="170.933"
                            cy="69.551"
                            r="1.046"
                        />
                        <circle
                            fill="#45346B"
                            cx="166.234"
                            cy="70.639"
                            r="0.804"
                        />
                        <circle
                            fill="#45346B"
                            cx="156.255"
                            cy="75.001"
                            r="0.762"
                        />
                        <circle
                            fill="#45346B"
                            cx="179.401"
                            cy="84.374"
                            r="0.951"
                        />
                        <circle
                            fill="#45346B"
                            cx="211.574"
                            cy="78.884"
                            r="1.251"
                        />
                        <circle
                            fill="#45346B"
                            cx="207.891"
                            cy="72.122"
                            r="0.528"
                        />
                        <circle
                            fill="#45346B"
                            cx="224.404"
                            cy="57.155"
                            r="1.067"
                        />
                        <circle
                            fill="#45346B"
                            cx="212.788"
                            cy="48.727"
                            r="0.495"
                        />
                        <circle
                            fill="#45346B"
                            cx="191.64"
                            cy="38.917"
                            r="0.792"
                        />
                        <circle
                            fill="#45346B"
                            cx="194.585"
                            cy="35.377"
                            r="0.542"
                        />
                        <circle
                            fill="#45346B"
                            cx="197.175"
                            cy="20.76"
                            r="1.244"
                        />
                        <circle
                            fill="#45346B"
                            cx="216.039"
                            cy="13.118"
                            r="0.792"
                        />
                        <circle
                            fill="#45346B"
                            cx="192.554"
                            cy="7.688"
                            r="1.13"
                        />
                        <circle
                            fill="#45346B"
                            cx="139.29"
                            cy="8.132"
                            r="0.947"
                        />
                        <circle
                            fill="#45346B"
                            cx="138.168"
                            cy="17.312"
                            r="0.96"
                        />
                        <circle
                            fill="#45346B"
                            cx="141.053"
                            cy="21.763"
                            r="0.977"
                        />
                        <circle
                            fill="#45346B"
                            cx="111.438"
                            cy="13.377"
                            r="0.477"
                        />
                        <circle
                            fill="#45346B"
                            cx="128.59"
                            cy="34.555"
                            r="1.158"
                        />
                        <circle fill="#45346B" cx="118.4" cy="40.2" r="0.992" />
                        <circle
                            fill="#45346B"
                            cx="111.2"
                            cy="45.563"
                            r="0.885"
                        />
                        <circle
                            fill="#45346B"
                            cx="105.644"
                            cy="55.934"
                            r="1.338"
                        />
                        <circle
                            fill="#45346B"
                            cx="111.361"
                            cy="70.384"
                            r="1.066"
                        />
                        <circle
                            fill="#45346B"
                            cx="112.557"
                            cy="74.225"
                            r="0.642"
                        />
                        <circle
                            fill="#45346B"
                            cx="153.697"
                            cy="72.078"
                            r="1.069"
                        />
                        <circle
                            fill="#45346B"
                            cx="128.585"
                            cy="69.111"
                            r="1.327"
                        />
                        <circle
                            fill="#45346B"
                            cx="142.606"
                            cy="57.594"
                            r="1.529"
                        />
                        <circle
                            fill="#45346B"
                            cx="124.291"
                            cy="50.65"
                            r="1.301"
                        />
                        <circle
                            fill="#45346B"
                            cx="138.036"
                            cy="40.094"
                            r="0.453"
                        />
                        <circle
                            fill="#45346B"
                            cx="120.272"
                            cy="30.717"
                            r="1.456"
                        />
                        <circle
                            fill="#45346B"
                            cx="133.518"
                            cy="18.278"
                            r="0.749"
                        />
                        <circle
                            fill="#45346B"
                            cx="121.809"
                            cy="17.106"
                            r="1.096"
                        />
                        <circle
                            fill="#45346B"
                            cx="139.29"
                            cy="8.551"
                            r="0.696"
                        />
                        <circle
                            fill="#45346B"
                            cx="50.558"
                            cy="59.456"
                            r="0.43"
                        />
                        <circle
                            fill="#45346B"
                            cx="33.505"
                            cy="79.208"
                            r="0.69"
                        />
                        <circle
                            fill="#45346B"
                            cx="50.425"
                            cy="75.45"
                            r="1.425"
                        />
                        <circle
                            fill="#45346B"
                            cx="62.945"
                            cy="84.3"
                            r="1.297"
                        />
                        <circle
                            fill="#45346B"
                            cx="59.947"
                            cy="75.401"
                            r="1.461"
                        />
                        <circle
                            fill="#45346B"
                            cx="57.081"
                            cy="74.257"
                            r="0.778"
                        />
                        <circle
                            fill="#45346B"
                            cx="63.901"
                            cy="61.562"
                            r="1.317"
                        />
                        <circle
                            fill="#45346B"
                            cx="59.567"
                            cy="59.989"
                            r="1.135"
                        />
                        <circle
                            fill="#45346B"
                            cx="70.205"
                            cy="53.076"
                            r="0.469"
                        />
                        <circle
                            fill="#45346B"
                            cx="79.375"
                            cy="45.41"
                            r="1.142"
                        />
                    </g>
                </g>
            </svg>
        );
    }
}
