import { combineReducers } from "redux";
import activeElement from "./reducers/activeElement";
import countdown from "./reducers/countdown";
import city from "./reducers/city";
import dnd from "./reducers/dnd";
import questions from "./reducers/questions";
import cityLevel from "./reducers/cityLevel";
import theList from "./reducers/theList";
import appState from "./reducers/appState";
import audioSync from "./reducers/audioSync";
import multiChoice from "./reducers/multiChoice";
import isTyping from "./reducers/isTyping";
import userData from "./reducers/userData";
import hasSentHighscore from "./reducers/hasSentHighscore";
import showScore from "./reducers/showScore";
import unlockedAchievements from "./reducers/unlockedAchievements";
import tabBar from "./reducers/tabBar";
import { reducer as burgerMenu } from "redux-burger-menu";

export default combineReducers({
    activeElement,
    burgerMenu,
    hasSentHighscore,
    userData,
    countdown,
    city,
    cityLevel,
    multiChoice,
    dnd,
    theList,
    questions,
    appState,
    audioSync,
    isTyping,
    showScore,
    unlockedAchievements,
    tabBar,
});
