import { USER_SET, USER_NONE, USER_ERROR } from "../actions";

const initialState = {
    uno: null,
    amigo: null,
    loaded: false,
    error: false,
    friends: [],
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case USER_SET:
            return {
                ...state,
                uno: action.uno,
                amigo: action.amigo,
                loaded: true,
                error: false,
                friends: action.friends,
            };
        case USER_NONE:
            return {
                ...state,
                uno: null,
                amigo: null,
                loaded: true,
                error: false,
                friends: [],
            };
        case USER_ERROR:
            return {
                ...state,
                uno: null,
                amigo: null,
                loaded: true,
                error: true,
                friends: [],
            };
        default:
            return state;
    }
}
