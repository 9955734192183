import { useSelector } from "react-redux";
import LViS from "@monterosa/lvis-api";

import { AchievementType } from "../../../redux/reducers/unlockedAchievements";
import { getAchievementsFromEpisode } from "../../useUnlockAchievement";

export default function UnlockedAchievementsEpisode() {
    const unlockedAchievements = useSelector(
        (state: {
            unlockedAchievements: {
                [key: string]: AchievementType;
            };
        }) => state.unlockedAchievements,
    );

    const episodeNumber = LViS.getEvent()
        .getCustomFields()
        .episode_number.toString();

    const achievementsFromEpisode = getAchievementsFromEpisode(episodeNumber);

    const achievementsToDisplay: { [key: string]: AchievementType } =
        Object.keys(achievementsFromEpisode).reduce(
            (
                achievementsByGroup: { [key: string]: AchievementType },
                groupName,
            ) => {
                achievementsFromEpisode[groupName].forEach((achievement) => {
                    if (unlockedAchievements[achievement.code]) {
                        achievementsByGroup[groupName] =
                            unlockedAchievements[achievement.code];
                    }
                });
                return achievementsByGroup;
            },
            {},
        );

    if (Object.keys(achievementsToDisplay).length === 0) {
        return null;
    }

    return (
        <div className="unlockAchievements__wrapper">
            <div className="unlockAchievements__heading">
                Nya prestationer du låst upp
            </div>
            <ul className="unlockAchievements__list">
                {Object.values(achievementsToDisplay).map(({ code }) => {
                    return (
                        <li
                            className="unlockAchievements__list-item"
                            key={code}
                        >
                            <div>
                                {unlockedAchievements[code].image && (
                                    <div>
                                        <img
                                            className="unlockAchievements__image"
                                            src={
                                                unlockedAchievements[code].image
                                            }
                                        />
                                    </div>
                                )}

                                <div className="unlockAchievements__text">
                                    {unlockedAchievements[code].title}
                                </div>
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}
