import { CLEAR_EVENT, SET_SHOW_SCORE, SET_HIDE_SCORE } from "../actions";

const initialState = false;

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case CLEAR_EVENT:
            return initialState;
        case SET_SHOW_SCORE:
            return true;
        case SET_HIDE_SCORE:
            return false;
        default:
            return state;
    }
}
