import React from "react";
import PropTypes from "prop-types";

export default class TheListAnswerOption extends React.Component {
    constructor() {
        super();

        this.onChange = this.onChange.bind(this);
    }

    onChange() {
        this.props.changeHandler(this.props.index, !this.props.item.selected);
    }

    render() {
        let id = "theList" + this.props.index;
        let disabled = !this.props.canSelect && !this.props.item.selected;

        return (
            <li className="svt_the-list__list-item">
                <input
                    type="checkbox"
                    className="svt_the-list__checkbox"
                    checked={this.props.item.selected}
                    disabled={disabled}
                    id={id}
                    onChange={this.onChange}
                />{" "}
                <label htmlFor={id} className="svt_the-list__label -answer">
                    {this.props.item.option}
                </label>
            </li>
        );
    }
}

TheListAnswerOption.propTypes = {
    index: PropTypes.number.isRequired,
    item: PropTypes.object.isRequired,
    canSelect: PropTypes.bool.isRequired,
    changeHandler: PropTypes.func.isRequired,
};
