import IllustrationFriends from "../../../../assets/icons/illustrationFriends.svg?react";

export default function FriendsSlide() {
    return (
        <>
            <IllustrationFriends />
            <h1 className="onBoarding__title">Tävla mot dina vänner</h1>
            <p>
                Lägg till vänner och se varandra i topplistorna samtidigt som ni
                spelar, fråga för fråga.
            </p>
        </>
    );
}
