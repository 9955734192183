import React from "react";
import PropTypes from "prop-types";

import AnswerBoxCity from "./answerBox/answerBoxCity";

export default function QuestionAnswersCity({ question }) {
    return (
        <div className="svt_city-results">
            <AnswerBoxCity questionState={question} />
        </div>
    );
}

QuestionAnswersCity.propTypes = {
    question: PropTypes.object.isRequired,
};
