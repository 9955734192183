import React from "react";
import PropTypes from "prop-types";
import ArrowIcon from "@svt/duo-shared-assets/assets/icons/arrow.svg?react";

export default class AutoCompleteOption extends React.Component {
    constructor() {
        super();
        this._onClick = this._onClick.bind(this);
    }

    _onClick() {
        this.props.clickHandler(this.props.item);
    }

    render() {
        return (
            <li
                className="svt_ac__suggest-item"
                role="option"
                id={this.props.active ? "svt_ac__suggest-active" : null}
                aria-selected={this.props.active ? "true" : "false"}
            >
                <button
                    type="button"
                    className={`svt_ac__suggest-select ${
                        this.props.active ? "-selected" : ""
                    }`}
                    onClick={this._onClick}
                >
                    <div className="svt_ac__suggest-select-wrapper">
                        <div className="svt_ac__suggest-select-city">
                            {this.props.item}
                        </div>

                        <div className="svt_ac__suggest-select-instruction-wrapper">
                            <span className="svt_ac__suggest-select-instruction">
                                Välj{" "}
                                <ArrowIcon className="svt_ac__suggest-select-arrow_icon" />
                            </span>
                        </div>
                    </div>
                </button>
            </li>
        );
    }
}

AutoCompleteOption.propTypes = {
    active: PropTypes.bool.isRequired,
    item: PropTypes.string.isRequired,
    clickHandler: PropTypes.func.isRequired,
};
