import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { easeQuadInOut } from "d3-ease";
import "react-circular-progressbar/dist/styles.css";

import AnimatedProgressProvider from "./animatedProgressProvider";

export default function Gauge({
    valueStart,
    valueEnd,
    pathColor = "#CFBBE7",
    trailColor = "#45346B",
    children,
}: {
    valueStart: number;
    valueEnd: number;
    pathColor?: string;
    trailColor?: string;
    children: React.ReactNode;
}) {
    return (
        <AnimatedProgressProvider
            valueStart={valueStart}
            valueEnd={valueEnd}
            duration={1.4}
            easingFunction={easeQuadInOut}
        >
            {(value: number) => {
                return (
                    <div
                        style={{
                            width: "80px",
                            height: "80px",
                            position: "relative",
                            margin: "auto",
                        }}
                    >
                        <CircularProgressbar
                            className="hideToMatchImageSnapshot"
                            value={value}
                            styles={buildStyles({
                                pathTransition: "none",
                                rotation: 0.25 + (1 - value / 100) / 2,
                                pathColor: pathColor,
                                trailColor: trailColor,
                            })}
                        />
                        <div
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: " translate(-50%, -50%)",
                                lineHeight: "0",
                            }}
                        >
                            {children}
                        </div>
                    </div>
                );
            }}
        </AnimatedProgressProvider>
    );
}
