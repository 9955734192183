import { localStorageHelper } from "@svt/duo-shared-components";
import LViS from "@monterosa/lvis-api";

const _createRoundObject = ({ eventNumber, score, rounds, starData }) => {
    return {
        version: 3,
        episodeNumber: eventNumber,
        season: LViS.Project.getSettings().season_number,
        eventName: `Avsnitt ${eventNumber}`,
        score: score,
        rounds: rounds,
        starData: starData,

        date: new Date(),
    };
};

const saveRound = ({ eventNumber, score, starData, rounds }) => {
    let roundObject = _createRoundObject({
        eventNumber,
        score,
        rounds,
        starData,
    });
    let previousRounds = localStorageHelper.getState("previousRounds");

    if (!previousRounds) {
        previousRounds = [];
    }

    previousRounds.unshift(roundObject);
    previousRounds = previousRounds.slice(0, 200);

    localStorageHelper.setState("previousRounds", previousRounds);
};

export default saveRound;
