import { toplistSendPoints } from "@svt/duo-shared-components";

import questionTypes from "./questionTypes";
import { getSegment } from "./getSegment";

let store;

function init(sentStore) {
    store = sentStore;
}

function sendOne(questionId, questionIndex) {
    if (
        import.meta.env.VITE_PLAY_WITH_FRIENDS === "true" &&
        typeof questionIndex === "number"
    ) {
        // If we go back to start from menu, typeof (questionIndex) will be null
        // Small timeout to make sure answers are set in store
        setTimeout(() => {
            const questionState = store.getState().questions;

            if (questionState[questionId]) {
                toplistSendPoints([
                    {
                        segment: questionIndex,
                        data: {
                            points: questionState[questionId].points,
                            answer: questionState[questionId].answer,
                            starLevel: questionState[questionId].starLevel,
                        },
                    },
                ]);
            }
        }, 500);
    }
}

function sendAll(lvisEvent) {
    if (lvisEvent && import.meta.env.VITE_PLAY_WITH_FRIENDS === "true") {
        const historyElements = lvisEvent
            .getHistory()
            .filter((el) => questionTypes[el.getContentType()]);
        const questionState = store.getState().questions;
        const pointsArray = [];

        for (let i = 0; i < historyElements.length; i++) {
            const questionId = historyElements[i].getId();

            if (
                historyElements[i].getDurationLeft() === 0 &&
                questionState[questionId]
            ) {
                pointsArray.push({
                    segment: getSegment(i).toString(),
                    data: {
                        points: questionState[questionId].points,
                        answer: questionState[questionId].answer,
                        starLevel: questionState[questionId].starLevel,
                    },
                });
            }
        }

        if (pointsArray.length) {
            toplistSendPoints(pointsArray);
        }
    }
}

export default {
    init: init,
    sendOne: sendOne,
    sendAll: sendAll,
};
