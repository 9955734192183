import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import useGetScore from "../../helpers/useGetScore";
import Goblet from "@svt/duo-shared-assets/assets/icons/goblet.svg?react";

const Score = () => {
    const score = useGetScore();
    const [stateScore, setStateScore] = useState(score);
    const activeElement = useSelector((state) => state.activeElement);

    useEffect(() => {
        setStateScore(score);
    }, [activeElement, score]);

    return (
        <div className="svt_score">
            <div className="svt_score__icon">
                <Goblet width={"16px"} height={"16px"} />
            </div>
            {stateScore}
        </div>
    );
};

export default Score;
