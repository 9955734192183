import React from "react";
import PropTypes from "prop-types";
import ldIsEqual from "lodash/isEqual";

import AnswerBoxMultiChoice from "./answerBox/answerBoxMultiChoice";

export default class QuestionAnswersMultiChoice extends React.Component {
    _totalCorrectAnswers() {
        let noOfCorrectAnswers = 0;

        for (let singleCorrectAnswer of this.props.question.correctAnswer) {
            noOfCorrectAnswers =
                noOfCorrectAnswers + singleCorrectAnswer.length;
        }

        return noOfCorrectAnswers;
    }

    render() {
        const { correctAnswer } = this.props.question;

        return (
            <div className="svt_results-mc">
                <ul className="svt_results-mc__list">
                    {correctAnswer.map((singleCorrectAnswer, i) => {
                        return (
                            <li className="svt_results-mc__list-item" key={i}>
                                <AnswerBoxMultiChoice
                                    answer={
                                        this.props.question.answer
                                            ? this.props.question.answer[i]
                                            : null
                                    }
                                    correctAnswer={singleCorrectAnswer}
                                    isCorrect={
                                        this.props.question.answer
                                            ? ldIsEqual(
                                                  this.props.question.answer[i],
                                                  this.props.question
                                                      .correctAnswer[i],
                                              )
                                            : false
                                    }
                                />
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }
}

QuestionAnswersMultiChoice.propTypes = {
    question: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
};
