import gsap from "gsap";

const cityHandBreakAnimation = (buttonRef) => {
    if (buttonRef) {
        gsap.to(buttonRef, 0.5, {
            backgroundImage: "linear-gradient(#FFFFFF, #CFBBE7)",
            repeat: -1,
            yoyo: true,
            ease: "power2.inOut",
        });
    }
};

export default cityHandBreakAnimation;
