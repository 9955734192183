import { useEffect } from "react";
import { useSelector } from "react-redux";
import { DebugJump, analytics } from "@svt/duo-shared-components";
import LViS from "@monterosa/lvis-api";
import duoCom from "@svt/duo-communication";
import gsap from "gsap";

import { setEvent, setEventTimeJump } from "../../index";
import { appStateObjectType } from "../../helpers/propTypes";
import Reload from "@svt/duo-shared-assets/assets/icons/reload.svg?react";
import Pause from "@svt/duo-shared-assets/assets/icons/pause.svg?react";
import Score from "./score";

export default function Header({ appState }) {
    const isTyping = useSelector((state) => state.isTyping);
    const audioSync = useSelector((state) => state.audioSync);
    const showScore = useSelector((state) => state.showScore);

    let hideClass = isTyping ? " -hide" : "";

    function pause() {
        analytics.trackClickEvent("audiosync:pause", { component: "header" });
        if (duoCom.supports("audiosyncPause")) {
            duoCom.audiosyncPause();
        }
    }

    function reSync() {
        analytics.trackClickEvent("audiosync:resync", { component: "header" });
        if (duoCom.supports("audiosyncResync")) {
            duoCom.audiosyncResync();
        }
    }

    useEffect(() => {
        if (audioSync === "reSyncing") {
            gsap.to("#reloadAnimation", {
                rotation: -360,
                repeat: -1,
                duration: 1,
            });
        } else {
            gsap.set("#reloadAnimation", {
                rotate: 0,
            });
        }
    }, [audioSync]);

    return (
        <div className={"svt_header" + hideClass}>
            {(audioSync === "idling" || audioSync === "reSyncing") && (
                <button
                    type="button"
                    className="svt_header__button"
                    onClick={() => {
                        pause();
                    }}
                >
                    <Pause className="svt_header__icon" />
                    <div className="svt_header__button-text">pausa</div>
                </button>
            )}

            {audioSync === "idling" && (
                <button
                    type="button"
                    className="svt_header__button"
                    onClick={() => {
                        reSync();
                    }}
                >
                    <Reload className="svt_header__icon" />
                    <div className="svt_header__button-text">Synka om</div>
                </button>
            )}

            {audioSync === "reSyncing" && (
                <button
                    type="button"
                    className="svt_header__button"
                    disabled
                    aria-label="Synkar om ljud"
                >
                    <Reload className="svt_header__icon" id="reloadAnimation" />
                    <div className="svt_header__button-text svt_header__button-text-disabled">
                        Lyssnar...
                    </div>
                </button>
            )}

            {showScore && <Score />}

            {(import.meta.env.DEV || import.meta.env.VITE_DEBUG === "true") &&
                !__STORYBOOK__ && (
                    <DebugJump
                        setEventTimeJump={setEventTimeJump}
                        setEvent={setEvent}
                        lvis={LViS}
                        appState={appState.view}
                    />
                )}
        </div>
    );
}

Header.propTypes = {
    appState: appStateObjectType,
};
