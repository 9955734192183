import { CLEAR_EVENT, GO_TO_TAB } from "../actions";
export type ViewType = { view: "start" | "history" | "achievements" };
const initialState: { view: ViewType["view"] } = {
    view: "start",
};

export default function reducer(
    state = initialState,
    action: {
        type: string;
        payload: ViewType["view"];
    },
) {
    switch (action.type) {
        case CLEAR_EVENT:
            return initialState;
        case GO_TO_TAB:
            return { view: action.payload };

        default:
            return state;
    }
}
