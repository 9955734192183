import { useContext, useEffect, useState } from "react";
import { AccountContext, ContentStatus } from "@svt/duo-shared-components";
import LViS from "@monterosa/lvis-api";
import duoCom from "@svt/duo-communication";

import ErrorBoundary from "../../../errorBoundary";
import TopListSeason from "../history/topListSeason";
import { HighscoreAggregatedResponseType } from "@svt/duo-communication/dist/api/highscore-types";

export default function Season() {
    const accountContext = useContext(AccountContext);
    const [seasonState, setSeasonState] = useState<{
        isLoaded: boolean;
        hasError: boolean;
        bestSeason: HighscoreAggregatedResponseType | null;
    }>({
        isLoaded: false,
        hasError: false,
        bestSeason: null,
    });

    useEffect(() => {
        async function getSeason() {
            let seasonResponse: HighscoreAggregatedResponseType = {
                key: "",
                season: "",
                list: [],
            };

            try {
                seasonResponse = await duoCom.Highscore.fetchAggregate({
                    season: LViS.Project.getSettings().season_number.toString(),
                    postal_code:
                        accountContext.userData.amigo.demographic
                            ?.postal_code ?? undefined,
                });

                if (seasonResponse.county && seasonResponse.municipality) {
                    seasonResponse = {
                        ...seasonResponse,

                        county: {
                            //@ts-expect-error - FIX type
                            max: seasonResponse.county,
                            county: seasonResponse.county.county,
                        },
                        municipality: {
                            //@ts-expect-error - FIX type
                            max: seasonResponse.municipality,
                            municipality:
                                seasonResponse.municipality.municipality,
                        },
                    };
                }

                setSeasonState({
                    bestSeason: seasonResponse,
                    isLoaded: true,
                    hasError: false,
                });
            } catch (error) {
                if (import.meta.env.DEV) {
                    console.log(
                        "🚀 ~ file: loggedIn.tsx ~ getHistory ~ error:",
                        error,
                    );
                }
            }
        }

        if (accountContext.userData.loaded) {
            getSeason();
        }
    }, [
        accountContext.userData?.amigo?.demographic?.postal_code,
        accountContext.userData?.loaded,
    ]);

    return (
        <ErrorBoundary
            errorTextComponent={
                <span>
                    Det gick tyvärr inte att ladda din historik. Försök igen
                    senare
                </span>
            }
            showCloseGame={false}
        >
            <ContentStatus
                loaded={accountContext.userData.loaded && seasonState.isLoaded}
                error={accountContext.userData.error || seasonState.hasError}
                errorComponent={
                    <p>
                        Det gick tyvärr inte att ladda din historik. Försök igen
                        senare.
                    </p>
                }
            >
                {() =>
                    accountContext.userData.isLoggedIn
                        ? seasonState.bestSeason && (
                              <TopListSeason
                                  bestSeasonList={seasonState.bestSeason}
                              />
                          )
                        : null
                }
            </ContentStatus>
        </ErrorBoundary>
    );
}
