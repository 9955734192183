import IllustrationAudioSync from "../../../../assets/icons/illustrationAudioSync.svg?react";

export default function AudioSyncSlide() {
    return (
        <>
            <IllustrationAudioSync />
            <h1 className="onBoarding__title">Kliv ombord och spela med</h1>
            <p>
                Duo använder din mikrofon för att ta dig till rätt plats i
                avsnittet du tittar på. Då får du frågorna i synk med programmet
                och samlar poäng på samma sätt som burarna.
            </p>
        </>
    );
}
