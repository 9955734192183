import React from "react";
import PropTypes from "prop-types";

export default class MultiChoiceQuestionItem extends React.Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.type = this.props.multiAnswers ? "checkbox" : "radio";
    }

    onChange() {
        this.props.clickHandler(this.props.index, !this.props.isSelected);
    }

    render() {
        let id = "multi" + this.props.index;

        return (
            <li className="svt_mc-question-item">
                <input
                    type={this.type}
                    className="svt_mc-question-item__input"
                    checked={this.props.isSelected}
                    disabled={this.props.disabled && this.type === "checkbox"}
                    id={id}
                    name="multi"
                    onChange={this.onChange}
                />
                <label htmlFor={id} className="svt_mc-question-item__label">
                    {this.props.option.option}

                    {this.props.option.image ? (
                        <img
                            className="svt_mc-question-item__image"
                            src={this.props.option.image}
                            alt=""
                        />
                    ) : null}
                </label>
            </li>
        );
    }
}

MultiChoiceQuestionItem.propTypes = {
    option: PropTypes.object.isRequired,
    clickHandler: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    isSelected: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    multiAnswers: PropTypes.bool.isRequired,
    stopHandler: PropTypes.func.isRequired,
};
