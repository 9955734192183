import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import LevelPlateImage from "../../assets/icons/level-plate.svg?react";

class CityLevel extends React.Component {
    render() {
        if (this.props.cityLevel === 0) {
            return null;
        }

        let hideClass = this.props.isTyping ? " -hide" : "";
        let modifier = " -" + this.props.modifier;

        return (
            <div className={"svt_city-level" + modifier + hideClass}>
                <div className={"svt_city-level__plate" + modifier}>
                    <LevelPlateImage />
                    <h2 className="svt_city-level__plate-text">På nivå</h2>
                </div>

                <div className={"svt_city-level__box" + modifier}>
                    <span className={"svt_city-level__text" + modifier}>
                        {this.props.cityLevel}
                    </span>
                </div>
            </div>
        );
    }
}

CityLevel.propTypes = {
    modifier: PropTypes.string.isRequired,
    cityLevel: PropTypes.number.isRequired,
    isTyping: PropTypes.bool.isRequired,
};

export default connect((state) => ({
    cityLevel: state.cityLevel,
    isTyping: state.isTyping,
}))(CityLevel);
