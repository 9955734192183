import { CLEAR_EVENT, CITY_LEVEL_SET } from "../actions";

const initialState = 0;

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case CLEAR_EVENT:
            return initialState;
        case CITY_LEVEL_SET:
            return action.level;
        default:
            return state;
    }
}
