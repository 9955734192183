import update from "immutability-helper";

import {
    CLEAR_EVENT,
    STORED_STATE,
    QUESTIONS_NEW,
    QUESTIONS_SET_CORRECT_ANSWER,
    QUESTIONS_BEGIN_ANSWERING,
    QUESTIONS_STOP_ANSWERING,
    QUESTIONS_SET_ANSWER,
    QUESTIONS_RESET_ANSWER,
    QUESTIONS_LOCK_ANSWER,
} from "../actions";

const initialState = {};
const questionProps = {
    questionType: null,
    beginAnswer: false,
    correctAnswer: null,
    hasLockedAnswer: false,
    hasAnswered: false,
    answer: null,
    isCorrect: false,
    allCorrect: false,
    points: 0,
    answerTime: null,
    starLevel: 0,
};

export type QuestionPropsType = {
    questionType: string | null;
    beginAnswer: boolean;
    correctAnswer: string | null;
    hasLockedAnswer: boolean;
    hasAnswered: boolean;
    answer: string[] | null;
    isCorrect: boolean;
    allCorrect: boolean;
    points: number;
    answerTime: string[] | null;
    starLevel: number;
};

let tempStateHelper;

export default function reducer(
    state = initialState,
    action: QuestionPropsType & {
        id: string;
        type: string;
        data: {
            questions: QuestionPropsType;
        };
    },
) {
    switch (action.type) {
        case CLEAR_EVENT:
            return initialState;
        case STORED_STATE:
            return action.data.questions;
        case QUESTIONS_NEW:
            //@ts-expect-error - FIX type
            if (state[action.id]) {
                return state;
            }

            // Create
            tempStateHelper = update(state, {
                [action.id]: {
                    $set: questionProps,
                },
            });

            return update(tempStateHelper, {
                [action.id]: {
                    questionType: {
                        $set: action.questionType,
                    },
                },
            });
        case QUESTIONS_SET_CORRECT_ANSWER:
            return update(state, {
                [action.id]: {
                    correctAnswer: {
                        $set: action.correctAnswer,
                    },
                },
            });
        case QUESTIONS_BEGIN_ANSWERING:
            return update(state, {
                [action.id]: {
                    beginAnswer: {
                        $set: action.data,
                    },
                },
            });
        case QUESTIONS_STOP_ANSWERING:
            return update(state, {
                [action.id]: {
                    beginAnswer: {
                        $set: false,
                    },
                },
            });
        case QUESTIONS_SET_ANSWER:
            return update(state, {
                [action.id]: {
                    hasAnswered: {
                        $set: true,
                    },
                    answer: {
                        $set: action.answer,
                    },
                    isCorrect: {
                        $set: action.isCorrect,
                    },
                    allCorrect: {
                        $set: action.allCorrect,
                    },
                    points: {
                        $set: action.points,
                    },
                    answerTime: {
                        $set: action.answerTime,
                    },
                    starLevel: {
                        $set: action.starLevel,
                    },
                },
            });
        case QUESTIONS_RESET_ANSWER:
            return update(state, {
                [action.id]: {
                    hasAnswered: {
                        $set: false,
                    },
                    answer: {
                        $set: null,
                    },
                    isCorrect: {
                        $set: false,
                    },
                    allCorrect: {
                        $set: false,
                    },
                    points: {
                        $set: 0,
                    },
                    answerTime: {
                        $set: null,
                    },
                    starLevel: {
                        $set: 0,
                    },
                },
            });
        case QUESTIONS_LOCK_ANSWER:
            return update(state, {
                [action.id]: {
                    hasLockedAnswer: {
                        $set: true,
                    },
                    points: {
                        $set: action.points,
                    },
                    answerTime: {
                        $set: action.answerTime,
                    },
                    starLevel: {
                        $set: action.starLevel,
                    },
                },
            });
        default:
            return state;
    }
}
