import React from "react";
import PropTypes from "prop-types";

import StarsRow from "../starsRow";

let followUpQuesIndex = 0;

export default class EpisodeEndText extends React.Component {
    render() {
        let text = "";
        const { questionType, questionPoints, questionStars } = this.props;

        switch (questionType) {
            case "city": {
                followUpQuesIndex = 0;
                text = "Vart är vi på väg?";
                break;
            }
            case "multi-music": {
                text = "Musikfrågan";
                break;
            }
            case "multi-who-there": {
                text = "Vem där";
                break;
            }
            case "multi-return": {
                text = "Retur";
                break;
            }
            case "the_list": {
                text = "Listan";
                break;
            }
            case "closest_wins": {
                text = "Närmast vinner";
                break;
            }
            default: {
                followUpQuesIndex++;
                text = "Följdfrågor " + followUpQuesIndex;
            }
        }

        return (
            <div className="svt_episode-end-round__text">
                <h3 className="svt_episode-end-round__q-text">{text}</h3>
                <div className="svt_episode-end-round__score_star_wrapper">
                    <div className="svt_episode-end-round__q-score">
                        {questionPoints}
                    </div>
                    <div className="svt_episode-end-round__q-star">
                        <StarsRow
                            width={10}
                            height={10}
                            emptyStarClass="-black-inner-fill"
                            starLevel={questionStars}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

EpisodeEndText.propTypes = {
    questionType: PropTypes.string.isRequired,
    questionPoints: PropTypes.number.isRequired,
    questionStars: PropTypes.number.isRequired,
};
