import React from "react";
import PropTypes from "prop-types";

export default class MultiChoiceOverviewItem extends React.Component {
    constructor() {
        super();
        this._onClick = this._onClick.bind(this);
    }

    _onClick() {
        this.props.clickHandler(this.props.index);
    }

    render() {
        let answers;
        let answersDisplay;

        if (
            this.props.questionState.answer &&
            this.props.questionState.answer[this.props.index]
        ) {
            answers = this.props.questionState.answer[this.props.index];
            answersDisplay = answers.join(", ");
        }

        return (
            <li className="svt_mc-overview__list-item">
                <button
                    type="button"
                    className={
                        "svt_mc-overview__button " +
                        (answers ? "-answered" : "")
                    }
                    onClick={this._onClick}
                >
                    {this.props.question}
                    {answers ? (
                        <span className="svt_mc-overview__answer">
                            {answersDisplay}
                        </span>
                    ) : null}
                </button>
            </li>
        );
    }
}

MultiChoiceOverviewItem.propTypes = {
    index: PropTypes.number.isRequired,
    question: PropTypes.string.isRequired,
    questionState: PropTypes.object.isRequired,
    clickHandler: PropTypes.func.isRequired,
};
