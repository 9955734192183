import React from "react";
import PropTypes from "prop-types";

import AnswerBoxMarkup from "../../../shared/answerBox";

export default class AnswerBoxCity extends React.Component {
    render() {
        let modifier;
        let headText;
        let bodyText;
        let { correctAnswer, answer, isCorrect, hasAnswered } =
            this.props.questionState;

        if (hasAnswered) {
            bodyText = answer;

            if (isCorrect) {
                modifier = "-correct";
                headText = "Rätt svar";
            } else {
                modifier = "-incorrect";
                headText = "Fel svar";
            }
        } else {
            modifier = "-no-answer";
            headText = "Inget svar";
            bodyText = "-";
        }

        return (
            <AnswerBoxMarkup
                headText={headText}
                modifier={modifier}
                hasAnswered={hasAnswered}
                bodyText={bodyText}
                isCorrect={isCorrect}
                correctAnswer={correctAnswer[0]}
            />
        );
    }
}

AnswerBoxCity.propTypes = {
    questionState: PropTypes.object.isRequired,
};
