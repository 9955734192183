export const CLEAR_EVENT = "CLEAR_EVENT";
export const TIMERS_STOP = "TIMERS_STOP";

export const APP_STATE_SET = "APP_STATE_SET";

export const AUDIO_SYNC_SET = "AUDIO_SYNC_SET";

export const IS_TYPING_TRUE = "IS_TYPING_TRUE";
export const IS_TYPING_FALSE = "IS_TYPING_FALSE";

export const STORED_STATE = "STORED_STATE";

export const ACTIVE_ELEMENT_SET = "ACTIVE_ELEMENT_SET";
export const ACTIVE_ELEMENT_CLEAR = "ACTIVE_ELEMENT_CLEAR";

export const COUNTDOWN_TIMER_START = "COUNTDOWN_TIMER_START";
export const COUNTDOWN_TIMER_TICK = "COUNTDOWN_TIMER_TICK";
export const COUNTDOWN_TIMER_CLEAR = "COUNTDOWN_TIMER_CLEAR";

export const COUNTDOWN_ANIMATION_SET = "COUNTDOWN_ANIMATION_SET";
export const COUNTDOWN_ANIMATION_CLEAR = "COUNTDOWN_ANIMATION_CLEAR";

export const CITY_INITIAL = "CITY_INITIAL";
export const CITY_SET = "CITY_SET";
export const CITY_CLEAR = "CITY_CLEAR";
export const CITY_MOVE = "CITY_MOVE";

export const CITY_LEVEL_SET = "CITY_LEVEL_SET";

export const DND_INITIAL = "DND_INITIAL";
export const DND_SET = "DND_SET";
export const DND_UNSET = "DND_UNSET";

export const THE_LIST_INITIAL = "THE_LIST_INITIAL";
export const THE_LIST_TOGGLE_OPTION = "THE_LIST_TOGGLE_OPTION";

export const MULTI_CHOICE_INITIAL = "MULTI_CHOICE_INITIAL";
export const MULTI_CHOICE_TOGGLE_OPTION = "MULTI_CHOICE_TOGGLE_OPTION";

export const QUESTIONS_NEW = "QUESTIONS_NEW";
export const QUESTIONS_SET_CORRECT_ANSWER = "QUESTIONS_SET_CORRECT_ANSWER";
export const QUESTIONS_BEGIN_ANSWERING = "QUESTIONS_BEGIN_ANSWERING";
export const QUESTIONS_STOP_ANSWERING = "QUESTIONS_STOP_ANSWERING";
export const QUESTIONS_SET_ANSWER = "QUESTIONS_SET_ANSWER";
export const QUESTIONS_RESET_ANSWER = "QUESTIONS_RESET_ANSWER";
export const QUESTIONS_LOCK_ANSWER = "QUESTIONS_LOCK_ANSWER";

export const USER_SET = "USER_SET";
export const USER_NONE = "USER_NONE";
export const USER_ERROR = "USER_ERROR";

export const SET_SENT_HIGHSCORE = "SET_SENT_HIGHSCORE";

export const SET_SHOW_SCORE = "SET_SHOW_SCORE";
export const SET_HIDE_SCORE = "SET_HIDE_SCORE";

export const ADD_UNLOCKED_ACHIEVEMENT = "ADD_UNLOCKED_ACHIEVEMENT";

export const GO_TO_TAB = "GO_TO_TAB";
