import React from "react";
import PropTypes from "prop-types";

export default class Star extends React.Component {
    render() {
        const width = this.props.width || 16;
        const height = this.props.height || 16;
        const innerFill = this.props.innerFill || "#7a6a97";
        const outerFill = this.props.outerFill || "#45346B";
        const outerClassName = this.props.outerClassName || "__outer";
        const innerClassName = this.props.innerClassName || "__inner";

        return (
            <svg
                className={`svt_star ${
                    this.props.modifier ? this.props.modifier : ""
                }`}
                width={width}
                height={height}
                viewBox="0 0 130 126"
            >
                <polygon
                    className={outerClassName}
                    fill={innerFill}
                    points="65.333,0 85.54,41.487 130.667,48.113 98,80.407 105.7,126 65.333,104.487 24.967,126 32.667,80.407 0,48.113 45.127,41.487 "
                />
                <polygon
                    className={innerClassName}
                    fill={outerFill}
                    points="65.003,24.929 78.097,51.812 107.339,56.106 86.171,77.033 91.161,106.577 65.003,92.637 38.845,106.577 43.835,77.033 22.667,56.106 51.909,51.812 "
                />
            </svg>
        );
    }
}

Star.propTypes = {
    modifier: PropTypes.string,
    innerFill: PropTypes.string,
    outerFill: PropTypes.string,
    innerClassName: PropTypes.string,
    outerClassName: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};
