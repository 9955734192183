import { useContext } from "react";
import { AccountContext, ToplistContext } from "@svt/duo-shared-components";
import { useSelector } from "react-redux";
import { AccountContextType } from "@svt/duo-shared-components/dist/types/types";

import { QuestionPropsType } from "../../../redux/reducers/questions";
import useGetScore from "../../../helpers/useGetScore";
import { useUnlockAchievement } from "../../useUnlockAchievement";

export default function useEpisodeEndUnlockAchievements() {
    const accountContext = useContext<AccountContextType>(AccountContext);
    const { unlockEpisodeEnd } = useUnlockAchievement();

    const questionsState = useSelector(
        (state: { questions: Record<string, QuestionPropsType> }) =>
            state.questions,
    );
    const toplistContext = useContext(ToplistContext);
    const score = useGetScore();

    if (!accountContext.userData.isLoggedIn) {
        return null;
    }

    unlockEpisodeEnd({
        toplistContext: toplistContext,
        questionsState: questionsState,
        score,
    });

    return null;
}
