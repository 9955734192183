// Taken from https://codesandbox.io/p/sandbox/vymm4oln6y?file=%2FAnimatedProgressProvider.js%3A9%2C5
import { useEffect, useRef, useState } from "react";
import { Animate } from "react-move";

export default function AnimatedProgressProvider({
    duration,
    repeat,
    valueStart,
    valueEnd,
    easingFunction,
    children,
}: {
    duration: number;
    repeat?: boolean;
    valueStart: number;
    valueEnd: number;
    easingFunction: (normalizedTime: number) => number;
    children: (value: number) => JSX.Element;
}) {
    const setIntervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
    const [animateState, setAnimateState] = useState({ isAnimated: false });
    const hasRunRef = useRef<boolean>(false);

    useEffect(() => {
        if (hasRunRef.current) {
            return;
        }
        hasRunRef.current = true;

        if (repeat) {
            setIntervalRef.current = setInterval(() => {
                setAnimateState({
                    isAnimated: !animateState.isAnimated,
                });
            }, duration * 1000);
        } else {
            setAnimateState({
                isAnimated: !animateState.isAnimated,
            });
        }

        return () => {
            if (setIntervalRef.current) {
                clearInterval(setIntervalRef.current);
            }
        };
    }, [animateState.isAnimated, duration, repeat]);

    return (
        <Animate
            start={() => ({
                value: valueStart,
            })}
            update={() => ({
                value: [animateState.isAnimated ? valueEnd : valueStart],
                timing: {
                    duration: duration * 1000,
                    ease: easingFunction,
                },
            })}
        >
            {({ value }) => children(value)}
        </Animate>
    );
}
