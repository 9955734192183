import { AccountPromotion } from "@svt/duo-shared-components";

import IllustrationAudioSync from "../../../../assets/icons/illustrationAudioSync.svg?react";

const LoggedOut = () => {
    return (
        <>
            <IllustrationAudioSync style={{ marginTop: "20px" }} />

            <AccountPromotion
                loggedOutHeader={"Spara dina poäng för säsongen"}
                loggedOutBody={
                    "När du spelar inloggad sparas din spelhistorik. Se vilka avsnitt du har kvar att spela och jämför ditt resultat över säsongen."
                }
                inlineStyles={{
                    button: { backgroundColor: "#311c57" },
                    headingLevel: {
                        color: "#CFBBE7",
                        fontSize: "20px",
                        fontWeight: "bold",
                    },
                }}
            />
        </>
    );
};

export default LoggedOut;
