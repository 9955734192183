import LViS from "@monterosa/lvis-api";

export type StatisticsCityElementKey =
    | "user_points_statistics_city_1"
    | "user_points_statistics_city_2"
    | "user_points_statistics_city_3";

export function getCityElements() {
    return LViS.getEvent()
        .getHistory()
        .filter((element) => {
            return element.getContentType() === "city";
        });
}

export function findStatisticsCityElement() {
    const cityElements = getCityElements();

    let elementKey: StatisticsCityElementKey;

    switch (cityElements.length) {
        case 0:
            return null;
        case 1: {
            elementKey = "user_points_statistics_city_1";
            break;
        }

        case 2: {
            elementKey = "user_points_statistics_city_2";
            break;
        }
        default: {
            elementKey = "user_points_statistics_city_3";
            break;
        }
    }

    const cityElement = LViS.getEvent()
        .getHistory()
        .find((element) => element.getContentType() === elementKey);

    return cityElement ?? null;
}

export function getUserPointsStatisticsElement() {
    return LViS.getEvent()
        .getHistory()
        .find(
            (element) =>
                element.getContentType() === "user_points_statistics_total",
        );
}
