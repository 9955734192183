import React from "react";
import PropTypes from "prop-types";

import handbreakImage from "../../../assets/inline/handbreak.png";

export default class CityHandbreak extends React.Component {
    constructor() {
        super();

        this.startY = null; // Y-cordinate of first touch
        this.moveStartY = null; // Y-cordinate of how start of move
        this.buttonRef = React.createRef();
        this.textRef = React.createRef();
        this.handleTouchStart = this.handleTouchStart.bind(this);
        this.handleTouchEnd = this.handleTouchEnd.bind(this);
        this.handleTouchMove = this.handleTouchMove.bind(this);
    }

    handleTouchStart(touchStartEvent) {
        this.startY = touchStartEvent.changedTouches[0].pageY;
    }

    handleTouchEnd(touchEndEvent) {
        const endY = touchEndEvent.changedTouches[0].pageY;

        if (this.startY < endY) {
            this.props.clickHandler();
        }

        // reset y-positions
        this.startY = null;
        this.moveStartY = null;
    }

    handleTouchMove(touchMoveEvent) {
        const maxMovedY = 70;
        const minMovedY = 0;

        if (this.moveStartY === null) {
            this.moveStartY = touchMoveEvent.changedTouches[0].pageY;
        }

        let movingY = touchMoveEvent.changedTouches[0].pageY - this.moveStartY; // Calculating how far you are moving

        // Check if moved more than maximum
        if (movingY > maxMovedY) {
            movingY = maxMovedY;
        }
        // Check if moved less than maximum
        if (movingY < minMovedY) {
            movingY = minMovedY;
        }

        // Move element
        this.buttonRef.current.style.webkitTransform = `translateY(${movingY}px)`;

        // Dim text
        this.textRef.current.style.opacity = (maxMovedY - movingY) / maxMovedY;
    }

    render() {
        return (
            <div className="svt_city-handbreak">
                <h1 className="svt_city-handbreak__header">
                    Vart är vi på väg?
                </h1>
                <button
                    type="button"
                    ref={this.buttonRef}
                    onTouchStart={this.handleTouchStart}
                    onTouchEnd={this.handleTouchEnd}
                    onTouchMove={this.handleTouchMove}
                    onClick={this.props.clickHandler}
                    aria-label="Dra i nödbromsen"
                    className="svt_city-handbreak__button"
                >
                    <img width="180" height="330" src={handbreakImage} alt="" />
                </button>
                <p ref={this.textRef} className="svt_city-handbreak__text">
                    Dra i nödbromsen när du vill svara
                </p>
            </div>
        );
    }
}

CityHandbreak.propTypes = {
    clickHandler: PropTypes.func.isRequired,
};
