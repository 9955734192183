import { useContext, useEffect, useRef } from "react";
import { AccountContext } from "@svt/duo-shared-components";
import duoCom from "@svt/duo-communication";
import LViS from "@monterosa/lvis-api";
import * as Sentry from "@sentry/react";
import { useSelector, useDispatch } from "react-redux";

import { SET_SENT_HIGHSCORE } from "../../redux/actions";
import useGetScore from "../../helpers/useGetScore";
import { AccountContextType } from "../../types";

export default function HighScoreSender() {
    const isSendingHighscoreRef = useRef(false);
    const dispatch = useDispatch();
    const accountContext = useContext<AccountContextType>(AccountContext);
    const hasSentHighscore = useSelector(
        (state: { hasSentHighscore: boolean }) => state.hasSentHighscore,
    );
    const score = useGetScore();

    useEffect(() => {
        if (
            accountContext.userData.isLoggedIn &&
            hasSentHighscore === false &&
            isSendingHighscoreRef.current === false
        ) {
            isSendingHighscoreRef.current = true;
            duoCom.Highscore.submit({
                season: LViS.Project.getSettings().season_number.toString(),
                episode: LViS.getEvent()
                    .getCustomFields()
                    .episode_number.toString(),
                score: score,
                postal_code:
                    accountContext.userData.amigo?.demographic?.postal_code ??
                    undefined,
            })
                .catch((error) => {
                    Sentry.captureException(error);
                })
                .then(() => {
                    dispatch({
                        type: SET_SENT_HIGHSCORE,
                    });
                })
                .finally(() => {
                    isSendingHighscoreRef.current = false;
                });
        }
    }, [
        accountContext.userData.amigo?.demographic?.postal_code,
        accountContext.userData.isLoggedIn,
        dispatch,
        hasSentHighscore,
        score,
    ]);

    return null;
}
