import React from "react";
import { useSelector } from "react-redux";

import { getPreviousQuestion } from "../../../helpers/questionHelpers";
import Waiting from "../waiting/waiting";
import ClosestWinsWaitingShared from "./shared/closestWinsWaitingShared";

function ClosestWinsWaiting() {
    const previousQuestionsElement = React.useRef(getPreviousQuestion());
    const questions = useSelector((state) => state.questions);

    // Couldn't find question
    if (!previousQuestionsElement.current) {
        return <Waiting />;
    }

    const questionState = questions[previousQuestionsElement.current.getId()];

    // User didn't see question
    if (!questionState) {
        return <Waiting />;
    }

    // There is no answer yet
    if (!questionState.answer) {
        return null;
    }

    return (
        <ClosestWinsWaitingShared
            question={previousQuestionsElement.current.getCustomFields()}
            questionState={questionState}
        />
    );
}

export default ClosestWinsWaiting;
