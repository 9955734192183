import React from "react";
import { scaleRotate as Menu } from "react-burger-menu";
import { decorator as reduxBurgerMenu } from "redux-burger-menu";

import MenuItems from "./menuItems";
import CloseIcon from "../../../assets/icons/close.svg?react";
import MenuIcon from "../../../assets/icons/menu.svg?react";

const GlobalMenu = (props) => {
    return (
        <Menu
            customBurgerIcon={
                <div>
                    <MenuIcon />
                </div>
            }
            customCrossIcon={
                <div>
                    <CloseIcon />
                </div>
            }
            outerContainerId={"svt_app"}
            pageWrapId={"svt_flex-container"}
            width={"80%"}
            {...props}
        >
            <MenuItems />
        </Menu>
    );
};

export default reduxBurgerMenu(GlobalMenu);
