export default function calculateStarLevel(answerTime, activeElement) {
    let starLevel = null;

    if (answerTime < activeElement.star_3) {
        starLevel = 3;
    } else if (answerTime < activeElement.star_2) {
        starLevel = 2;
    } else if (answerTime < activeElement.star_1) {
        starLevel = 1;
    } else {
        starLevel = 0;
    }

    return starLevel;
}
