import React from "react";
import duoCom from "@svt/duo-communication";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
    MenuAccountPromotion,
    MenuProfile,
    AccountContext,
} from "@svt/duo-shared-components";

import backToStart from "../../../helpers/backToStart";
import closeApp from "../../../helpers/closeApp";
import { getStore } from "../../../redux/configureStore";
import ProblemInfo from "../../shared/problemInfo";
import { appStateObjectType } from "../../../helpers/propTypes";

const MenuItems = (props) => {
    const accountContext = React.useContext(AccountContext);

    function _closeApp() {
        const activeElement = getStore().getState().activeElement;
        let isEndScreen = false;

        if (activeElement && activeElement.type === "episode_end") {
            isEndScreen = true;
        }

        closeApp(
            isEndScreen,
            accountContext.userData.isLoggedIn,
            accountContext.signUp,
        );
    }

    function _sendFeedback() {
        if (duoCom.supports("triggerFeedback")) {
            duoCom.triggerFeedback();
        }
    }

    function _backToStart() {
        const activeElement = getStore().getState().activeElement;
        let isEndScreen = false;

        if (activeElement && activeElement.type === "episode_end") {
            isEndScreen = true;
        }

        backToStart(
            isEndScreen,
            accountContext.userData.isLoggedIn,
            props.dispatch,
            accountContext.signUp,
        );
    }

    return (
        <div className="menu-items__wrapper">
            <div>
                <ul className="menu-items__menuListWrapper">
                    <li>
                        <button
                            type="button"
                            className="menu-items__menuButton"
                            onClick={() => {
                                _sendFeedback();
                            }}
                        >
                            Lämna feedback
                        </button>
                    </li>

                    {props.appState.view !== null && (
                        <li>
                            <button
                                type="button"
                                className="menu-items__menuButton"
                                onClick={() => {
                                    _backToStart();
                                }}
                            >
                                Tillbaka till start
                            </button>
                        </li>
                    )}

                    <li>
                        <button
                            type="button"
                            className="menu-items__menuButton"
                            onClick={() => {
                                _closeApp();
                            }}
                        >
                            Avsluta spelet
                        </button>
                    </li>
                </ul>
            </div>

            <div>
                <ProblemInfo />
                {
                    // Hide account promotion if HIDE_ADD_FRIENDS is true and user is logged in
                    import.meta.env.VITE_HIDE_ADD_FRIENDS === "true" &&
                    accountContext.userData.isLoggedIn ? null : (
                        <MenuAccountPromotion
                            inlineStyles={{
                                wrapper: { borderTop: "1px solid #47395F" },
                                headingLevel: { color: "#CFBBE7" },
                                button: { backgroundColor: "#5C3E7E" },
                            }}
                        />
                    )
                }
                <MenuProfile
                    inlineStyles={{
                        wrapper: { background: "rgba(0, 0, 0, 0.20)" },
                    }}
                />
            </div>
        </div>
    );
};

MenuItems.propTypes = {
    appState: appStateObjectType,
    audioSync: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
};

export default connect((state) => ({
    appState: state.appState,
    audioSync: state.audioSync,
}))(MenuItems);
