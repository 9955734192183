import { useContext } from "react";
import { AccountContext } from "@svt/duo-shared-components";
import duoCom from "@svt/duo-communication";
import VisibilitySensor from "react-visibility-sensor";
import { analytics } from "@svt/duo-shared-components";
import { HighscoreFetchResponseType } from "@svt/duo-communication/dist/api/highscore-types";

import TopListShared from "../../../shared/toplist/shared";
import { AccountContextType } from "../../../../types";

export default function useGeography({
    county = null,
    municipality = null,
    view,
    showPostalCodeNotSubmittedMessage,
}: {
    county?: HighscoreFetchResponseType["episodes"][0]["county"] | null;
    municipality?:
        | HighscoreFetchResponseType["episodes"][0]["municipality"]
        | null;
    view: string;
    showPostalCodeNotSubmittedMessage?: boolean;
}) {
    const accountContext: AccountContextType = useContext(AccountContext);
    const hasGeographical = county !== null && municipality !== null;
    const postCodeValidatedStatus =
        accountContext.userData.amigo?.demographic?.validated?.postalCode
            .status;

    function getGeographyContent(place: string) {
        switch (postCodeValidatedStatus) {
            case "INVALID": {
                return (
                    <div className="use-geography__wrapper">
                        <p className="use-geography__text">
                            Vi kunde inte hitta {place}. Kontrollera gärna
                            postnumret.
                        </p>
                        <button
                            type="button"
                            className="use-geography__postCode-button"
                            onClick={() => {
                                addPostCode();
                            }}
                        >
                            Ändra postnummer
                        </button>
                    </div>
                );
            }
            case "NOT_FOUND": {
                return (
                    <div className="use-geography__wrapper">
                        <p className="use-geography__text">
                            Vi kunde inte hitta {place}. Kontrollera gärna
                            postnumret.
                        </p>
                        <button
                            type="button"
                            className="use-geography__postCode-button"
                            onClick={() => {
                                addPostCode();
                            }}
                        >
                            Ändra postnummer
                        </button>
                    </div>
                );
            }
            default: {
                return (
                    <div className="use-geography__wrapper">
                        <p className="use-geography__text">
                            Fyll i ditt postnummer för att jämföra med andra
                            spelare i ditt område.
                        </p>
                        <button
                            type="button"
                            className="use-geography__postCode-button"
                            onClick={() => {
                                addPostCode();
                            }}
                        >
                            Fyll i postnummer
                        </button>
                    </div>
                );
            }
        }
    }

    function addPostCode() {
        analytics.trackClickEvent("start:lägg-till-postnummer");
        if (duoCom.supports("presentModalWebView")) {
            duoCom
                .presentModalWebView({
                    route: "/modal-edit-demographic",
                    width: "90%",
                    height: "300px",
                    payload: {
                        buttonText: "Spara",
                        heading: "Dina kontouppgifter",
                        view: view,
                    },
                })
                .then(() => {
                    accountContext.setGetUser();
                });
        }
    }

    function getPostalCodeNotSubmittedMessage() {
        return (
            <div className="use-geography__wrapper">
                <p className="use-geography__text">
                    Det gick tyvärr inte att hämta topplistan just nu.
                </p>
                <button
                    type="button"
                    className="use-geography__postCode-button"
                    onClick={() => {
                        addPostCode();
                    }}
                >
                    Hämta topplistan igen
                </button>
            </div>
        );
    }

    return [
        {
            header: hasGeographical ? municipality.municipality : "Kommun",
            name: "Kommun",
            toplist:
                hasGeographical && municipality ? (
                    <TopListShared
                        topList={municipality.max.list}
                        user={municipality.max.user}
                    />
                ) : (
                    <VisibilitySensor
                        delayedCall
                        onChange={(isVisible: boolean) => {
                            if (isVisible) {
                                analytics.trackCustomEvent(view, "visible", {
                                    statisticsName: "postnummer/kommun",
                                });
                            }
                        }}
                    >
                        {showPostalCodeNotSubmittedMessage
                            ? getPostalCodeNotSubmittedMessage()
                            : getGeographyContent("din kommun")}
                    </VisibilitySensor>
                ),
        },
        {
            header: hasGeographical ? county.county : "Län",
            name: "Län",
            toplist:
                hasGeographical && county ? (
                    <TopListShared
                        topList={county.max.list}
                        user={county.max.user}
                    />
                ) : (
                    <VisibilitySensor
                        delayedCall
                        onChange={(isVisible: boolean) => {
                            if (isVisible) {
                                analytics.trackCustomEvent(view, "visible", {
                                    statisticsName: "postnummer/län",
                                });
                            }
                        }}
                    >
                        {showPostalCodeNotSubmittedMessage
                            ? getPostalCodeNotSubmittedMessage()
                            : getGeographyContent("ditt län")}
                    </VisibilitySensor>
                ),
        },
    ];
}
