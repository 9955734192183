import { CLEAR_EVENT, AUDIO_SYNC_SET } from "../actions";
const initialState = "stopped";

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case CLEAR_EVENT:
            return initialState;
        case AUDIO_SYNC_SET:
            return action.data;
        default:
            return state;
    }
}
