import React from "react";
import ReactDOM from "react-dom";
import DndDragLayer from "./dndDragLayer";

export default class DndDragPortal extends React.Component {
    render() {
        const rootEl = document.getElementById("svt_drag");

        return ReactDOM.createPortal(<DndDragLayer />, rootEl);
    }
}
