import { localStorageHelper } from "@svt/duo-shared-components";
import * as Sentry from "@sentry/react";
import { useDispatch } from "react-redux";
import { ReactNode } from "react";
import { HighscoreFetchResponseType } from "@svt/duo-communication/dist/api/highscore-types";

import { APP_STATE_SET } from "../../../../redux/actions";
import { TeamsType } from "../../../../types";

export default function UserPlayed({
    episodeNumber,
    episodeHighscore,
    children,
    userScore,
}: {
    episodeNumber: string;
    episodeHighscore: HighscoreFetchResponseType["episodes"][0] & {
        teams?: TeamsType[];
    };

    children: ReactNode;
    userScore: number;
}) {
    const dispatch = useDispatch();

    return (
        <button
            data-testid="episode-summary-button"
            type="button"
            className="episodeList__button"
            onClick={() => {
                const episodeNumberAsNumber = parseInt(episodeNumber, 10);
                let previousRoundByEpisode = localStorageHelper
                    .getState("previousRounds")
                    // @ts-expect-error - fix type
                    ?.filter((round) => round.rounds)
                    .filter(
                        // @ts-expect-error - fix type
                        (round) =>
                            round.episodeNumber === episodeNumberAsNumber,
                    );

                if (previousRoundByEpisode) {
                    if (previousRoundByEpisode.length > 1) {
                        previousRoundByEpisode = previousRoundByEpisode?.reduce(
                            // @ts-expect-error - fix type
                            (a, b) => {
                                return new Date(a.date) > new Date(b.date)
                                    ? a
                                    : b;
                            },
                        );
                    } else {
                        previousRoundByEpisode = previousRoundByEpisode[0];
                    }
                }

                const missingUsernameInFriend = (
                    episodeHighscore?.friends?.max ?? []
                )
                    // @ts-expect-error TODO: Update FriendUserType
                    .some((friend) => !friend.username);

                if (missingUsernameInFriend) {
                    Sentry.captureMessage(`Missing username for friend`);
                }

                dispatch({
                    type: APP_STATE_SET,
                    view: "episodeSummary",
                    data: {
                        rounds: previousRoundByEpisode?.rounds,
                        starData: previousRoundByEpisode?.starData,
                        score: userScore,
                        episodeNumber: episodeNumberAsNumber,
                        episodeHighscore: {
                            friends: episodeHighscore.friends,
                            teams: episodeHighscore.teams,
                            toplist: episodeHighscore.max.list,
                            user: episodeHighscore.max.user,
                            municipality: episodeHighscore.municipality,
                            county: episodeHighscore.county,
                        },
                    },
                });
            }}
        >
            {children}
        </button>
    );
}
