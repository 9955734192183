import { useContext } from "react";
import { AccountContext, AccountPromotion } from "@svt/duo-shared-components";
import { AccountContextType } from "@svt/duo-shared-components/dist/types/types";

import IllustrationAchievements from "../../../../assets/icons/illustrationAchievements.svg?react";
import Achievements from "./achievements";

export default function Index() {
    const accountContext = useContext<AccountContextType>(AccountContext);

    return (
        <div>
            {accountContext.userData.isLoggedIn ? (
                <Achievements />
            ) : (
                <>
                    <IllustrationAchievements style={{ marginTop: "20px" }} />
                    <AccountPromotion
                        loggedOutHeader={"Nya prestationer varje vecka"}
                        loggedOutBody={
                            "Utmana dig själv och samla prestationer både över säsongen och i varje avsnitt. Bygg upp din trofésamling och ja, skryt gärna lite för dina vänner!"
                        }
                        inlineStyles={{
                            button: { backgroundColor: "#311c57" },
                            headingLevel: {
                                color: "#CFBBE7",
                                fontSize: "20px",
                                fontWeight: "bold",
                            },
                        }}
                    />
                </>
            )}
        </div>
    );
}
