import { useContext, useEffect, useState } from "react";
import { AccountContext, LottieAnimation } from "@svt/duo-shared-components";
import LViS from "@monterosa/lvis-api";
import LoaderImg from "@svt/duo-shared-assets/assets/icons/loader.svg?react";

import {
    findStatisticsCityElement,
    getCityElements,
} from "../../../../helpers/findStatisticsElement";
import calculatePointsPercentage, {
    getTotalNumberOfPlayers,
} from "../../../../helpers/calculatePointsPercentage";
import BarChart from "./barChart";
import { AccountContextType, ResultsType } from "../../../../types";
import barChartAnimation from "../../../../assets/lottie/bar-chart.json";
import { hideToMatchImageSnapshotClass } from "../../../../helpers/imageTest";

export default function StatisticsCityAnswers({
    userPoints,
    hasAnswered,
}: {
    userPoints: number;
    hasAnswered: boolean;
}) {
    const statisticsCityElement = findStatisticsCityElement();
    const accountContext = useContext<AccountContextType>(AccountContext);
    const [allPlayerPointsCity, setAllPlayerPointsCity] =
        // @ts-expect-error - fix type
        useState<ResultsType | null>(statisticsCityElement.results);
    useEffect(() => {
        function updateResults() {
            // @ts-expect-error - fix type
            setAllPlayerPointsCity(statisticsCityElement.results);
        }

        // @ts-expect-error - fix type
        statisticsCityElement.on(LViS.Regular.ON_RESULTS, updateResults);
        return () => {
            // @ts-expect-error - fix type
            statisticsCityElement.off(LViS.Regular.ON_RESULTS, updateResults);
        };
    }, [statisticsCityElement]);

    if (!allPlayerPointsCity) {
        return null;
    }

    const barChartlabels = ["2", "4", "6", "8", "10"];

    const allPlayerPointsForBarChart = [
        allPlayerPointsCity[1]?.votes,
        allPlayerPointsCity[2]?.votes,
        allPlayerPointsCity[3]?.votes,
        allPlayerPointsCity[4]?.votes,
        allPlayerPointsCity[5]?.votes,
    ];

    const highestBarHeight = 200;

    function login() {
        accountContext.login(`resa ${getCityElements().length}`);
    }

    function getPerformanceStatus() {
        switch (userPoints) {
            case 2:
                return (
                    <div className="statistics-city-answers__performance-status-gray">
                        Kämpa på
                    </div>
                );
            case 4:
            case 6:
                return (
                    <div className="statistics-city-answers__performance-status-blue">
                        Bra
                    </div>
                );
            default:
                return (
                    <div className="statistics-city-answers__performance-status-green">
                        Snyggt!
                    </div>
                );
        }
    }

    function getLoginPromptTitle() {
        if (!allPlayerPointsCity) return;
        if (hasAnswered) {
            if (userPoints == 0) {
                return (
                    <div className="statistics-city-answers__title">
                        {allPlayerPointsCity[0]?.votes.toLocaleString("sv-SE")}
                        {allPlayerPointsCity[0]?.votes === 1
                            ? " annan "
                            : " andra "}
                        svarade fel!
                    </div>
                );
            }
            return (
                <div className="statistics-city-answers__title">
                    {allPlayerPointsCity[userPoints / 2]?.votes.toLocaleString(
                        "sv-SE",
                    )}
                    {allPlayerPointsCity[userPoints / 2]?.votes === 1
                        ? " annan "
                        : " andra "}
                    drog på {userPoints} poäng!
                </div>
            );
        }
        return (
            <div className="statistics-city-answers__title">
                {allPlayerPointsCity[4]?.votes.toLocaleString("sv-SE")} drog på
                8 poäng!
            </div>
        );
    }

    if (accountContext.userData.isLoggedIn) {
        if (
            allPlayerPointsCity.length === 0 ||
            getTotalNumberOfPlayers(allPlayerPointsCity) < 2
        ) {
            return (
                <div className={"statistics-city-answers__wrapper-loading"}>
                    <div className="statistics-city-answers__title">
                        Hämtar resultat från alla som låst in svar
                    </div>
                    <div className="statistics-city-answers__image-wrapper">
                        <LoaderImg className={hideToMatchImageSnapshotClass} />
                    </div>
                </div>
            );
        }
        return (
            <div className="statistics-city-answers__wrapper-logged-in">
                <div className="statistics-city-answers__title">
                    Såhär många poäng fick Sverige
                </div>
                {hasAnswered && userPoints > 0 && (
                    <div className="statistics-city-answers__performance-status-wrapper">
                        {getPerformanceStatus()}

                        <div className="statistics-city-answers__performance-status-percentage">
                            Du var bättre än{" "}
                            {calculatePointsPercentage(
                                allPlayerPointsCity,
                                userPoints / 2,
                                "worseResult",
                            )}
                            % av spelarna
                        </div>
                    </div>
                )}
                <BarChart
                    allPlayerPoints={allPlayerPointsForBarChart}
                    highestBarHeight={highestBarHeight}
                    labels={barChartlabels}
                    userData={accountContext.userData}
                    userPoints={userPoints}
                    hasAnswered={hasAnswered}
                />
                <div className="statistics-city-answers__wrong-answers">
                    {allPlayerPointsCity[0]?.votes.toLocaleString("sv-SE")}{" "}
                    svarade fel
                </div>
            </div>
        );
    }
    return (
        <div className="statistics-city-answers__wrapper-logged-out">
            <LottieAnimation
                passedClassNames={
                    "statistics-city-answers__image hideToMatchImageSnapshot"
                }
                animationData={barChartAnimation}
                loop
            />
            {getLoginPromptTitle()}
            <div className="statistics-city-answers__text">
                Se hur svår alla andra tyckte att den här resan var.
            </div>
            <button
                type="button"
                className="statistics-city-answers__login-button"
                onClick={() => login()}
            >
                Logga in
            </button>
        </div>
    );
}
