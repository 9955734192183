import { useSelector } from "react-redux";

import questionTypes from "../../helpers/questionTypes";
import calculateStarLevel from "../../helpers/calculateStarLevel";
import StarsRow from "../shared/starsRow";

export default function StarLevel() {
    const activeElement = useSelector((state) => state.activeElement);
    const countdown = useSelector((state) => state.countdown);

    if (
        activeElement &&
        questionTypes[activeElement.type] &&
        countdown.duration !== -1 &&
        countdown.now !== -1
    ) {
        const answerTime = countdown.duration - countdown.now;
        const starLevel = calculateStarLevel(answerTime, activeElement);

        return (
            <StarsRow
                starLevel={starLevel}
                emptyStarClass="-empty-star-class"
                height={16}
                width={16}
            />
        );
    }

    return null;
}
