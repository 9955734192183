import { CLEAR_EVENT, APP_STATE_SET } from "../actions";

const initialState = { view: null, data: null };

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case CLEAR_EVENT:
            return initialState;
        case APP_STATE_SET:
            return action;
        default:
            return state;
    }
}
