import React from "react";
import PropTypes from "prop-types";

import AnswerBoxMarkup from "../../../shared/answerBox";

export default class AnswerBoxDnd extends React.Component {
    render() {
        let modifier;
        let bodyText;
        let { label, answer, correctAnswer, isCorrect } = this.props;

        if (answer) {
            if (isCorrect) {
                modifier = "-correct";
                bodyText = correctAnswer;
            } else {
                modifier = "-incorrect";
                bodyText = answer;
            }
        } else {
            modifier = "-no-answer";
            bodyText = "-";
        }

        return (
            <AnswerBoxMarkup
                headText={label}
                modifier={modifier}
                hasAnswered={Boolean(answer)}
                bodyText={bodyText}
                isCorrect={isCorrect}
                correctAnswer={correctAnswer}
            />
        );
    }
}

AnswerBoxDnd.propTypes = {
    label: PropTypes.string,
    answer: PropTypes.string,
    correctAnswer: PropTypes.string,
    isCorrect: PropTypes.bool,
};
