import ThreeDots from "@svt/duo-shared-assets/assets/icons/threeDots.svg?react";

import Row from "../../../shared/toplist/row";
import { LoggedInAmigoType, ToplistUserRowType } from "../../../../types";

export default function TopListSharedOutside({
    user,
}: {
    user: ToplistUserRowType & LoggedInAmigoType;
}) {
    return (
        <>
            <div className="toplist_shared_outside__wrapper">
                <ThreeDots className="toplist_shared_outside__dot" />
            </div>
            {/* @ts-expect-error /* TODO: Fix this type for v2 */}
            <Row modifyPoints={false} rank={user.rank} user={user} />
        </>
    );
}
