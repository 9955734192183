import React from "react";
import { AccountContext } from "@svt/duo-shared-components";

const withAccountContext = (PassedComponent) => {
    const NewComponent = (props) => {
        const accountContext = React.useContext(AccountContext);

        return <PassedComponent {...props} accountContext={accountContext} />;
    };

    return NewComponent;
};

export default withAccountContext;
