import { useContext } from "react";
import { AccountContext, LottieAnimation } from "@svt/duo-shared-components";

import { AccountContextType } from "../../types";
import barChartSmallAnimation from "../../assets/lottie/bar-chart-small.json";
import globeAnimation from "../../assets/lottie/globe.json";

const LoginPrompt = ({
    headingFirstCard,
    textFirstCard,
    headingSecondCard,
    textSecondCard,
    viewName,
}: {
    headingFirstCard: string;
    textFirstCard: string;
    headingSecondCard: string;
    textSecondCard: string;
    viewName: string;
}) => {
    const accountContext: AccountContextType = useContext(AccountContext);

    function login() {
        accountContext.login(viewName);
    }

    return (
        <div className="login-prompt">
            <div className="login-prompt__card">
                <div className="login-prompt__card-text-wrapper">
                    <div className="login-prompt__card-title">
                        {headingFirstCard}
                    </div>
                    <div className="login-prompt__card-text">
                        {textFirstCard}
                    </div>
                </div>
                <LottieAnimation
                    passedClassNames={
                        "login-prompt__card-image hideToMatchImageSnapshot"
                    }
                    animationData={barChartSmallAnimation}
                    loop
                />
            </div>
            <div className="login-prompt__card">
                <div className="login-prompt__card-text-wrapper">
                    <div className="login-prompt__card-title">
                        {headingSecondCard}
                    </div>
                    <div className="login-prompt__card-text">
                        {textSecondCard}
                    </div>
                </div>
                <LottieAnimation
                    passedClassNames={
                        "login-prompt__card-image hideToMatchImageSnapshot"
                    }
                    animationData={globeAnimation}
                    loop
                />
            </div>
            <button
                type="button"
                className="login-prompt__login-button"
                onClick={() => login()}
            >
                Logga in
            </button>
        </div>
    );
};

export default LoginPrompt;
