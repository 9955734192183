/**
 * @copyright Copyright © 2021 SVT Design
 * @author Axel Boberg <axel.boberg@svt.se>
 * @typedef { Array.<Number> } LonLat
 */

import GeographicLib from "geographiclib";

/**
 * A convenience reference to the
 * geodesic methods for the WGS84 system
 * @type { any }
 */
const geod = GeographicLib.Geodesic.WGS84;

/**
 * Calculate the geodesic inverse in meters between
 * two coordinate pairs on the WGS84 ellipsoid
 *
 * @param { LonLat } lonLat1
 * @param { LonLat } lonLat2
 * @returns { Number } The distance between
 *                     the two points in meters
 *                     or -1 if any of the pairs
 *                     is missing
 *
 * @see https://geographiclib.sourceforge.io/scripts/geod-calc.html
 */
function geodesicInverse(lonLat1, lonLat2) {
    if (!lonLat1 || !lonLat2) return -1;
    const r = geod.Inverse(lonLat1[1], lonLat1[0], lonLat2[1], lonLat2[0]);

    return r.s12;
}

export default function diffInKm(lonLat1, lonLat2) {
    return Math.floor(geodesicInverse(lonLat1, lonLat2) / 1000);
}
