import { useEffect, useRef, useState, useContext } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import duoCom from "@svt/duo-communication";
import LViS from "@monterosa/lvis-api";
import {
    analytics,
    localStorageHelper,
    AccountContext,
    Rating,
} from "@svt/duo-shared-components";
import VisibilitySensor from "react-visibility-sensor";

import ProblemInfo from "../../shared/problemInfo";
import EpisodeEndRounds from "../../shared/episodeEnd/rounds";
import questionTypes from "../../../helpers/questionTypes";
import saveRoundData from "../../../helpers/saveRoundData";
import Share from "../../shared/share";
import ToplistWrapper from "../../shared/toplist/toplistwrapper";
import UserScore from "../../shared/episodeEnd/userScore";
import { SET_HIDE_SCORE } from "../../../redux/actions";
import onSeen from "../../shared/onSeen";
import useGetScore from "../../../helpers/useGetScore";
import { getUserPointsStatisticsElement } from "../../../helpers/findStatisticsElement";
import { getTotalNumberOfPlayers } from "../../../helpers/calculatePointsPercentage";
import LoginPrompt from "../../shared/loginPrompt";
import GeographyToplist from "./geographyToplist";
import StatisticsUserPointsTotal from "./statisticsUserPointsTotal/statisticsUserPointsTotal";
import HighScoreSender from "../../shared/highScoreSender";
import SendToMusikhjalpen from "./sendToMusikhjalpen";
import UnlockedAchievementsEpisode from "./unlockedAchievementsEpisode";
import UnlockedAchievementsSeason from "./unlockedAchievementsSeason";
import useEpisodeEndUnlockAchievements from "./useEpisodeEndUnlockAchievements";

const EpisodeEnd = ({ questions, dispatch, onSeenComponent, stateOnSeen }) => {
    useEpisodeEndUnlockAchievements();
    const accountContext = useContext(AccountContext);
    const score = useGetScore();
    const [allPlayerPoints, setAllPlayerPoints] = useState(null);

    useEffect(() => {
        const userPointsStatisticsElement = getUserPointsStatisticsElement();
        if (!userPointsStatisticsElement) return;

        setAllPlayerPoints(userPointsStatisticsElement.results);

        function updateScores() {
            setAllPlayerPoints(userPointsStatisticsElement.results);
        }

        userPointsStatisticsElement.on(LViS.Regular.ON_RESULTS, updateScores);
        return () => {
            userPointsStatisticsElement.off(
                LViS.Regular.ON_RESULTS,
                updateScores,
            );
        };
    }, []);

    useEffect(() => {
        dispatch({
            type: SET_HIDE_SCORE,
        });
    }, [dispatch]);

    useEffect(() => {
        const userPointsStatisticsElement = getUserPointsStatisticsElement();
        if (!userPointsStatisticsElement) return;
        userPointsStatisticsElement.vote({ [score]: 1 });
    }, [score]);

    useEffect(() => {
        if (duoCom.supports("disableActiveMode")) {
            duoCom.disableActiveMode();
        }
    }, []);

    const _getRounds = () => {
        let historyQuestions = LViS.getEvent()
            .getHistory()
            .filter((el) => questionTypes[el.getContentType()]);

        let rounds = [];

        historyQuestions.forEach((historyQuestion) => {
            let type = historyQuestion.getContentType();

            if (type === "multi_choice") {
                type = historyQuestion.getCustomFields().multi_choice_type;
            }

            if (
                type === "city" ||
                type === "multi-who-there" ||
                type === "multi-return" ||
                type === "the_list" ||
                type === "closest_wins"
            ) {
                // Set round name
                let roundName;

                switch (type) {
                    case "city": {
                        roundName =
                            historyQuestion.getCustomFields()
                                .city_correct_answers[0].correct_answer;
                        break;
                    }
                    case "multi-who-there": {
                        roundName = "Vem där";
                        break;
                    }
                    case "multi-return": {
                        roundName = "Retur";
                        break;
                    }
                    case "the_list": {
                        roundName = "Listan";
                        break;
                    }
                    case "closest_wins": {
                        roundName = "Närmast vinner";
                        break;
                    }
                    default: {
                        break;
                    }
                }

                rounds.push({
                    type: type,
                    roundName: roundName,
                    questions: [],
                });
            }

            // Get round question data
            const questionId = historyQuestion.getId();
            const questionState = questions[questionId];

            let points = 0;
            let starLevel = 0;

            if (questionState) {
                points = questions[questionId].points;
                starLevel = questions[questionId].starLevel;
            }

            // Only if there is a round to push to
            if (rounds.length > 0) {
                rounds[rounds.length - 1].questions.push({
                    type: type,
                    points: points,
                    starLevel: starLevel,
                });
            }
        });

        return rounds;
    };

    const _getStarData = (rounds) => {
        let bonus = 0;
        let noOfQuestions = 0;
        let max;

        rounds.forEach((round) => {
            round.questions.forEach((roundQuestions) => {
                bonus += roundQuestions.starLevel;
                noOfQuestions++;
            });
        });

        max = noOfQuestions * 3;

        return { bonus, max };
    };
    const roundsRef = useRef(_getRounds());
    const starDataRef = useRef(_getStarData(roundsRef.current));

    useEffect(() => {
        if (duoCom.supports("audiosyncStop")) {
            duoCom.audiosyncStop();
        }

        analytics.helpers.totalPoints(score);
        analytics.trackCustomEvent("bonus", "points", {
            points: starDataRef.current.bonus,
        });

        localStorageHelper.removeState("currentEvent");
    }, [score, starDataRef.current.bonus]);

    useEffect(() => {
        saveRoundData({
            eventNumber: LViS.getEvent().getCustomFields().episode_number,
            score: score,
            rounds: roundsRef.current,
            starData: starDataRef.current,
        });
    }, [score]);

    function onSeenUserScore(isVisible) {
        onSeenComponent(isVisible, "userScore", "poang", "episodeEnd");
    }

    function onSeenShare(isVisible) {
        onSeenComponent(isVisible, "share", "dela", "episodeEnd");
    }

    function onSeenLoginPrompt(isVisible) {
        onSeenComponent(isVisible, "loginPrompt", "episodeEnd");
    }

    function onSeenStatisticsUserPointsTotal(isVisible) {
        onSeenComponent(isVisible, "statisticsUserPointsTotal", "episodeEnd");
    }
    function onSeenGeographyToplist(isVisible) {
        onSeenComponent(isVisible, "geographyToplist", "episodeEnd");
    }

    function onSeenToplist(isVisible) {
        onSeenComponent(isVisible, "topList", "toplista", "episodeEnd");
    }

    function onUnlockedEpisodeAchievements(isVisible) {
        onSeenComponent(
            isVisible,
            "unlockedEpisodeAchievements",
            "prestation episode",
            "episodeEnd",
        );
    }

    function onUnlockedSeasonAchievements(isVisible) {
        onSeenComponent(
            isVisible,
            "seasonAchievements",
            "prestation säsong",
            "episodeEnd",
        );
    }

    function onSeenEndRounds(isVisible) {
        onSeenComponent(
            isVisible,
            "endRounds",
            "detaljerad-resultat",
            "episodeEnd",
        );
    }

    function onSeenRating(isVisible) {
        onSeenComponent(isVisible, "rating", "betyg", "episodeEnd");
    }

    return (
        <>
            <HighScoreSender />
            <div className="svt_episode-end">
                <h1 className="svt_episode-end__header">
                    Ditt resultat för avsnittet
                </h1>

                <VisibilitySensor
                    delayedCall
                    onChange={(isVisible) => {
                        onSeenUserScore(isVisible);
                    }}
                    active={!stateOnSeen.userScore}
                >
                    <UserScore
                        userScore={score}
                        userStars={starDataRef.current.bonus}
                    />
                </VisibilitySensor>

                <SendToMusikhjalpen />

                <VisibilitySensor
                    delayedCall
                    onChange={(isVisible) => {
                        onSeenShare(isVisible);
                    }}
                    active={!stateOnSeen.share}
                >
                    <Share score={score} stars={starDataRef.current.bonus} />
                </VisibilitySensor>

                {!accountContext.userData.isLoggedIn && (
                    <VisibilitySensor
                        delayedCall
                        onChange={(isVisible) => {
                            onSeenLoginPrompt(isVisible);
                        }}
                        active={!stateOnSeen.loginPrompt}
                    >
                        <LoginPrompt
                            headingFirstCard={"Jämför dina poäng"}
                            textFirstCard={`Hittills har ${getTotalNumberOfPlayers(
                                allPlayerPoints,
                            )} spelat. Hur många poäng fick de?`}
                            headingSecondCard={"Är du bäst i kommunen?"}
                            textSecondCard={
                                "Se hur bra dina grannar klarade det här avsnittet."
                            }
                            viewName={"slutvy"}
                        />
                    </VisibilitySensor>
                )}

                {accountContext.userData.isLoggedIn && (
                    <VisibilitySensor
                        delayedCall
                        onChange={(isVisible) => {
                            onSeenStatisticsUserPointsTotal(isVisible);
                        }}
                        active={!stateOnSeen.statisticsUserPointsTotal}
                    >
                        <StatisticsUserPointsTotal
                            userPoints={score}
                            allPlayerPoints={allPlayerPoints}
                        />
                    </VisibilitySensor>
                )}

                {accountContext.userData.isLoggedIn && (
                    <VisibilitySensor
                        delayedCall
                        onChange={(isVisible) => {
                            onSeenGeographyToplist(isVisible);
                        }}
                        active={!stateOnSeen.geographyToplist}
                    >
                        <GeographyToplist />
                    </VisibilitySensor>
                )}

                {accountContext.userData.isLoggedIn && (
                    <VisibilitySensor
                        delayedCall
                        onChange={(isVisible) => {
                            onUnlockedEpisodeAchievements(isVisible);
                        }}
                        active={!stateOnSeen.unlockedEpisodeAchievements}
                    >
                        <UnlockedAchievementsEpisode />
                    </VisibilitySensor>
                )}

                {accountContext.userData.isLoggedIn && (
                    <VisibilitySensor
                        delayedCall
                        onChange={(isVisible) => {
                            onUnlockedSeasonAchievements(isVisible);
                        }}
                        active={!stateOnSeen.seasonAchievements}
                    >
                        <UnlockedAchievementsSeason
                            isVisible={stateOnSeen.seasonAchievements}
                        />
                    </VisibilitySensor>
                )}

                {accountContext.userData.isLoggedIn && (
                    <VisibilitySensor
                        delayedCall
                        onChange={(isVisible) => {
                            onSeenToplist(isVisible);
                        }}
                        active={!stateOnSeen.topList}
                    >
                        {import.meta.env.VITE_PLAY_WITH_FRIENDS === "true" && (
                            <ToplistWrapper
                                listName="Poängställning vänner"
                                shouldShowTotalStarLevel
                            />
                        )}
                    </VisibilitySensor>
                )}

                <ProblemInfo />

                <VisibilitySensor
                    delayedCall
                    onChange={(isVisible) => {
                        onSeenEndRounds(isVisible);
                    }}
                    active={!stateOnSeen.endRounds}
                >
                    <EpisodeEndRounds
                        rounds={roundsRef.current}
                        starData={starDataRef.current}
                        score={score}
                    />
                </VisibilitySensor>

                <VisibilitySensor
                    delayedCall
                    onChange={(isVisible) => {
                        onSeenRating(isVisible);
                    }}
                    active={!stateOnSeen.rating}
                >
                    <Rating
                        styles={{ wrapper: "svt_episode-end__rating-wrapper" }}
                        eventUUID={LViS.getEvent().getUUID()}
                    />
                </VisibilitySensor>
            </div>
        </>
    );
};

EpisodeEnd.propTypes = {
    questions: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    onSeenComponent: PropTypes.func.isRequired,
    stateOnSeen: PropTypes.object.isRequired,
};

export default connect((state) => ({
    questions: state.questions,
}))(onSeen(EpisodeEnd));
