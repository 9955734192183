import IllustrationAchievements from "../../../../assets/icons/illustrationAchievements.svg?react";

export default function AchievementsSlide() {
    return (
        <>
            <IllustrationAchievements />
            <h1 className="onBoarding__title">Nya prestationer varje vecka</h1>
            <p>
                Utmana dig själv och samla prestationer både över säsongen och i
                varje avsnitt. Bygg upp din trofésamling och ja, skryt gärna
                lite för dina vänner!
            </p>
        </>
    );
}
