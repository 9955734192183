import "./polyfills";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import duoCom from "@svt/duo-communication";
import LViS from "@monterosa/lvis-api";
import * as Sentry from "@sentry/react";
import elementsFromPointPolyfill from "polyfill-elements-from-point";
import {
    ToplistProvider,
    toplistClear,
    FriendsProvider,
    AccountProvider,
    analytics,
    getQueryParam,
    getIsFakedLoggedIn,
    overrideErrorResponse,
    localStorageHelper,
    insertStyle,
} from "@svt/duo-shared-components";
import sharedComposeImportStyle from "@svt/duo-shared-components/css/sharedComposeImport.module.css?inline";
import "@svt/duo-shared-components/css/sharedComposeImport.module.css";
import "keen-slider/keen-slider.css";

localStorageHelper.setPrefix("paSparet");

import configureStore from "./redux/configureStore";
import App from "./components/app";
import questionTypes from "./helpers/questionTypes";
import pointsSender from "./helpers/pointsSender";
import reportAccessibility from "./helpers/reportAccessibility";
insertStyle(sharedComposeImportStyle);

import {
    ACTIVE_ELEMENT_SET,
    QUESTIONS_NEW,
    APP_STATE_SET,
    SET_SHOW_SCORE,
    AUDIO_SYNC_SET,
    CLEAR_EVENT,
    TIMERS_STOP,
    ACTIVE_ELEMENT_CLEAR,
    STORED_STATE,
} from "./redux/actions";

import "./sass/main.scss";
import ErrorBoundary from "./components/errorBoundary";
import {
    unlockBingespelaren,
    unlockFredagstittaren,
} from "./components/useUnlockAchievement";

elementsFromPointPolyfill(window);
const store = configureStore({});

let lvisEvent;
let lvisEventDuration;
let currentDelay;
let paused;
let reSyncing;

function initialValues() {
    lvisEvent = null;
    currentDelay = null;
    paused = false;
    reSyncing = false;
}

function startApp() {
    if (import.meta.env.VITE_SENTRY_ENABLED === "true") {
        Sentry.init({
            dsn: "https://2f07e367f58e44868f04e75d6517fe20@sentry.io/1323507",
            release: `pa-sparet@${__COMMIT_HASH__}`,
            environment: import.meta.env.VITE_ENVIRONMENT,
            normalizeDepth: 5,
        });

        if (duoCom.settings) {
            const deviceIdentifier = duoCom.settings().deviceIdentifier;

            if (deviceIdentifier) {
                Sentry.setUser({
                    id: deviceIdentifier,
                });
            }
        }
    }

    initialValues();
    pointsSender.init(store);

    reportAccessibility(React);

    if (getQueryParam("debug") === "true") {
        const systemDown = getQueryParam("systemDown") === "true";
        const mergeInitialState = systemDown ? { error: true } : {};
        overrideErrorResponse(systemDown);

        ReactDOM.createRoot(document.getElementById("svt_app")).render(
            <React.StrictMode>
                <Provider store={store}>
                    <ErrorBoundary>
                        <AccountProvider
                            mergeInitialState={mergeInitialState}
                            isFakedLoggedIn={getIsFakedLoggedIn()}
                            demographicsToGet={["postal_code, gender"]}
                            shouldValidatePostalCode={true}
                        >
                            <FriendsProvider>
                                <ToplistProvider>
                                    <App />
                                </ToplistProvider>
                            </FriendsProvider>
                        </AccountProvider>
                    </ErrorBoundary>
                </Provider>
            </React.StrictMode>,
        );
    } else {
        ReactDOM.createRoot(document.getElementById("svt_app")).render(
            <React.StrictMode>
                <Provider store={store}>
                    <ErrorBoundary>
                        <AccountProvider
                            demographicsToGet={["postal_code, gender"]}
                            shouldValidatePostalCode={true}
                        >
                            <FriendsProvider>
                                <ToplistProvider>
                                    <App />
                                </ToplistProvider>
                            </FriendsProvider>
                        </AccountProvider>
                    </ErrorBoundary>
                </Provider>
            </React.StrictMode>,
        );
    }

    if (duoCom.supports("viewReady")) {
        duoCom.viewReady();
    }
}

function positionBackground() {
    if (lvisEvent) {
        lvisEvent.unbind(LViS.Event.ON_ELEMENT_PUBLISH, handleElementCreate);
    }
}

function positionForeground() {
    if (lvisEvent) {
        lvisEvent.bind(LViS.Event.ON_ELEMENT_PUBLISH, handleElementCreate);
    }
}

function audioSyncStart() {
    store.dispatch([{ type: AUDIO_SYNC_SET, data: "listening" }]);
}

function audioSyncStop() {
    store.dispatch([{ type: AUDIO_SYNC_SET, data: "stopped" }]);
}

function audioSyncPause() {
    paused = true;

    store.dispatch([
        { type: APP_STATE_SET, view: "paused" },
        { type: AUDIO_SYNC_SET, data: "paused" },
        { type: ACTIVE_ELEMENT_CLEAR },
    ]);
}

function audioSyncReSync() {
    reSyncing = true;

    store.dispatch([{ type: AUDIO_SYNC_SET, data: "reSyncing" }]);
}

function audioSyncReSyncComplete() {
    reSyncing = false;
}

function audioSyncResume() {
    reSyncing = false;
    store.dispatch([{ type: AUDIO_SYNC_SET, data: "listening" }]);
}

function startAppWithDuo() {
    duoCom.events.addEventListener("audiosync:timestamp", setEventTimeJump);
    duoCom.events.addEventListener("audiosync:episode", setEvent);
    duoCom.events.addEventListener("audiosync:start", audioSyncStart);
    duoCom.events.addEventListener("audiosync:stop", audioSyncStop);
    duoCom.events.addEventListener("audiosync:resync", audioSyncReSync);
    duoCom.events.addEventListener(
        "audiosync:resync-complete",
        audioSyncReSyncComplete,
    );
    duoCom.events.addEventListener("audiosync:pause", audioSyncPause);
    duoCom.events.addEventListener("audiosync:resume", audioSyncResume);
    duoCom.events.addEventListener("duo:background", positionBackground);
    duoCom.events.addEventListener("duo:foreground", positionForeground);
}

function getEvent({ id, timestamp }) {
    let events = LViS.Listings.getList();
    let foundEvent = null;

    for (var i = 0; i < events.length && !foundEvent; i++) {
        if (events[i].getState() !== LViS.Event.STATE_UPCOMING) {
            let data = events[i].getCustomFields();

            if (data.vision_id === id) {
                foundEvent = events[i];
            }
        }
    }

    if (foundEvent === null) {
        analytics.trackCustomEvent("episode not found", "shazamkit", {
            id: id,
            timeStamp: timestamp,
        });
    }

    return foundEvent;
}

function setSync(eventData) {
    if (lvisEvent) {
        store.dispatch([
            { type: APP_STATE_SET, view: "syncing" },
            { type: ACTIVE_ELEMENT_CLEAR },
        ]);

        const preTickTime = new Date().getTime();

        LViS.Run.next(() => {
            const postTickTime = new Date().getTime();
            const tickDifference = postTickTime - preTickTime;
            const currentTime = LViS.Date.now();
            const eventStartTime = lvisEvent.getOriginalStartAt();

            let milliIntoEvent = eventData.detail.timestamp + tickDifference;
            if (milliIntoEvent >= lvisEventDuration) {
                milliIntoEvent = lvisEventDuration - 1000; // Don't sync further than the event duration
            }

            const secondsIntoEvent = Math.floor(milliIntoEvent / 1000);
            const timeDiff = currentTime - eventStartTime - secondsIntoEvent;

            if (currentDelay === timeDiff) {
                getCurrentElement();
            } else {
                currentDelay = timeDiff;

                LViS.Sync.setDelay(timeDiff); // Set the sync x seconds behind. Will trigger getCurrentElement
            }

            LViS.setEvent(lvisEvent);
            unlockBingespelaren();
            unlockFredagstittaren();
        });
    }
}

function statePlaying() {
    if (store.getState().appState.view !== "playing") {
        store.dispatch([
            {
                type: APP_STATE_SET,
                view: "playing",
            },
            {
                type: SET_SHOW_SCORE,
            },
        ]);
    }
}

export const elementsNotToInclude = [
    "user_points_statistics_total",
    "user_points_statistics_city_1",
    "user_points_statistics_city_2",
    "user_points_statistics_city_3",
];

function getCurrentElement() {
    if (lvisEvent) {
        paused = false;

        let foundEl = false;
        let historyElements = lvisEvent.getHistory();

        for (let i = 0; i < historyElements.length; i++) {
            if (
                !elementsNotToInclude.includes(
                    historyElements[i].getContentType(),
                )
            ) {
                if (historyElements[i].getDurationLeft() !== 0) {
                    foundEl = true;
                    handleElementCreate(historyElements[i]);
                    break;
                }
            }
        }

        if (!foundEl) {
            statePlaying();
        }

        if (!reSyncing) {
            store.dispatch([{ type: AUDIO_SYNC_SET, data: "idling" }]);
        }
    }
}

function getElementData(element) {
    let elementData;

    elementData = element.getCustomFields();
    elementData.type = element.getContentType();
    elementData.id = element.getId();
    elementData.duration = element.getDuration();
    elementData.durationLeft = element.getDurationLeft();

    return elementData;
}

function handleElementCreate(element) {
    if (!paused && lvisEvent) {
        let type = element.getContentType();
        let elData;

        if (questionTypes[type]) {
            elData = getElementData(element);

            store.dispatch({
                type: QUESTIONS_NEW,
                id: elData.id,
                questionType: elData.type,
            });

            if (duoCom.supports("vibrate")) {
                duoCom.vibrate();
            }
        } else {
            elData = {
                ...element.getCustomFields(),
                type: type,
                id: element.getId(),
            };
        }
        store.dispatch({
            type: ACTIVE_ELEMENT_SET,
            data: elData,
        });

        statePlaying();
    }
}

export function clearEvent(stopSync) {
    if (lvisEvent) {
        analytics.setEpisode(null);

        lvisEvent.unbind(LViS.Event.ON_READY, getCurrentElement);
        lvisEvent.unbind(LViS.Event.ON_ELEMENT_PUBLISH, handleElementCreate);
        LViS.unsetEvent();
    }

    store.dispatch({
        type: CLEAR_EVENT,
    });

    initialValues();

    if (stopSync) {
        if (duoCom.supports("audiosyncStop")) {
            duoCom.audiosyncStop();
        }

        if (duoCom.supports("disableActiveMode")) {
            duoCom.disableActiveMode();
        }
    }

    toplistClear();
}

export function setEvent(eventData) {
    if (lvisEvent) {
        clearEvent(false);
    }

    lvisEvent = getEvent({
        id: eventData.detail.id,
        timestamp: eventData.detail.timestamp,
    });

    if (lvisEvent) {
        lvisEventDuration =
            (lvisEvent.getEndAt() - lvisEvent.getStartAt()) * 1000;
        const storedCurrentEvent = localStorageHelper.getState("currentEvent");
        const currentEventId = lvisEvent.getUUID();
        const currentDateTime = new Date().getTime();
        let withIn = import.meta.env.DEV ? 0 : 60 * 60 * 1000; // 60 minutes, 0 in dev mode

        const eventCustomFields = lvisEvent.getCustomFields();

        if (
            storedCurrentEvent &&
            currentEventId === storedCurrentEvent.eventId &&
            currentDateTime - storedCurrentEvent.dateTime < withIn
        ) {
            store.dispatch({
                type: STORED_STATE,
                data: storedCurrentEvent.eventData,
            });
        }

        if (duoCom.supports("enableActiveMode")) {
            duoCom.enableActiveMode();
        }

        paused = false;

        analytics.setEpisode(eventCustomFields.vision_id);
        analytics.helpers.startGame();

        lvisEvent.bind(LViS.Event.ON_READY, getCurrentElement);
        lvisEvent.bind(LViS.Event.ON_ELEMENT_PUBLISH, handleElementCreate);

        setSync(eventData, true);
    }
}

export function setEventTimeJump(eventData) {
    if (lvisEvent) {
        store.dispatch({
            type: TIMERS_STOP,
        });
        setSync(eventData, false);
    }
}

function handleLViSReady() {
    startApp();

    let visionID = getQueryParam("visionId");
    if (visionID) {
        let timestamp = parseInt(getQueryParam("timestamp"), 10) || 0;
        setEvent({ detail: { id: visionID, timestamp: timestamp } });
    }

    if (duoCom.ready) {
        startAppWithDuo();
    } else {
        duoCom.events.addEventListener("duo:ready", () => {
            startAppWithDuo();
        });
    }
}

LViS.bind(LViS.ON_READY, handleLViSReady);
LViS.init({
    project: getQueryParam("lvisProject") ?? import.meta.env.VITE_LVIS_PROJECT,
    host: "cdn.monterosa.cloud",
});
