import PropTypes from "prop-types";

export const toplistType = PropTypes.arrayOf(
    PropTypes.shape({
        isTeam: PropTypes.bool,
        avatar: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        points: PropTypes.number,
        profile: PropTypes.shape({
            color: PropTypes.string,
            userId: PropTypes.string,
            username: PropTypes.string,
        }),
    }),
);

export const toplistResponseSingleObjectType = PropTypes.shape({
    friends: toplistType,
    toplist: toplistType,
    user: PropTypes.shape({
        points: PropTypes.oneOfType([
            PropTypes.string, //for logged out when sending '?' as points
            PropTypes.number,
        ]),
        rank: PropTypes.number,
    }),
    type: PropTypes.string,
});

export const toplistResponseType = PropTypes.objectOf(
    toplistResponseSingleObjectType,
);

export const allEpisodeAirTimesType = PropTypes.objectOf(
    PropTypes.shape({
        airTime: PropTypes.number.isRequired,
        hasAired: PropTypes.bool.isRequired,
    }),
);

export const appStateObjectType = PropTypes.shape({
    view: PropTypes.string,
    data: PropTypes.object,
});
