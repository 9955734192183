import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
    QUESTIONS_STOP_ANSWERING,
    QUESTIONS_SET_ANSWER,
    MULTI_CHOICE_TOGGLE_OPTION,
} from "../../../redux/actions";
import MultiChoiceQuestionItem from "./multiChoiceQuestionItem";
import BackArrowImage from "../../../assets/icons/backArrow.svg?react";
import calculateStarLevel from "../../../helpers/calculateStarLevel";
import { getStore } from "../../../redux/configureStore";

const getSelectedAnswers = (state) => {
    let answers = [];

    for (let i = 0; i < state.questions.length; i++) {
        const allSelections = state.questions[i].options
            .filter((option) => option.isSelected)
            .map((value) => value.option);

        answers.push(allSelections.length > 0 ? allSelections : null);
    }

    return answers;
};

const getSelectedAnswersDataByIndex = (state, index) =>
    state.questions[index].options.filter((option) => option.isSelected);

const getSelectedAnswerIndexByIndex = (state, index) =>
    state.questions[index].options.findIndex((option) => option.isSelected);

const canSelectByIndex = (state, index) =>
    state.questions[index].amountToSelect >
    state.questions[index].amountSelected;

const isMultiAnswersByIndex = (state, index) =>
    state.questions[index].amountToSelect > 1;

const amountSelectedByIndex = (state, index) =>
    state.questions[index].amountSelected;

const amountToSelectByIndex = (state, index) =>
    state.questions[index].amountToSelect;

class MultiChoiceQuestion extends React.Component {
    constructor() {
        super();

        this._selectAnswer = this._selectAnswer.bind(this);
        this._stopAnswering = this._stopAnswering.bind(this);
        this._clear = this._clear.bind(this);
    }

    componentDidUpdate() {
        this._setAnswers();
    }

    _setAnswers() {
        const selectedAnswers = getSelectedAnswers(this.props.multiChoice);

        const amountSelected = amountSelectedByIndex(
            this.props.multiChoice,
            this.props.index,
        );

        const amountToSelect = amountToSelectByIndex(
            this.props.multiChoice,
            this.props.index,
        );

        const correction = this._getCorrection();
        const storeState = getStore().getState();
        const answerTime =
            storeState.countdown.duration - storeState.countdown.now;

        this.props.dispatch({
            type: QUESTIONS_SET_ANSWER,
            id: this.props.element.id,
            answer: selectedAnswers,
            isCorrect: correction.isCorrect,
            allCorrect: correction.allCorrect,
            points: correction.points,
            answerTime: answerTime * 1000,
            starLevel: correction.allCorrect
                ? calculateStarLevel(answerTime, this.props.element)
                : 0,
        });

        if (amountSelected === amountToSelect) {
            this._stopAnswering();
        }
    }

    _getCorrection() {
        let isCorrect = false;
        let allCorrect = true;
        let points = 0;

        if (
            this.props.element.multi_choice_type === "multi-who-there" ||
            this.props.element.multi_choice_type === "multi-return"
        ) {
            const question = this.props.multiChoice.questions[0];

            if (question.amountSelected === 0) {
                isCorrect = false;
                allCorrect = false;
                points = 0;
            } else {
                for (let option of question.options) {
                    if (option.isCorrect && option.isSelected) {
                        isCorrect = true;
                        allCorrect = true;
                        points = 3;
                    }

                    if (option.isCorrect && !option.isSelected) {
                        allCorrect = false;
                        points = -3;
                    }
                }
            }
        } else {
            let noOfPossibleCorrect = 0;

            for (let question of this.props.multiChoice.questions) {
                for (let option of question.options) {
                    if (option.isCorrect && option.isSelected) {
                        isCorrect = true;
                        points++;
                    }

                    if (option.isCorrect && !option.isSelected) {
                        allCorrect = false;
                    }

                    if (option.isCorrect) {
                        noOfPossibleCorrect++;
                    }
                }
            }

            // Bonus: the total amount of correct answers that is selectable is 2 and the user has all of them selected
            if (noOfPossibleCorrect === 2 && allCorrect) {
                points++;
            }
        }

        return {
            isCorrect,
            allCorrect,
            points,
        };
    }

    _handleCheckBoxButton(questionIndex, optionIndex, direction) {
        this.props.dispatch({
            type: MULTI_CHOICE_TOGGLE_OPTION,
            questionIndex: questionIndex,
            optionIndex: optionIndex,
            direction: direction,
        });
    }

    _handleRadioButton(questionIndex, optionIndex, direction) {
        const selectedAnswerIndex = getSelectedAnswerIndexByIndex(
            this.props.multiChoice,
            this.props.index,
        );

        if (selectedAnswerIndex !== optionIndex) {
            // Selected radio button is new selection
            const selectedAnswersData = getSelectedAnswersDataByIndex(
                this.props.multiChoice,
                this.props.index,
            );

            // Un select old button
            if (direction === 1 && selectedAnswersData.length !== 0) {
                this.props.dispatch({
                    type: MULTI_CHOICE_TOGGLE_OPTION,
                    questionIndex: questionIndex,
                    optionIndex: selectedAnswerIndex,
                    direction: -direction,
                });
            }

            // Select new button
            this.props.dispatch({
                type: MULTI_CHOICE_TOGGLE_OPTION,
                questionIndex: questionIndex,
                optionIndex: optionIndex,
                direction: direction,
            });
        }
    }

    _selectAnswer(optionIndex, selected) {
        const direction = selected ? 1 : -1;

        const amountToSelect = amountToSelectByIndex(
            this.props.multiChoice,
            this.props.index,
        );

        if (amountToSelect === 1) {
            // Is radio button
            this._handleRadioButton(this.props.index, optionIndex, direction);
        } else {
            // Check box
            this._handleCheckBoxButton(
                this.props.index,
                optionIndex,
                direction,
            );
        }
    }

    _stopAnswering() {
        this.props.dispatch({
            type: QUESTIONS_STOP_ANSWERING,
            id: this.props.element.id,
        });
    }

    _clear() {
        let selectedIndex = null;

        this.props.multiChoice.questions[this.props.index].options.forEach(
            (option, index) => {
                if (option.isSelected) {
                    selectedIndex = index;
                }
            },
        );

        if (typeof selectedIndex === "number") {
            this.props.dispatch({
                type: MULTI_CHOICE_TOGGLE_OPTION,
                questionIndex: this.props.index,
                optionIndex: selectedIndex,
                direction: -1,
            });
        }
    }

    render() {
        const canSelect = canSelectByIndex(
            this.props.multiChoice,
            this.props.index,
        );

        const isMultiAnswers = isMultiAnswersByIndex(
            this.props.multiChoice,
            this.props.index,
        );

        const amountSelected = amountSelectedByIndex(
            this.props.multiChoice,
            this.props.index,
        );

        const amountToSelect = amountToSelectByIndex(
            this.props.multiChoice,
            this.props.index,
        );

        let amoutToSelectText = null;

        if (isMultiAnswers) {
            amoutToSelectText =
                amountSelected === 0 ? (
                    <div>Markera de {amountToSelect} rätta svaren</div>
                ) : (
                    <div>
                        Du har markerat {amountSelected} av {amountToSelect}
                    </div>
                );
        }

        return (
            <div className="svt_mc-question">
                <div className="svt_mc-question__header-block">
                    <h1 className="svt_mc-question__header">
                        {this.props.question.question}
                    </h1>

                    {this.props.question.image ? (
                        <img
                            className="svt_mc-question__image"
                            src={this.props.question.image}
                            alt=""
                        />
                    ) : null}

                    {amoutToSelectText}

                    {this.props.moreThanOneQuestion && (
                        <div>
                            <button
                                type="button"
                                className="svt_mc-question__back"
                                onClick={this._stopAnswering}
                            >
                                <BackArrowImage />
                                <span className="svt_mc-question__back-text">
                                    Tillbaka
                                </span>
                            </button>
                        </div>
                    )}

                    {(this.props.element.multi_choice_type ===
                        "multi-who-there" ||
                        this.props.element.multi_choice_type ===
                            "multi-return") && (
                        <button
                            type="button"
                            className="svt_mc-question__clear-button"
                            disabled={amountSelected === 0}
                            onClick={this._clear}
                        >
                            Rensa svar
                        </button>
                    )}
                </div>

                <ul className="svt_mc-question__list">
                    {this.props.question.options.map((option, i) => {
                        return (
                            <MultiChoiceQuestionItem
                                key={i}
                                index={i}
                                clickHandler={this._selectAnswer}
                                option={option}
                                disabled={
                                    !canSelect &&
                                    !this.props.multiChoice.questions[
                                        this.props.index
                                    ].options[i].isSelected
                                }
                                isSelected={
                                    this.props.multiChoice.questions[
                                        this.props.index
                                    ].options[i].isSelected
                                }
                                multiAnswers={isMultiAnswers}
                                stopHandler={this._stopAnswering}
                            />
                        );
                    })}
                </ul>

                {(this.props.element.multi_choice_type === "multi-who-there" ||
                    this.props.element.multi_choice_type ===
                        "multi-return") && (
                    <button
                        type="button"
                        className="svt_mc-question__clear-button"
                        disabled={amountSelected === 0}
                        onClick={this._clear}
                    >
                        Rensa svar
                    </button>
                )}
            </div>
        );
    }
}

MultiChoiceQuestion.propTypes = {
    question: PropTypes.object.isRequired,
    element: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
    multiChoice: PropTypes.object.isRequired,
    moreThanOneQuestion: PropTypes.bool.isRequired,
};

export default connect((state) => ({
    multiChoice: state.multiChoice,
}))(MultiChoiceQuestion);
