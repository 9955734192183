import React, { useState } from "react";

import Star from "../../shared/star";

export default function ExplainStars() {
    const [showExplanation, setShowExplanation] = useState(false);

    function closeExplain() {
        setShowExplanation((currentState) => !currentState);
    }

    function getExplanation() {
        return (
            <div className="explain_stars__wrapper">
                <div className="explain_stars__heading">
                    Vad betyder stjärnorna?
                </div>
                <div className="explain_stars__text_stars_wrapper">
                    <div className="explain_stars__inner_wrapper">
                        <div className="explain_stars__star">
                            <Star modifier="-full-moon" />{" "}
                            <Star modifier="-full-moon" />{" "}
                            <Star modifier="-full-moon" />
                        </div>
                        <div className="explain_stars__star">
                            <Star modifier="-full-moon" />{" "}
                            <Star modifier="-full-moon" />{" "}
                            <Star modifier="-haiti-inner-fill" />
                        </div>
                        <div className="explain_stars__star">
                            <Star modifier="-full-moon" />{" "}
                            <Star modifier="-haiti-inner-fill" />{" "}
                            <Star modifier="-haiti-inner-fill" />
                        </div>
                        <div className="explain_stars__star">
                            <Star modifier="-haiti-inner-fill" />{" "}
                            <Star modifier="-haiti-inner-fill" />{" "}
                            <Star modifier="-haiti-inner-fill" />
                        </div>
                    </div>
                    <div className="explain_stars__text_wrapper">
                        <div className="explain_stars__text">
                            = Svarat supersnabbt
                        </div>
                        <div className="explain_stars__text">
                            = Svarat före första buren
                        </div>
                        <div className="explain_stars__text">
                            = Svarat före andra buren
                        </div>
                        <div className="explain_stars__text">
                            = Svarat sent eller svarat fel
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="explain_stars__wrapper">
            {showExplanation ? (
                getExplanation()
            ) : (
                <button
                    type="button"
                    onClick={closeExplain}
                    className="explain_stars__close"
                >
                    Vad betyder stjärnorna?
                </button>
            )}
        </div>
    );
}
