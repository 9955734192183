import { createStore, compose, applyMiddleware } from "redux";
import { reduxBatch } from "@manaflair/redux-batch";
import createSagaMiddleware from "redux-saga";
import * as Sentry from "@sentry/react";

import rootSaga from "./saga";
import reducer from "./reducer";

let store;

export function getStore() {
    return store;
}

export default function configureStore(initialState) {
    let middleWares = [];
    let composeEnhancers;

    const sagaMiddleware = createSagaMiddleware();
    middleWares.push(sagaMiddleware);

    if (import.meta.env.DEV && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
        composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    } else {
        composeEnhancers = compose;
    }

    store = createStore(
        reducer,
        initialState,
        composeEnhancers(
            reduxBatch,
            applyMiddleware(...middleWares),
            reduxBatch,
            Sentry.createReduxEnhancer({}),
        ),
    );

    sagaMiddleware.run(rootSaga);

    return store;
}
