import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import duoCom from "@svt/duo-communication";

import { FooterTeleport } from "../../app";
import CityHandbreak from "./cityHandbreak";
import CityAutocomplete from "./cityAutoComplete";
import CityWaiting from "./cityWaiting";
import {
    CITY_INITIAL,
    QUESTIONS_SET_CORRECT_ANSWER,
    QUESTIONS_BEGIN_ANSWERING,
} from "../../../redux/actions";
import pointsSender from "../../../helpers/pointsSender";
import CityLevelCounter from "./cityLevelCounter";
import CityLevel from "../../shared/cityLevel";
import { getStore } from "../../../redux/configureStore";
import { getQuestionIndex } from "../../../helpers/questionHelpers";

const City = ({ element, dispatch, questionState }) => {
    const [stateStartTime] = useState(new Date().getTime());

    const _setCorrectAnswer = useCallback(() => {
        const cityData = getStore().getState().city;

        if (cityData.id !== element.id) {
            dispatch({
                type: CITY_INITIAL,
                id: element.id,
            });

            let correctAnswers = [];
            for (let i = 0; i < element.city_correct_answers.length; i++) {
                correctAnswers.push(
                    element.city_correct_answers[i].correct_answer,
                );
            }

            if (questionState.correctAnswer === null) {
                dispatch({
                    type: QUESTIONS_SET_CORRECT_ANSWER,
                    id: element.id,
                    correctAnswer: correctAnswers,
                });
            }
        }
    }, [
        dispatch,
        element.city_correct_answers,
        element.id,
        questionState.correctAnswer,
    ]);

    useEffect(() => {
        _setCorrectAnswer();
    }, [_setCorrectAnswer]);

    useEffect(() => {
        return () => {
            pointsSender.sendOne(element.id, getQuestionIndex());
        };
    }, [element.id]);

    function _beginAnswer() {
        dispatch({
            type: QUESTIONS_BEGIN_ANSWERING,
            id: element.id,
            data: true,
        });

        if (duoCom.supports("playSound")) {
            duoCom.playSound({
                id: "tuta_klass_1",
            });
        }
    }

    let content;
    let cityLevelCounterComponent = null;
    let cityLevelModifier = "answering";

    if (questionState.hasLockedAnswer) {
        content = (
            <CityWaiting questionState={questionState} dispatch={dispatch} />
        );

        cityLevelModifier = "waiting";
    } else if (questionState.beginAnswer) {
        content = (
            <CityAutocomplete
                element={element}
                questionState={questionState}
                stateStartTime={stateStartTime}
            />
        );

        cityLevelCounterComponent = <CityLevelCounter element={element} />;
    } else {
        content = (
            <CityHandbreak
                clickHandler={() => {
                    _beginAnswer();
                }}
            />
        );
        cityLevelCounterComponent = <CityLevelCounter element={element} />;
    }

    return (
        <div className="svt_city">
            {content}
            {cityLevelCounterComponent}

            <FooterTeleport.Source>
                <CityLevel modifier={cityLevelModifier} />
            </FooterTeleport.Source>
        </div>
    );
};

City.propTypes = {
    element: PropTypes.object.isRequired,
    questionState: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
};

export default connect((state, ownProps) => ({
    questionState: state.questions[ownProps.element.id],
}))(City);
