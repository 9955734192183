import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import duoCom from "@svt/duo-communication";
import {
    AccountContext,
    analytics,
    ContentStatus,
} from "@svt/duo-shared-components";
import { AccountContextType } from "@svt/duo-shared-components/dist/types/types";
import Arrow from "@svt/duo-shared-assets/assets/icons/arrow.svg?react";

import HistoryIndex from "./history";
import { FooterTeleport } from "../../app";
import TabBar from "./tabBar/tabBar";
import AchievementsIndex from "./achievements/index";
import Season from "./season/season";
import LoginPrompt from "../../shared/loginPrompt";

export type ViewType = { view: "start" | "history" | "achievements" };

const StartScreen = ({
    setShowOnboarding,
}: {
    setShowOnboarding: (value: boolean) => void;
}) => {
    const tabBar = useSelector((state: { tabBar: ViewType }) => state.tabBar);

    const accountContext = useContext<AccountContextType>(AccountContext);

    useEffect(() => {
        analytics.trackViewEvent("index");
    }, []);

    function _startGame() {
        if (duoCom.supports("audiosyncStart")) {
            duoCom.audiosyncStart();
            analytics.trackClickEvent("audiosync:start");
        }
    }

    return (
        <div className="svt_startscreen">
            <div className="svt_startscreen__scroller">
                {tabBar.view === "start" && (
                    <div className="svt_startscreen__content">
                        <div className="svt_startscreen__buttons-wrapper">
                            <h1 className="svt_startscreen__title">
                                Välkommen till På spåret!
                            </h1>
                            <p className="svt_startscreen__text">
                                Dra igång programmet på din TV eller på SVT
                                Play. Duo kommer lyssna efter ljudet och ta dig
                                till rätt plats i spelet.
                            </p>

                            <button
                                type="button"
                                className="svt_startscreen__start-button"
                                onClick={() => {
                                    _startGame();
                                }}
                            >
                                Starta spelet
                            </button>
                        </div>

                        <button
                            className="svt_startscreen__onboarding-button"
                            onClick={() => {
                                setShowOnboarding(true);
                            }}
                        >
                            Allt om att spela med till På spåret
                            <Arrow />
                        </button>

                        <ContentStatus
                            loaded={accountContext.userData.loaded}
                            error={accountContext.userData.error}
                            errorComponent={<></>}
                        >
                            {() => {
                                return !accountContext.userData.isLoggedIn ? (
                                    <LoginPrompt
                                        headingFirstCard={
                                            "Är du bäst i kommunen?"
                                        }
                                        textFirstCard={`Se topplistor för kommun, län och hela Sverige.`}
                                        headingSecondCard={
                                            "Var du snabbast på resan?"
                                        }
                                        textSecondCard={
                                            "Se hur många poäng som övriga Sverige fick på resorna."
                                        }
                                        viewName={"startvy"}
                                    />
                                ) : (
                                    <Season />
                                );
                            }}
                        </ContentStatus>
                    </div>
                )}
                {tabBar.view === "history" && <HistoryIndex />}
                {tabBar.view === "achievements" && <AchievementsIndex />}

                <FooterTeleport.Source>
                    <TabBar />
                </FooterTeleport.Source>
            </div>
        </div>
    );
};

export default StartScreen;
