import React from "react";
import PropTypes from "prop-types";
import { DragLayer } from "react-dnd";

let scrollEl = null;
let boxesEl = null;

function collect(monitor) {
    const item = monitor.getItem();

    return {
        label: item && item.label,
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging(),
    };
}

function getItemStyles(currentOffset) {
    if (!currentOffset) {
        return {
            display: "none",
        };
    }

    const x = currentOffset.x;
    const y = currentOffset.y;
    const boxOffset = boxesEl.offsetTop;

    if (y < 120 && scrollEl.scrollTop > boxOffset) {
        scrollEl.scrollTop = boxOffset - 20;
    }

    return {
        top: `${y - 20}px`,
        left: `${x}px`,
    };
}

class DndDragLayer extends React.Component {
    componentDidMount() {
        scrollEl = document.querySelector(".js-selector-app-scrollview");
        boxesEl = document.querySelector(".js-selector-dnd-boxes");
    }

    componentWillUnmount() {
        scrollEl = null;
    }

    render() {
        if (!this.props.isDragging) {
            return null;
        }

        return (
            <div
                className="svt_dnd__drag"
                style={getItemStyles(this.props.currentOffset)}
            >
                {this.props.label}
            </div>
        );
    }
}

DndDragLayer.propTypes = {
    label: PropTypes.string,
    currentOffset: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
    }),
    isDragging: PropTypes.bool,
};

export default DragLayer(collect)(DndDragLayer);
