import React from "react";
import { DropTarget } from "react-dnd";
import PropTypes from "prop-types";

import { DND_SET, DND_UNSET } from "../../../redux/actions";
import CloseIcon from "../../../assets/icons/close.svg?react";

const boxTarget = {
    canDrop() {
        return true;
    },
    drop(props, monitor) {
        let option = monitor.getItem();

        props.dispatch({
            type: DND_SET,
            index: props.index,
            option: option,
        });

        // Timeout to be sure that dnd state is set before calculating answer
        setTimeout(() => {
            props.setAnswer();
        }, 0);
    },
};

class DndBox extends React.Component {
    constructor() {
        super();

        this._remove = this._remove.bind(this);
    }

    _remove() {
        this.props.dispatch({
            type: DND_UNSET,
            index: this.props.index,
            option: this.props.droppedItem,
        });

        // Timeout to be sure that dnd state is set before calculating answer
        setTimeout(() => {
            this.props.setAnswer();
        }, 0);
    }

    render() {
        const { isOver, canDrop, droppedItem, connectDropTarget } = this.props;

        let modifier;

        if (isOver) {
            modifier = "-is-over";
        } else if (canDrop) {
            modifier = "-can-drop";
        } else if (droppedItem) {
            modifier = "-dropped";
        } else {
            modifier = "-inactive";
        }

        return connectDropTarget(
            <li className="svt_dnd__box-list-item">
                <div className="svt_dnd__box-wrapper">
                    <div className={"svt_dnd__box " + modifier}>
                        <div className="svt_dnd__box-content">
                            <h2 className="svt_dnd__box-label">
                                {this.props.label}
                            </h2>
                            {droppedItem ? (
                                <>
                                    <div className="svt_dnd__box-dropped">
                                        {droppedItem.label}
                                    </div>

                                    <button
                                        className="svt_dnd__box-remove"
                                        type="button"
                                        aria-label="Ta bort"
                                        onClick={this._remove}
                                    >
                                        <CloseIcon />
                                    </button>
                                </>
                            ) : null}
                        </div>
                    </div>
                </div>
            </li>,
        );
    }
}

DndBox.propTypes = {
    connectDropTarget: PropTypes.func.isRequired,
    isOver: PropTypes.bool.isRequired,
    canDrop: PropTypes.bool.isRequired,
    droppedItem: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    setAnswer: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
};

export default DropTarget(
    (props) => props.accepts,
    boxTarget,
    (connect, monitor) => ({
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
    }),
)(DndBox);
