import { useContext } from "react";
import { useSelector } from "react-redux";
import LViS from "@monterosa/lvis-api";
import {
    AccountContext,
    Toplist,
    ToplistContext,
} from "@svt/duo-shared-components";

import {
    getResultElements,
    getQuestionElements,
} from "../../../helpers/questionHelpers";
import CustomStarScoreToplistColumn from "./customStarScoreToplistColumn";

export const outer = {
    headerH1Inline: {
        background: "#311c57",
        color: "#ffffff",
        fontSize: "1rem",
    },
    inlineWrapper: { background: "transparent", margin: "32px 0" },
};

export const toplistUsersHeaders = {
    listHeader: {
        background: "#311c57",
        border: "none",
    },
    listCol2: {
        color: "#ffffff",
    },
    listCol3: {
        color: "#ffffff",
    },
};

export const toplistUsers = {
    listMe: {
        backgroundColor: "#5c3e7e",
        color: "#ffffff",
    },
    listItemMe: {
        backgroundColor: "transparent",
        border: "none",
        display: "flex",
        alignItems: "initial",
        marginBottom: "4px",
    },
    listRealName: {
        color: "#ffffff",
    },
    listItem: {
        backgroundColor: "transparent",
        border: "none",
        alignItems: "initial",
        marginBottom: "5px",
    },
    listLastQuestionInfoText: {
        color: "#ffffff",
    },
    listColUser: {
        color: "#ffffff",
        backgroundColor: "#13062a",
    },
    listColLastQuestion: {
        textShadow: "0 0 10px #fefdd9",
        color: "#fefdd9",
        backgroundColor: "#13062a",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: "2px",
        marginRight: "2px",
        fontSize: "1.25rem",
    },
    listColTotalScore: {
        textShadow: "0 0 10px #fefdd9",
        color: "#fefdd9",
        backgroundColor: "#13062a",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "1.5rem",
    },
};

export default function ToplistWrapper({
    listName,
    shouldShowTotalStarLevel = false,
}) {
    const toplistContext = useContext(ToplistContext);
    const accountContext = useContext(AccountContext);
    const appState = useSelector((state) => state.appState);
    const questionsState = useSelector((state) => state.questions);

    if (appState.view !== "playing") {
        return null;
    }

    const lvisEvent = LViS.getEvent();

    if (!lvisEvent) {
        return null;
    }

    function getQuestionIds(questionElements) {
        const noOfQuestions = getResultElements().length;
        let pickedQuestions = 0;
        let questionIds = [];

        questionElements.forEach((el) => {
            if (pickedQuestions < noOfQuestions) {
                pickedQuestions++;
                questionIds.push(el.getId());
            }
        });

        return questionIds;
    }

    function getTeamPoints(questionElements) {
        const eventCustomFields = LViS.getEvent().getCustomFields();
        let teams = [];

        [1, 2].forEach((teamNumber) => {
            let pointsById = {};

            questionElements.forEach((el) => {
                const elTeamPoints =
                    el.getCustomFields()[`train_${teamNumber}_points`];

                pointsById[el.getId()] = {
                    points: elTeamPoints,
                };
            });

            teams.push({
                name: eventCustomFields[`train_${teamNumber}_name`],
                image: eventCustomFields[`train_${teamNumber}_image`],
                pointsById: pointsById,
            });
        });

        return teams;
    }

    const questionElements = getQuestionElements();
    const questionIds = getQuestionIds(questionElements);
    const teamPoints = getTeamPoints(questionElements);

    function getTotalStars() {
        return questionIds.reduce((stars, questionId) => {
            const question = questionsState[questionId];
            const questionStars = question?.starLevel;

            if (typeof questionStars === "number") {
                stars += questionStars;
            }

            // Ensure total score doesn't go below 0
            return Math.max(stars, 0);
        }, 0);
    }

    function getFriendsStarsAndScore() {
        if (!accountContext.userData.isLoggedIn) return;
        const friendToplist = Object.keys(toplistContext.friends).reduce(
            (topListFriends, unoId) => {
                const profile = toplistContext.friends[unoId];
                const pointsForProfile = toplistContext.points[unoId];

                // Only proceed for friends who are online or have points
                if (profile.isOnline || pointsForProfile) {
                    if (shouldShowTotalStarLevel) {
                        let totalStars = 0;

                        questionIds.forEach((_, index) => {
                            const questionStars =
                                pointsForProfile?.[index + 1]?.starLevel;

                            if (typeof questionStars === "number") {
                                totalStars = totalStars + questionStars;

                                if (totalStars < 0) {
                                    totalStars = 0;
                                }
                            }
                        });
                        topListFriends[unoId] = (
                            <CustomStarScoreToplistColumn
                                totalStars={totalStars}
                                shouldShowTotalStarLevel={true}
                            />
                        );
                    } else {
                        const latestQuestionIndex = questionIds.length;
                        const latestQuestionScore =
                            pointsForProfile?.[latestQuestionIndex]?.points ??
                            null;
                        const latestQuestionStarLevel =
                            pointsForProfile?.[latestQuestionIndex]
                                ?.starLevel ?? null;

                        // Skip if no valid score or star level
                        if (
                            latestQuestionScore === null ||
                            latestQuestionStarLevel === null
                        ) {
                            return topListFriends;
                        }

                        topListFriends[unoId] = (
                            <CustomStarScoreToplistColumn
                                latestQuestionScore={latestQuestionScore}
                                latestQuestionStarLevel={
                                    latestQuestionStarLevel
                                }
                            />
                        );
                    }
                }
                return topListFriends;
            },
            {},
        );
        return { friends: friendToplist };
    }

    function getMyStarsAndScore() {
        if (
            getQuestionIds(questionElements).length > 0 &&
            Object.keys(questionsState).length > 0
        ) {
            if (shouldShowTotalStarLevel) {
                const totalStars = getTotalStars();
                return {
                    me: (
                        <CustomStarScoreToplistColumn
                            totalStars={totalStars}
                            shouldShowTotalStarLevel={true}
                        />
                    ),
                };
            }
            const lastQuestionId = questionIds[questionIds.length - 1];

            const latestQuestionScore =
                questionsState[lastQuestionId]?.points ?? null;
            const latestQuestionStarLevel =
                questionsState[lastQuestionId]?.starLevel ?? null;

            // Skip adding this if latestQuestionScore or latestQuestionStarLevel is null
            if (
                latestQuestionScore === null ||
                latestQuestionStarLevel === null
            ) {
                return;
            }

            return {
                me: (
                    <CustomStarScoreToplistColumn
                        latestQuestionScore={latestQuestionScore}
                        latestQuestionStarLevel={latestQuestionStarLevel}
                    />
                ),
            };
        }
    }

    function getCustomComponentForTeams() {
        if (shouldShowTotalStarLevel) {
            return {
                teams: Object.fromEntries(
                    teamPoints.map((teamPoint) => [teamPoint.name, <></>]),
                ),
            };
        }
        return;
    }

    function getCustomComponentForUsers() {
        return {
            ...getMyStarsAndScore(),
            ...getFriendsStarsAndScore(),
            ...getCustomComponentForTeams(),
        };
    }

    return (
        <Toplist
            headerH1Text={listName}
            inlineStyles={{ ...outer, toplistUsersHeaders, toplistUsers }}
            modal={false}
            questionIds={questionIds}
            userAnswers={questionsState}
            teamPoints={teamPoints}
            customComponent={getCustomComponentForUsers()}
            leftColumnHeading={shouldShowTotalStarLevel ? "Stjärnor" : ""}
            rightColumnHeading={shouldShowTotalStarLevel ? "Poäng" : ""}
        />
    );
}
