import React from "react";
import PropTypes from "prop-types";

import MultiChoiceOverviewItem from "./multiChoiceOverviewItem";
import { QUESTIONS_BEGIN_ANSWERING } from "../../../redux/actions";

export default class MultiChoiceOverview extends React.Component {
    constructor() {
        super();
        this._beginAnswering = this._beginAnswering.bind(this);
    }

    _beginAnswering(index) {
        this.props.dispatch({
            type: QUESTIONS_BEGIN_ANSWERING,
            id: this.props.element.id,
            data: index,
        });
    }

    render() {
        return (
            <div className="svt_mc-overview">
                <h1 className="svt_mc-overview__header">Följdfrågor</h1>

                <ul className="svt_mc-overview__list">
                    {this.props.questions.map((question, i) => {
                        return (
                            <MultiChoiceOverviewItem
                                key={i}
                                index={i}
                                question={question.question}
                                questionState={this.props.questionState}
                                clickHandler={this._beginAnswering}
                            />
                        );
                    })}
                </ul>
            </div>
        );
    }
}

MultiChoiceOverview.propTypes = {
    element: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    questions: PropTypes.array.isRequired,
    questionState: PropTypes.object.isRequired,
};
