import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import {
    DND_INITIAL,
    QUESTIONS_SET_CORRECT_ANSWER,
    QUESTIONS_RESET_ANSWER,
} from "../../../redux/actions";
import DndView from "./dndView";
import pointsSender from "../../../helpers/pointsSender";
import { getQuestionIndex } from "../../../helpers/questionHelpers";
import { getStore } from "../../../redux/configureStore";

const itemType = "option";

function Dnd(props) {
    const questionState = useSelector(
        (state) => state.questions[props.element.id],
    );
    const store = getStore();
    const dispatch = useDispatch();

    const _getInitial = useCallback(() => {
        let initialState = {};

        initialState.boxes = [];
        initialState.boxes.push({
            accepts: [itemType],
            droppedItem: null,
            label: props.element.box1_label,
        });
        initialState.boxes.push({
            accepts: [itemType],
            droppedItem: null,
            label: props.element.box2_label,
        });
        initialState.boxes.push({
            accepts: [itemType],
            droppedItem: null,
            label: props.element.box3_label,
        });

        initialState.options = [];
        for (let i = 0; i < props.element.dnd_options.length; i++) {
            initialState.options.push({
                type: itemType,
                dropped: false,
                label: props.element.dnd_options[i].option,
            });
        }

        return initialState;
    }, [
        props.element.box1_label,
        props.element.box2_label,
        props.element.box3_label,
        props.element.dnd_options,
    ]);

    const _setCorrectAnswer = useCallback(() => {
        if (questionState.correctAnswer === null) {
            let correctAnswer = [];
            correctAnswer.push({
                label: props.element.box1_label,
                correctAnswer: props.element.box1_correct_answer,
            });
            correctAnswer.push({
                label: props.element.box2_label,
                correctAnswer: props.element.box2_correct_answer,
            });
            correctAnswer.push({
                label: props.element.box3_label,
                correctAnswer: props.element.box3_correct_answer,
            });

            dispatch({
                type: QUESTIONS_SET_CORRECT_ANSWER,
                id: props.element.id,
                correctAnswer: correctAnswer,
            });
        }
    }, [
        dispatch,
        props.element.box1_correct_answer,
        props.element.box1_label,
        props.element.box2_correct_answer,
        props.element.box2_label,
        props.element.box3_correct_answer,
        props.element.box3_label,
        props.element.id,
        questionState.correctAnswer,
    ]);

    const _setInitial = useCallback(
        (force) => {
            const dndData = store.getState().dnd;

            if (force || dndData.id !== props.element.id) {
                dispatch({
                    type: DND_INITIAL,
                    data: _getInitial(),
                    id: props.element.id,
                });
            }
        },
        [_getInitial, dispatch, props.element.id, store],
    );

    const _reset = useCallback(() => {
        _setInitial(true);

        dispatch({
            type: QUESTIONS_RESET_ANSWER,
            id: props.element.id,
        });
    }, [_setInitial, dispatch, props.element.id]);

    useEffect(() => {
        _setInitial();
        _setCorrectAnswer();
    }, [_setCorrectAnswer, _setInitial]);

    useEffect(() => {
        pointsSender.sendOne(props.element.id, getQuestionIndex());
    }, [props.element.id]);

    return (
        <DndView
            resetClickHandler={_reset}
            element={props.element}
            questionState={questionState}
        />
    );
}

export default Dnd;

Dnd.propTypes = {
    element: PropTypes.object.isRequired,
    questionState: PropTypes.object.isRequired,
};
