import lodash from "lodash";
import duoCom from "@svt/duo-communication";
import LViS from "@monterosa/lvis-api";
import {
    AccountContext,
    duoLog,
    getQueryParam,
} from "@svt/duo-shared-components";
import { useSelector } from "react-redux";
import * as Sentry from "@sentry/react";
import { useContext } from "react";
import { AccountContextType } from "@svt/duo-shared-components/dist/types/types";

import {
    getCityIndex,
    getQuestionElements,
    getQuestionIndex,
    getResultElements,
} from "../helpers/questionHelpers";
import { QuestionPropsType } from "../redux/reducers/questions";
import questionTypes from "../helpers/questionTypes";
import { getSegment } from "../helpers/getSegment";

function errorLog(message: string, data: Record<string, unknown>) {
    Sentry.captureMessage(`${message} ${JSON.stringify(data)}`);
    duoLog(message, data);
    console.error(message, data);
}

function log(message: string, data: Record<string, unknown>) {
    duoLog(message, data);
    console.log(message, data);
}

export function useUnlockAchievement() {
    const accountContext = useContext<AccountContextType>(AccountContext);

    const questionsState = useSelector(
        (state: { questions: Record<string, QuestionPropsType> }) =>
            state.questions,
    );

    function unlockQuestionAnswerPoints() {
        if (!accountContext.userData.isLoggedIn) {
            return;
        }

        const lvisEvent = LViS.getEvent();
        if (lvisEvent) {
            const historyElements = lvisEvent
                .getHistory()
                .filter((el) => questionTypes[el.getContentType()]);

            const pointsArray = [];

            for (let i = 0; i < historyElements.length; i++) {
                const questionId = historyElements[i].getId();

                if (
                    historyElements[i].getDurationLeft() === 0 &&
                    questionsState[questionId]
                ) {
                    pointsArray.push({
                        segment: getSegment(i),
                        data: {
                            points: questionsState[questionId].points,
                            starLevel: questionsState[questionId].starLevel,
                            questionType:
                                questionsState[questionId].questionType,
                        },
                    });
                }
            }

            if (pointsArray.length) {
                pointsArray.forEach((questionData) => {
                    questionAnswerPoints({
                        points: questionData.data.points,
                        starLevel: questionData.data.starLevel,
                        questionType: questionData.data.questionType ?? "",
                        segment: questionData.segment,
                    });
                });
            }
        }
    }

    function questionAnswerPoints({
        points,
        starLevel,
        questionType,
        segment,
    }: {
        points: number;
        starLevel: number;
        questionType: string;
        segment: number;
    }) {
        if (playedSegment({ segment })) {
            return;
        }

        log("QuestionAnswerPoints", {
            segment,
            points,
            starLevel,
            questionType,
            historyEpisodesSegments,
        });

        Object.keys(
            getEpisodeAchievements({ achievementKey: "Poängsamlaren" }),
        ).forEach((code) => {
            try {
                if (points > 0) {
                    unlockAchievementByCodeAndCount(code, points);
                }
            } catch (e) {
                errorLog("Poängsamlaren Error", {
                    code,
                    points,
                    error: JSON.stringify(e),
                });
            }
        });

        Object.keys(
            getEpisodeAchievements({ achievementKey: "Stjärnsamlaren" }),
        ).forEach((code) => {
            try {
                if (starLevel > 0) {
                    unlockAchievementByCodeAndCount(code, starLevel);
                }
            } catch (e) {
                errorLog("Stjärnsamlaren Error", {
                    code,
                    starLevel,
                    error: JSON.stringify(e),
                });
            }
        });

        if (questionType === "city" && points === 10) {
            Object.keys(
                getEpisodeAchievements({ achievementKey: "Globetrottern" }),
            ).forEach((code) => {
                try {
                    unlockAchievementByCodeAndKey(
                        code,
                        `City${getCityIndex()}`,
                    );
                } catch (e) {
                    errorLog("Globetrottern Error", {
                        code,
                        key: `City${getCityIndex()}`,
                        error: JSON.stringify(e),
                    });
                }
            });

            try {
                unlockAchievementByCodeAndKey(
                    achievementNameAndCode["10X10"],
                    `Episode${LViS.getEvent().getCustomFields().episode_number}City${getCityIndex()}`,
                );
            } catch (e) {
                errorLog("10X10", {
                    code: achievementNameAndCode["10X10"],
                    key: `Episode${LViS.getEvent().getCustomFields().episode_number}City${getCityIndex()}`,
                    error: JSON.stringify(e),
                });
            }
        }

        if (questionType === "closest_wins" && points === 3) {
            Object.keys(
                getEpisodeAchievements({ achievementKey: "Kartläsaren" }),
            ).forEach((code) => {
                try {
                    unlockAchievementByCode(code);
                } catch (e) {
                    errorLog("Kartläsaren", {
                        code,
                        error: JSON.stringify(e),
                    });
                }
            });
        }

        if (points > 0) {
            unlockAchievementByCodeAndCount(
                achievementNameAndCode["POÄNGSAMLAREN_1"],
                points,
            ).catch((e) => {
                errorLog("POÄNGSAMLAREN_1", {
                    points,
                    error: JSON.stringify(e),
                });
            });
            unlockAchievementByCodeAndCount(
                achievementNameAndCode["POÄNGSAMLAREN_2"],
                points,
            ).catch((e) => {
                errorLog("POÄNGSAMLAREN_2", {
                    points,
                    error: JSON.stringify(e),
                });
            });
            unlockAchievementByCodeAndCount(
                achievementNameAndCode["POÄNGSAMLAREN_3"],
                points,
            ).catch((e) => {
                errorLog("POÄNGSAMLAREN_3", {
                    points,
                    error: JSON.stringify(e),
                });
            });
        }

        if (starLevel > 0) {
            unlockAchievementByCodeAndCount(
                achievementNameAndCode["STJÄRNSAMLAREN_1"],
                starLevel,
            ).catch((e) => {
                errorLog("STJÄRNSAMLAREN_1", {
                    points,
                    error: JSON.stringify(e),
                });
            });
            unlockAchievementByCodeAndCount(
                achievementNameAndCode["STJÄRNSAMLAREN_2"],
                starLevel,
            ).catch((e) => {
                errorLog("STJÄRNSAMLAREN_2", {
                    points,
                    error: JSON.stringify(e),
                });
            });
            unlockAchievementByCodeAndCount(
                achievementNameAndCode["STJÄRNSAMLAREN_3"],
                starLevel,
            ).catch((e) => {
                errorLog("STJÄRNSAMLAREN_3", {
                    points,
                    error: JSON.stringify(e),
                });
            });
        }
    }

    function unlockEpisodeEnd({
        toplistContext,
        questionsState,
        score,
    }: {
        toplistContext: {
            friends: { [unoId: string]: { isOnline: boolean } };
            points: {
                [unoId: string]: { [unoId: string]: { points: number } };
            };
        };
        questionsState: Record<string, QuestionPropsType>;
        score: number;
    }) {
        unlockQuestionAnswerPoints();
        unlockBingespelaren();
        unlockFredagstittaren();
        const questionIndex = getQuestionIndex();
        const segment = questionIndex ? questionIndex + 1 : null;

        if (segment) {
            if (playedSegment({ segment })) {
                return;
            }

            log("unlockEpisodeEnd", {
                score,
                segment: segment,
                historyEpisodesSegments,
            });

            unlockVänbräckaren({ toplistContext, questionsState });
            unlockCrackTheCages({ score });
        }
    }

    return { unlockEpisodeEnd, unlockQuestionAnswerPoints };
}

async function unlockAchievementByCode(achievementCode: string | number) {
    if (typeof achievementCode !== "number") {
        achievementCode = parseInt(achievementCode, 10);
    }

    return await duoCom.Amigo.unlockAchievement({
        code: achievementCode,
        shouldShowAchievementNotification: false,
    });
}

async function unlockAchievementByCodeAndCount(
    achievementCode: string | number,
    count: number,
) {
    if (typeof achievementCode !== "number") {
        achievementCode = parseInt(achievementCode, 10);
    }

    return await duoCom.Amigo.unlockAchievement({
        code: achievementCode,
        count: count,
        shouldShowAchievementNotification: false,
    });
}

async function unlockAchievementByCodeAndKey(
    achievementCode: string | number,
    key: string,
) {
    if (typeof achievementCode !== "number") {
        achievementCode = parseInt(achievementCode, 10);
    }

    return await duoCom.Amigo.unlockAchievement({
        code: achievementCode,
        key: key,
        shouldShowAchievementNotification: false,
    });
}

const historyEpisodesSegments: number[] = [];

export function setHistoryEpisodeSegments(
    historyPlayedEpisodeSegments: string[],
) {
    historyPlayedEpisodeSegments = []; // Reset for new episode
    historyPlayedEpisodeSegments.map((segment) => {
        historyEpisodesSegments.push(parseInt(segment, 10));
    });
}

function playedSegment({ segment }: { segment: number }) {
    if (getQueryParam("skipPlayedSegment")) {
        return false;
    }

    const hasPlayed = historyEpisodesSegments.includes(segment);
    if (!hasPlayed) {
        historyEpisodesSegments.push(segment);
    }

    return hasPlayed;
}

function unlockCrackTheCages({ score }: { score: number }) {
    const teams: { totalPoints: number }[] = [1, 2].reduce(
        (accumulator: { totalPoints: number }[], teamNumber) => {
            let teamTotalPoints = 0;

            getQuestionElements().forEach((questionElement) => {
                const elTeamPoints =
                    //@ts-expect-error - FIX type
                    questionElement.getCustomFields()[
                        `train_${teamNumber}_points`
                    ];

                if (elTeamPoints) {
                    teamTotalPoints = teamTotalPoints + elTeamPoints;
                }
            });

            accumulator[teamNumber - 1] = {
                totalPoints: teamTotalPoints,
            };

            return accumulator;
        },
        [{ totalPoints: 0 }, { totalPoints: 0 }],
    );

    const allBurbräckaren: { [key: string]: { nivå?: number } } =
        getEpisodeAchievements({ achievementKey: "Burbräckaren" });
    Object.keys(allBurbräckaren).forEach((code) => {
        if (score > teams[0].totalPoints && score > teams[1].totalPoints) {
            unlockAchievementByCode(code);
        } else if (
            score > teams[0].totalPoints ||
            score > teams[1].totalPoints
        ) {
            if (allBurbräckaren[code].nivå === 1) {
                unlockAchievementByCode(code);
            }
        }
    });
}

type AchievementKeysType =
    | "Poängsamlaren"
    | "Globetrottern"
    | "Burbräckaren"
    | "Stjärnsamlaren"
    | "Kartläsaren"
    | "Vänbräckaren";

export function getEpisodeAchievements({
    achievementKey,
    episodeNumber,
}: { achievementKey?: AchievementKeysType; episodeNumber?: string } = {}) {
    if (achievementKey) {
        return (
            allEpisodeAchievementsByEpisodeId[
                episodeNumber ??
                    LViS.getEvent().getCustomFields().episode_number
            ][achievementKey] ?? {}
        );
    }
    return (
        allEpisodeAchievementsByEpisodeId[
            episodeNumber ?? LViS.getEvent().getCustomFields().episode_number
        ] ?? {}
    );
}

export function isEpisodeAchievement(code: string) {
    const currentEpisodeAchievements = getEpisodeAchievements();

    return Object.keys(currentEpisodeAchievements).some((achievementKey) => {
        return Object.keys(
            currentEpisodeAchievements[
                achievementKey as keyof typeof currentEpisodeAchievements
            ]!,
        ).some((episodeAchievementCode) => episodeAchievementCode === code);
    });
}

export function getEpisodeAchievementTitleLockedFromAchievementCode(
    code: string,
) {
    const allAchievements: {
        [episode: string]: {
            [key: string]: {
                [achievement: string]: {
                    code: string;
                    title_locked: string;
                    nivå?: number;
                };
            };
        };
    } = allEpisodeAchievementsByEpisodeId;

    for (const episode in allAchievements) {
        const episodeAchievements = allAchievements[episode];
        for (const key in episodeAchievements) {
            const keyAchievements = episodeAchievements[key];
            for (const achievement in keyAchievements) {
                if (achievement === code) {
                    return keyAchievements[achievement].title_locked;
                }
            }
        }
    }
}

export const allEpisodeAchievementsByEpisodeId: {
    [episodeId: string]: {
        [key in AchievementKeysType]?: {
            [achievementId: string]: {
                code: string;
                title_locked: string;
                nivå?: number;
            };
        };
    };
} = {
    "1": {
        Poängsamlaren: {
            "138485762": {
                code: "138485762",
                title_locked: "Poängsamlaren nivå 1",
                nivå: 1,
            },
            "1269792782": {
                code: "1269792782",
                title_locked: "Poängsamlaren nivå 2",
                nivå: 2,
            },
            "1319933466": {
                code: "1319933466",
                title_locked: "Poängsamlaren nivå 3",
                nivå: 3,
            },
        },
        Globetrottern: {
            "154539207": {
                code: "154539207",
                title_locked: "Globetrottern nivå 1",
                nivå: 1,
            },
            "2000270595": {
                code: "2000270595",
                title_locked: "Globetrottern nivå 2",
                nivå: 2,
            },
        },
        Burbräckaren: {
            "370293242": {
                code: "370293242",
                title_locked: "Burbräckaren nivå 1",
                nivå: 1,
            },
            "2087565700": {
                code: "2087565700",
                title_locked: "Burbräckaren nivå 2",
                nivå: 2,
            },
        },
        Stjärnsamlaren: {
            "453017660": {
                code: "453017660",
                title_locked: "Stjärnsamlaren nivå 1",
                nivå: 1,
            },
            "683861472": {
                code: "683861472",
                title_locked: "Stjärnsamlaren nivå 3",
                nivå: 3,
            },
            "1138610732": {
                code: "1138610732",
                title_locked: "Stjärnsamlaren nivå 2",
                nivå: 2,
            },
        },
        Vänbräckaren: {
            "1012566109": {
                code: "1012566109",
                title_locked: "Vänbräckaren",
            },
        },
        Kartläsaren: {
            "1885666588": {
                code: "1885666588",
                title_locked: "Kartläsaren",
            },
        },
    },
    "2": {
        Kartläsaren: {
            "102135582": {
                code: "102135582",
                title_locked: "Kartläsaren",
            },
        },
        Globetrottern: {
            "595663588": {
                code: "595663588",
                title_locked: "Globetrottern nivå 1",
                nivå: 1,
            },
            "725715452": {
                code: "725715452",
                title_locked: "Globetrottern nivå 2",
                nivå: 2,
            },
        },
        Burbräckaren: {
            "616457855": {
                code: "616457855",
                title_locked: "Burbräckaren nivå 1",
                nivå: 1,
            },
            "1965290342": {
                code: "1965290342",
                title_locked: "Burbräckaren nivå 2",
                nivå: 2,
            },
        },
        Poängsamlaren: {
            "859082345": {
                code: "859082345",
                title_locked: "Poängsamlaren nivå 3",
                nivå: 3,
            },
            "907682888": {
                code: "907682888",
                title_locked: "Poängsamlaren nivå 2",
                nivå: 2,
            },
            "1075822478": {
                code: "1075822478",
                title_locked: "Poängsamlaren nivå 1",
                nivå: 1,
            },
        },
        Stjärnsamlaren: {
            "1169668225": {
                code: "1169668225",
                title_locked: "Stjärnsamlaren nivå 1",
                nivå: 1,
            },
            "1659424700": {
                code: "1659424700",
                title_locked: "Stjärnsamlaren nivå 3",
                nivå: 3,
            },
            "2045179421": {
                code: "2045179421",
                title_locked: "Stjärnsamlaren nivå 2",
                nivå: 2,
            },
        },
        Vänbräckaren: {
            "1741356533": {
                code: "1741356533",
                title_locked: "Vänbräckaren",
            },
        },
    },
    "3": {
        Stjärnsamlaren: {
            "541267267": {
                code: "541267267",
                title_locked: "Stjärnsamlaren nivå 2",
                nivå: 2,
            },
            "811634840": {
                code: "811634840",
                title_locked: "Stjärnsamlaren nivå 3",
                nivå: 3,
            },
            "1540298489": {
                code: "1540298489",
                title_locked: "Stjärnsamlaren nivå 1",
                nivå: 1,
            },
        },
        Globetrottern: {
            "751851215": {
                code: "751851215",
                title_locked: "Globetrottern nivå 2",
                nivå: 2,
            },
            "891005813": {
                code: "891005813",
                title_locked: "Globetrottern nivå 1",
                nivå: 1,
            },
        },
        Poängsamlaren: {
            "1513509878": {
                code: "1513509878",
                title_locked: "Poängsamlaren nivå 3",
                nivå: 3,
            },
            "1576214240": {
                code: "1576214240",
                title_locked: "Poängsamlaren nivå 1",
                nivå: 1,
            },
            "2096961357": {
                code: "2096961357",
                title_locked: "Poängsamlaren nivå 2",
                nivå: 2,
            },
        },
        Burbräckaren: {
            "1903924161": {
                code: "1903924161",
                title_locked: "Burbräckaren nivå 1",
                nivå: 1,
            },
            "2115711101": {
                code: "2115711101",
                title_locked: "Burbräckaren nivå 2",
                nivå: 2,
            },
        },
        Vänbräckaren: {
            "1995144794": {
                code: "1995144794",
                title_locked: "Vänbräckaren",
            },
        },
        Kartläsaren: {
            "2071734724": {
                code: "2071734724",
                title_locked: "Kartläsaren",
            },
        },
    },
    "4": {
        Globetrottern: {
            "436857689": {
                code: "436857689",
                title_locked: "Globetrottern nivå 1",
                nivå: 1,
            },
            "1808317253": {
                code: "1808317253",
                title_locked: "Globetrottern nivå 2",
                nivå: 2,
            },
        },
        Stjärnsamlaren: {
            "704910363": {
                code: "704910363",
                title_locked: "Stjärnsamlaren nivå 1",
                nivå: 1,
            },
            "1365453374": {
                code: "1365453374",
                title_locked: "Stjärnsamlaren nivå 3",
                nivå: 3,
            },
            "1680902257": {
                code: "1680902257",
                title_locked: "Stjärnsamlaren nivå 2",
                nivå: 2,
            },
        },
        Poängsamlaren: {
            "1348038639": {
                code: "1348038639",
                title_locked: "Poängsamlaren nivå 2",
                nivå: 2,
            },
            "1606669426": {
                code: "1606669426",
                title_locked: "Poängsamlaren nivå 1",
                nivå: 1,
            },
            "1999288816": {
                code: "1999288816",
                title_locked: "Poängsamlaren nivå 3",
                nivå: 3,
            },
        },
        Burbräckaren: {
            "1391620815": {
                code: "1391620815",
                title_locked: "Burbräckaren nivå 2",
                nivå: 2,
            },
            "1684888481": {
                code: "1684888481",
                title_locked: "Burbräckaren nivå 1",
                nivå: 1,
            },
        },
        Vänbräckaren: {
            "1944944580": {
                code: "1944944580",
                title_locked: "Vänbräckaren",
            },
        },
        Kartläsaren: {
            "1957493002": {
                code: "1957493002",
                title_locked: "Kartläsaren",
            },
        },
    },
    "5": {
        Globetrottern: {
            "63594679": {
                code: "63594679",
                title_locked: "Globetrottern nivå 2",
                nivå: 2,
            },
            "1120835531": {
                code: "1120835531",
                title_locked: "Globetrottern nivå 1",
                nivå: 1,
            },
        },
        Vänbräckaren: {
            "166526490": {
                code: "166526490",
                title_locked: "Vänbräckaren",
            },
        },
        Burbräckaren: {
            "472965278": {
                code: "472965278",
                title_locked: "Burbräckaren nivå 2",
                nivå: 2,
            },
            "1952348635": {
                code: "1952348635",
                title_locked: "Burbräckaren nivå 1",
                nivå: 1,
            },
        },
        Stjärnsamlaren: {
            "648919937": {
                code: "648919937",
                title_locked: "Stjärnsamlaren nivå 3",
                nivå: 3,
            },
            "1021655074": {
                code: "1021655074",
                title_locked: "Stjärnsamlaren nivå 2",
                nivå: 2,
            },
            "1511017733": {
                code: "1511017733",
                title_locked: "Stjärnsamlaren nivå 1",
                nivå: 1,
            },
        },
        Poängsamlaren: {
            "705781099": {
                code: "705781099",
                title_locked: "Poängsamlaren nivå 1",
                nivå: 1,
            },
            "796175587": {
                code: "796175587",
                title_locked: "Poängsamlaren nivå 2",
                nivå: 2,
            },
            "2008366564": {
                code: "2008366564",
                title_locked: "Poängsamlaren nivå 3",
                nivå: 3,
            },
        },
        Kartläsaren: {
            "1655465542": {
                code: "1655465542",
                title_locked: "Kartläsaren",
            },
        },
    },
    "6": {
        Poängsamlaren: {
            "121096101": {
                code: "121096101",
                title_locked: "Poängsamlaren nivå 3",
                nivå: 3,
            },
            "806441635": {
                code: "806441635",
                title_locked: "Poängsamlaren nivå 1",
                nivå: 1,
            },
            "1705062253": {
                code: "1705062253",
                title_locked: "Poängsamlaren nivå 2",
                nivå: 2,
            },
        },
        Stjärnsamlaren: {
            "174093247": {
                code: "174093247",
                title_locked: "Stjärnsamlaren nivå 2",
                nivå: 2,
            },
            "992784326": {
                code: "992784326",
                title_locked: "Stjärnsamlaren nivå 3",
                nivå: 3,
            },
            "1376265056": {
                code: "1376265056",
                title_locked: "Stjärnsamlaren nivå 1",
                nivå: 1,
            },
        },
        Burbräckaren: {
            "801222298": {
                code: "801222298",
                title_locked: "Burbräckaren nivå 2",
                nivå: 2,
            },
            "1505405905": {
                code: "1505405905",
                title_locked: "Burbräckaren nivå 1",
                nivå: 1,
            },
        },
        Kartläsaren: {
            "861161031": {
                code: "861161031",
                title_locked: "Kartläsaren",
            },
        },
        Vänbräckaren: {
            "1462369625": {
                code: "1462369625",
                title_locked: "Vänbräckaren",
            },
        },
        Globetrottern: {
            "1815511091": {
                code: "1815511091",
                title_locked: "Globetrottern nivå 1",
                nivå: 1,
            },
            "2091698557": {
                code: "2091698557",
                title_locked: "Globetrottern nivå 2",
                nivå: 2,
            },
        },
    },
    "7": {
        Stjärnsamlaren: {
            "209710858": {
                code: "209710858",
                title_locked: "Stjärnsamlaren nivå 3",
                nivå: 3,
            },
            "1700822124": {
                code: "1700822124",
                title_locked: "Stjärnsamlaren nivå 1",
                nivå: 1,
            },
            "1845040508": {
                code: "1845040508",
                title_locked: "Stjärnsamlaren nivå 2",
                nivå: 2,
            },
        },
        Poängsamlaren: {
            "907853886": {
                code: "907853886",
                title_locked: "Poängsamlaren nivå 3",
                nivå: 3,
            },
            "1305719556": {
                code: "1305719556",
                title_locked: "Poängsamlaren nivå 1",
                nivå: 1,
            },
            "1698104374": {
                code: "1698104374",
                title_locked: "Poängsamlaren nivå 2",
                nivå: 2,
            },
        },
        Burbräckaren: {
            "964897814": {
                code: "964897814",
                title_locked: "Burbräckaren nivå 2",
                nivå: 2,
            },
            "1050884286": {
                code: "1050884286",
                title_locked: "Burbräckaren nivå 1",
                nivå: 1,
            },
        },
        Vänbräckaren: {
            "1154712116": {
                code: "1154712116",
                title_locked: "Vänbräckaren",
            },
        },
        Kartläsaren: {
            "1447474094": {
                code: "1447474094",
                title_locked: "Kartläsaren",
            },
        },
        Globetrottern: {
            "1780537711": {
                code: "1780537711",
                title_locked: "Globetrottern nivå 2",
                nivå: 2,
            },
            "1810534360": {
                code: "1810534360",
                title_locked: "Globetrottern nivå 1",
                nivå: 1,
            },
        },
    },
    "8": {
        Burbräckaren: {
            "249098611": {
                code: "249098611",
                title_locked: "Burbräckaren nivå 2",
                nivå: 2,
            },
            "487220459": {
                code: "487220459",
                title_locked: "Burbräckaren nivå 1",
                nivå: 1,
            },
        },
        Globetrottern: {
            "430992405": {
                code: "430992405",
                title_locked: "Globetrottern nivå 1",
                nivå: 1,
            },
            "474068758": {
                code: "474068758",
                title_locked: "Globetrottern nivå 2",
                nivå: 2,
            },
        },
        Stjärnsamlaren: {
            "720439150": {
                code: "720439150",
                title_locked: "Stjärnsamlaren nivå 3",
                nivå: 3,
            },
            "1117105248": {
                code: "1117105248",
                title_locked: "Stjärnsamlaren nivå 2",
                nivå: 2,
            },
            "1585628191": {
                code: "1585628191",
                title_locked: "Stjärnsamlaren nivå 1",
                nivå: 1,
            },
        },
        Poängsamlaren: {
            "739870294": {
                code: "739870294",
                title_locked: "Poängsamlaren nivå 3",
                nivå: 3,
            },
            "1653379079": {
                code: "1653379079",
                title_locked: "Poängsamlaren nivå 2",
                nivå: 2,
            },
            "1703101488": {
                code: "1703101488",
                title_locked: "Poängsamlaren nivå 1",
                nivå: 1,
            },
        },
        Kartläsaren: {
            "1970209248": {
                code: "1970209248",
                title_locked: "Kartläsaren",
            },
        },
        Vänbräckaren: {
            "2112391643": {
                code: "2112391643",
                title_locked: "Vänbräckaren",
            },
        },
    },
    "9": {
        Kartläsaren: {
            "23140857": {
                code: "23140857",
                title_locked: "Kartläsaren",
            },
        },
        Poängsamlaren: {
            "142001347": {
                code: "142001347",
                title_locked: "Poängsamlaren nivå 1",
                nivå: 1,
            },
            "468096718": {
                code: "468096718",
                title_locked: "Poängsamlaren nivå 2",
                nivå: 2,
            },
            "1131147937": {
                code: "1131147937",
                title_locked: "Poängsamlaren nivå 3",
                nivå: 3,
            },
        },
        Globetrottern: {
            "455705661": {
                code: "455705661",
                title_locked: "Globetrottern nivå 1",
                nivå: 1,
            },
            "1711916046": {
                code: "1711916046",
                title_locked: "Globetrottern nivå 2",
                nivå: 2,
            },
        },
        Stjärnsamlaren: {
            "467035635": {
                code: "467035635",
                title_locked: "Stjärnsamlaren nivå 2",
                nivå: 2,
            },
            "557571315": {
                code: "557571315",
                title_locked: "Stjärnsamlaren nivå 1",
                nivå: 1,
            },
            "1901542038": {
                code: "1901542038",
                title_locked: "Stjärnsamlaren nivå 3",
                nivå: 3,
            },
        },
        Vänbräckaren: {
            "1643285937": {
                code: "1643285937",
                title_locked: "Vänbräckaren",
            },
        },
        Burbräckaren: {
            "2051252969": {
                code: "2051252969",
                title_locked: "Burbräckaren nivå 2",
                nivå: 2,
            },
            "2146150185": {
                code: "2146150185",
                title_locked: "Burbräckaren nivå 1",
                nivå: 1,
            },
        },
    },
    "10": {
        Poängsamlaren: {
            "18090189": {
                code: "18090189",
                title_locked: "Poängsamlaren nivå 1",
                nivå: 1,
            },
            "928540442": {
                code: "928540442",
                title_locked: "Poängsamlaren nivå 3",
                nivå: 3,
            },
            "990204534": {
                code: "990204534",
                title_locked: "Poängsamlaren nivå 2",
                nivå: 2,
            },
        },
        Vänbräckaren: {
            "537968760": {
                code: "537968760",
                title_locked: "Vänbräckaren",
            },
        },
        Stjärnsamlaren: {
            "1267481436": {
                code: "1267481436",
                title_locked: "Stjärnsamlaren nivå 2",
                nivå: 2,
            },
            "1385844669": {
                code: "1385844669",
                title_locked: "Stjärnsamlaren nivå 1",
                nivå: 1,
            },
            "2000960731": {
                code: "2000960731",
                title_locked: "Stjärnsamlaren nivå 3",
                nivå: 3,
            },
        },
        Kartläsaren: {
            "1299195377": {
                code: "1299195377",
                title_locked: "Kartläsaren",
            },
        },
        Globetrottern: {
            "1595308428": {
                code: "1595308428",
                title_locked: "Globetrottern nivå 1",
                nivå: 1,
            },
            "1790269584": {
                code: "1790269584",
                title_locked: "Globetrottern nivå 2",
                nivå: 2,
            },
        },
        Burbräckaren: {
            "1685513648": {
                code: "1685513648",
                title_locked: "Burbräckaren nivå 1",
                nivå: 1,
            },
            "2099015643": {
                code: "2099015643",
                title_locked: "Burbräckaren nivå 2",
                nivå: 2,
            },
        },
    },
    "11": {
        Poängsamlaren: {
            "160193106": {
                code: "160193106",
                title_locked: "Poängsamlaren nivå 2",
                nivå: 2,
            },
            "1223281242": {
                code: "1223281242",
                title_locked: "Poängsamlaren nivå 3",
                nivå: 3,
            },
            "1815629719": {
                code: "1815629719",
                title_locked: "Poängsamlaren nivå 1",
                nivå: 1,
            },
        },
        Kartläsaren: {
            "502834489": {
                code: "502834489",
                title_locked: "Kartläsaren",
            },
        },
        Stjärnsamlaren: {
            "508240464": {
                code: "508240464",
                title_locked: "Stjärnsamlaren nivå 3",
                nivå: 3,
            },
            "759058137": {
                code: "759058137",
                title_locked: "Stjärnsamlaren nivå 1",
                nivå: 1,
            },
            "1911526439": {
                code: "1911526439",
                title_locked: "Stjärnsamlaren nivå 2",
                nivå: 2,
            },
        },
        Globetrottern: {
            "513491680": {
                code: "513491680",
                title_locked: "Globetrottern nivå 1",
                nivå: 1,
            },
            "522063187": {
                code: "522063187",
                title_locked: "Globetrottern nivå 2",
                nivå: 2,
            },
        },
        Vänbräckaren: {
            "627075529": {
                code: "627075529",
                title_locked: "Vänbräckaren",
            },
        },
        Burbräckaren: {
            "685248222": {
                code: "685248222",
                title_locked: "Burbräckaren nivå 2",
                nivå: 2,
            },
            "1505040158": {
                code: "1505040158",
                title_locked: "Burbräckaren nivå 1",
                nivå: 1,
            },
        },
    },
    "12": {
        Poängsamlaren: {
            "298412583": {
                code: "298412583",
                title_locked: "Poängsamlaren nivå 1",
                nivå: 1,
            },
            "1129030463": {
                code: "1129030463",
                title_locked: "Poängsamlaren nivå 3",
                nivå: 3,
            },
            "1298181506": {
                code: "1298181506",
                title_locked: "Poängsamlaren nivå 2",
                nivå: 2,
            },
        },
        Stjärnsamlaren: {
            "334336492": {
                code: "334336492",
                title_locked: "Stjärnsamlaren nivå 3",
                nivå: 3,
            },
            "805250891": {
                code: "805250891",
                title_locked: "Stjärnsamlaren nivå 2",
                nivå: 2,
            },
            "1237376399": {
                code: "1237376399",
                title_locked: "Stjärnsamlaren nivå 1",
                nivå: 1,
            },
        },
        Burbräckaren: {
            "593851711": {
                code: "593851711",
                title_locked: "Burbräckaren nivå 1",
                nivå: 1,
            },
            "1179996653": {
                code: "1179996653",
                title_locked: "Burbräckaren nivå 2",
                nivå: 2,
            },
        },
        Globetrottern: {
            "725880868": {
                code: "725880868",
                title_locked: "Globetrottern nivå 2",
                nivå: 2,
            },
            "2096110130": {
                code: "2096110130",
                title_locked: "Globetrottern nivå 1",
                nivå: 1,
            },
        },
        Kartläsaren: {
            "1228249404": {
                code: "1228249404",
                title_locked: "Kartläsaren",
            },
        },
        Vänbräckaren: {
            "1725655238": {
                code: "1725655238",
                title_locked: "Vänbräckaren",
            },
        },
    },
    "13": {
        Poängsamlaren: {
            "34283289": {
                code: "34283289",
                title_locked: "Poängsamlaren nivå 2",
                nivå: 2,
            },
            "1198430282": {
                code: "1198430282",
                title_locked: "Poängsamlaren nivå 1",
                nivå: 1,
            },
            "2099722091": {
                code: "2099722091",
                title_locked: "Poängsamlaren nivå 3",
                nivå: 3,
            },
        },
        Globetrottern: {
            "291117865": {
                code: "291117865",
                title_locked: "Globetrottern nivå 2",
                nivå: 2,
            },
            "2005938045": {
                code: "2005938045",
                title_locked: "Globetrottern nivå 1",
                nivå: 1,
            },
        },
        Stjärnsamlaren: {
            "790675467": {
                code: "790675467",
                title_locked: "Stjärnsamlaren nivå 1",
                nivå: 1,
            },
            "1057756880": {
                code: "1057756880",
                title_locked: "Stjärnsamlaren nivå 3",
                nivå: 3,
            },
            "2017177171": {
                code: "2017177171",
                title_locked: "Stjärnsamlaren nivå 2",
                nivå: 2,
            },
        },
        Burbräckaren: {
            "987160315": {
                code: "987160315",
                title_locked: "Burbräckaren nivå 1",
                nivå: 1,
            },
            "1917081070": {
                code: "1917081070",
                title_locked: "Burbräckaren nivå 2",
                nivå: 2,
            },
        },
        Kartläsaren: {
            "1049012912": {
                code: "1049012912",
                title_locked: "Kartläsaren",
            },
        },
        Vänbräckaren: {
            "2066692208": {
                code: "2066692208",
                title_locked: "Vänbräckaren",
            },
        },
    },
    "14": {
        Globetrottern: {
            "94422686": {
                code: "94422686",
                title_locked: "Globetrottern nivå 2",
                nivå: 2,
            },
            "836474181": {
                code: "836474181",
                title_locked: "Globetrottern nivå 1",
                nivå: 1,
            },
        },
        Poängsamlaren: {
            "192809811": {
                code: "192809811",
                title_locked: "Poängsamlaren nivå 3",
                nivå: 3,
            },
            "1385915386": {
                code: "1385915386",
                title_locked: "Poängsamlaren nivå 1",
                nivå: 1,
            },
            "1886408504": {
                code: "1886408504",
                title_locked: "Poängsamlaren nivå 2",
                nivå: 2,
            },
        },
        Stjärnsamlaren: {
            "201938214": {
                code: "201938214",
                title_locked: "Stjärnsamlaren nivå 3",
                nivå: 3,
            },
            "767367467": {
                code: "767367467",
                title_locked: "Stjärnsamlaren nivå 2",
                nivå: 2,
            },
            "860792208": {
                code: "860792208",
                title_locked: "Stjärnsamlaren nivå 1",
                nivå: 1,
            },
        },
        Kartläsaren: {
            "653798437": {
                code: "653798437",
                title_locked: "Kartläsaren",
            },
        },
        Vänbräckaren: {
            "998610988": {
                code: "998610988",
                title_locked: "Vänbräckaren",
            },
        },
        Burbräckaren: {
            "1747922578": {
                code: "1747922578",
                title_locked: "Burbräckaren nivå 1",
                nivå: 1,
            },
            "2071099197": {
                code: "2071099197",
                title_locked: "Burbräckaren nivå 2",
                nivå: 2,
            },
        },
    },
    "15": {
        Poängsamlaren: {
            "108777304": {
                code: "108777304",
                title_locked: "Poängsamlaren nivå 2",
                nivå: 2,
            },
            "113099544": {
                code: "113099544",
                title_locked: "Poängsamlaren nivå 3",
                nivå: 3,
            },
            "191970641": {
                code: "191970641",
                title_locked: "Poängsamlaren nivå 1",
                nivå: 1,
            },
        },
        Burbräckaren: {
            "565767059": {
                code: "565767059",
                title_locked: "Burbräckaren nivå 1",
                nivå: 1,
            },
            "1168416910": {
                code: "1168416910",
                title_locked: "Burbräckaren nivå 2",
                nivå: 2,
            },
        },
        Stjärnsamlaren: {
            "593084521": {
                code: "593084521",
                title_locked: "Stjärnsamlaren nivå 3",
                nivå: 3,
            },
            "1270181666": {
                code: "1270181666",
                title_locked: "Stjärnsamlaren nivå 2",
                nivå: 2,
            },
            "1553695672": {
                code: "1553695672",
                title_locked: "Stjärnsamlaren nivå 1",
                nivå: 1,
            },
        },
        Globetrottern: {
            "1107471267": {
                code: "1107471267",
                title_locked: "Globetrottern nivå 2",
                nivå: 2,
            },
            "1449310052": {
                code: "1449310052",
                title_locked: "Globetrottern nivå 1",
                nivå: 1,
            },
        },
        Kartläsaren: {
            "1311965620": {
                code: "1311965620",
                title_locked: "Kartläsaren",
            },
        },
        Vänbräckaren: {
            "1799032121": {
                code: "1799032121",
                title_locked: "Vänbräckaren",
            },
        },
    },
};

interface Achievement {
    code: string;
    title_locked: string;
    nivå?: number;
    unlocked?: boolean;
}

interface AchievementGroup {
    [achievementCode: string]: Achievement;
}

export function currentNivåFromGroup(group: AchievementGroup) {
    return (
        Object.keys(group).find((achievementCode) => {
            if (!group[achievementCode].unlocked) {
                return true;
            }
        }) ?? group[Object.keys(group)[Object.keys(group).length - 1]]
    );
}

export function getAchievementsFromEpisode(episodeNumber: string) {
    const episodeAchievementsForEpisode: {
        [key: string]: AchievementGroup;
    } = allEpisodeAchievementsByEpisodeId[episodeNumber];

    return Object.keys(episodeAchievementsForEpisode).reduce(
        (acc, groupName: string) => {
            const sorted = lodash.sortBy(
                episodeAchievementsForEpisode[groupName],
                ["nivå"],
            );

            acc[groupName] = sorted;

            return acc;
        },
        {} as { [key: string]: Achievement[] },
    );
}

export function unlockBingespelaren() {
    unlockAchievementByCodeAndKey(
        achievementNameAndCode["BINGESPELAREN"],
        LViS.getEvent().getCustomFields().episode_number.toString(),
    ).catch((e) => {
        errorLog("Bingespelaren", {
            code: achievementNameAndCode["BINGESPELAREN"],
            error: JSON.stringify(e),
        });
    });
}

const queryPrimeTimeDay = getQueryParam("primeTimeDay") ?? "5";
const queryPrimeTimeFromTime = getQueryParam("primeTimeFromTime") ?? "20";
const queryPrimeTimeToTime = getQueryParam("primeTimeToTime") ?? "22";

function isPrimeTime() {
    //check if date is friday
    const date = new Date(LViS.Date.now() * 1000);
    if (date.getDay() !== parseInt(queryPrimeTimeDay, 10)) {
        return false;
    }

    //check if time is between 20:00 and 22:00
    const hour = date.getHours();
    if (
        hour < parseInt(queryPrimeTimeFromTime, 10) ||
        hour >= parseInt(queryPrimeTimeToTime, 10)
    ) {
        return false;
    }

    return true;
}

export function unlockFredagstittaren() {
    if (!isPrimeTime()) {
        return;
    }

    unlockAchievementByCodeAndKey(
        achievementNameAndCode["FREDAGSTITTAREN"],
        LViS.getEvent().getCustomFields().episode_number.toString(),
    ).catch((e) => {
        errorLog("Fredagstittaren", {
            code: achievementNameAndCode["FREDAGSTITTAREN"],
            error: JSON.stringify(e),
        });
    });
}

function unlockVänbräckaren({
    toplistContext,
    questionsState,
}: {
    toplistContext: {
        friends: { [unoId: string]: { isOnline: boolean } };
        points: { [unoId: string]: { [unoId: string]: { points: number } } };
    };
    questionsState: Record<string, QuestionPropsType>;
}) {
    if (!isPrimeTime()) {
        return;
    }

    function getQuestionIds(questionElements: { getId: () => string }[]) {
        const noOfQuestions = getResultElements().length;
        let pickedQuestions = 0;
        const questionIds: string[] = [];

        questionElements.forEach((questionElement) => {
            if (pickedQuestions < noOfQuestions) {
                pickedQuestions++;
                questionIds.push(questionElement.getId());
            }
        });

        return questionIds;
    }

    const questionIds = getQuestionIds(getQuestionElements());

    const finishedFriendsPoints = Object.keys(toplistContext.friends).reduce<
        Record<string, number>
    >((accumulator, friendUnoId) => {
        const profile = toplistContext.friends[friendUnoId];
        const pointsForProfile = toplistContext.points[friendUnoId] ?? {};
        const hasAnsweredLastQuestion = Boolean(
            pointsForProfile[questionIds.length],
        );

        if (!hasAnsweredLastQuestion) {
            return accumulator;
        }

        let totalPoints = 0;

        if (profile.isOnline || pointsForProfile) {
            questionIds.forEach((_, index) => {
                const questionPoints = pointsForProfile?.[index + 1]?.points;

                if (typeof questionPoints === "number") {
                    totalPoints = totalPoints + questionPoints;

                    if (totalPoints < 0) {
                        totalPoints = 0;
                    }
                }
            });

            accumulator[friendUnoId] = totalPoints;
        }
        return accumulator;
    }, {});

    const ownPoints = questionIds.reduce((points, questionId) => {
        const question = questionsState[questionId];
        const questionPoints = question?.points;

        if (typeof questionPoints === "number") {
            points += questionPoints;
        }

        // Ensure total score doesn't go below 0
        return Math.max(points, 0);
    }, 0);

    log("UnlockVänbräckaren", { ownPoints, finishedFriendsPoints });

    if (Object.keys(finishedFriendsPoints).length > 0) {
        const beatFriend = Object.keys(finishedFriendsPoints).some(
            (friendPointKey: string | number) =>
                ownPoints > finishedFriendsPoints[friendPointKey],
        );
        log("UnlockVänbräckaren", { beatFriend });

        if (beatFriend) {
            Object.keys(
                getEpisodeAchievements({ achievementKey: "Vänbräckaren" }),
            ).forEach((code) => {
                try {
                    unlockAchievementByCode(code);
                } catch (e) {
                    errorLog("Kartläsaren", {
                        code,
                        error: JSON.stringify(e),
                    });
                }
            });
        }
    }
}

export const achievementNameAndCode = {
    POÄNGSAMLAREN_1: 1642645979, // Samla poäng under säsongen.
    POÄNGSAMLAREN_2: 1699484308, // Samla poäng under säsongen.
    POÄNGSAMLAREN_3: 2135511798, // Samla poäng under säsongen.

    STJÄRNSAMLAREN_1: 73383485, // Samla stjärnor under säsongen.
    STJÄRNSAMLAREN_2: 1120185971, // Samla stjärnor under säsongen.
    STJÄRNSAMLAREN_3: 1464503843, // Samla stjärnor under säsongen.

    FREDAGSTITTAREN: 122474879, // Spela tio av säsongens avsnitt under fredagens sändningstid

    BINGESPELAREN: 1867463156, // Spela alla avsnitt i säsongen

    "10X10": 1018400169, // Få tio poäng på tio resor
};

export const allSeasonAchievements = {
    "73383485": {
        code: 73383485,
        title: "Säsongsstjärnsamlaren nivå 1",
        nivå: 1,
    },
    "122474879": {
        code: 122474879,
        title: "Fredagstittaren",
    },
    "1018400169": {
        code: 1018400169,
        title: "10x10",
    },
    "1120185971": {
        code: 1120185971,
        title: "Säsongsstjärnsamlaren nivå 2",
        nivå: 2,
    },
    "1464503843": {
        code: 1464503843,
        title: "Säsongsstjärnsamlaren nivå 3",
        nivå: 3,
    },
    "1642645979": {
        code: 1642645979,
        title: "Säsongspoängsamlaren nivå 1",
        nivå: 1,
    },
    "1699484308": {
        code: 1699484308,
        title: "Säsongspoängsamlaren nivå 2",
        nivå: 2,
    },
    "1867463156": {
        code: 1867463156,
        title: "Bingespelaren",
    },
    "2135511798": {
        code: 2135511798,
        title: "Säsongspoängsamlaren nivå 3",
        nivå: 3,
    },
};

// Used to fetch and structure the hardcoded data (allEpisodeAchievementsByEpisodeId)
export async function getAllAchievements() {
    const achievementsTree = await duoCom.Amigo.getAchievementsTree({
        app: "duo/pa-sparet",
        query: { includeProgressStatus: "true" },
    });

    const allEpisodeAchievements: {
        [key: string]: { [key: string]: unknown };
    } = {};

    const allSeasonAchievements: {
        [key: string]: { [key: string]: unknown };
    } = {};

    Object.keys(achievementsTree["children"]["ps-2024"]["children"]).forEach(
        (key) => {
            const episodeAchievements =
                achievementsTree["children"]["ps-2024"]["children"][key];
            if (episodeAchievements.children) {
                Object.keys(episodeAchievements.children).forEach(
                    (childKey) => {
                        const episodeAchievement =
                            episodeAchievements.children[childKey];

                        if (!allEpisodeAchievements[key]) {
                            allEpisodeAchievements[key] = {};
                        }
                        const all = [
                            "Poängsamlaren",
                            "Globetrottern",
                            "Stjärnsamlaren",
                            "Burbräckaren",
                            "Kartläsaren",
                            "Vänbräckaren",
                        ];

                        if (
                            all.some((name) =>
                                episodeAchievement[
                                    "info"
                                ].title_locked.includes(name),
                            )
                        ) {
                            all.forEach((name) => {
                                const titleLocked =
                                    episodeAchievement["info"].title_locked;
                                const numberMatch = titleLocked.match(/\d+/);
                                const number = numberMatch
                                    ? parseInt(numberMatch[0], 10)
                                    : null;

                                if (
                                    episodeAchievement[
                                        "info"
                                    ].title_locked.includes(name)
                                ) {
                                    allEpisodeAchievements[key][name] = {
                                        ...(allEpisodeAchievements[key][name] ??
                                            {}),
                                        [childKey]: {
                                            code: childKey,
                                            title_locked:
                                                episodeAchievement["info"]
                                                    .title_locked,
                                            nivå: number,
                                        },
                                    };
                                }
                            });
                        } else {
                            allEpisodeAchievements[key][childKey] = {
                                code: childKey,
                                title: episodeAchievement["info"].title_locked,
                            };
                        }
                    },
                );
            } else {
                if (!allSeasonAchievements[key]) {
                    allSeasonAchievements[key] = {};
                }

                const titleLocked = episodeAchievements["info"].title_locked;
                const numberMatch = titleLocked.match(/\d+/);
                const nivå = numberMatch ? parseInt(numberMatch[0], 10) : null;

                allSeasonAchievements[key] = {
                    code: episodeAchievements["info"].code,
                    title: episodeAchievements["info"].title_locked,
                };

                if (nivå) {
                    allSeasonAchievements[key] = {
                        ...allSeasonAchievements[key],
                        nivå: nivå,
                    };
                }
            }
        },
    );
    //Copy this from the console
    console.log("allEpisodeAchievements", allEpisodeAchievements);
    console.log("allSeasonAchievements", allSeasonAchievements);
}
