import { useEffect, useCallback, useState, useContext } from "react";
import LViS from "@monterosa/lvis-api";
import * as Sentry from "@sentry/react";
import update, { extend } from "immutability-helper";
import { AccountContext } from "@svt/duo-shared-components";
import { FriendsContext } from "@svt/duo-shared-components";
import duoCom from "@svt/duo-communication";
import _cloneDeep from "lodash/cloneDeep";

extend("$auto", (value, object) => {
    return object ? update(object, value) : update({}, value);
});
import {
    HighscoreFetchResponseType,
    FriendUserType,
} from "@svt/duo-communication/dist/api/highscore-types";

import ContentStatus from "../../../shared/contentStatus";

import {
    AccountContextLoggedInType,
    AirTimeType,
    FriendsContextType,
    LoggedInAmigoType,
    TeamsType,
} from "../../../../types";
import EpisodeList from "./episodeList";

export type EpisodeType = HighscoreFetchResponseType["episodes"][0] & {
    teams?: TeamsType[];
} & {
    friends?: {
        first_submit:
            | FriendUserType[]
            | (
                  | {
                        firstName: string;
                        isOnline?: false;
                        lastName: string;
                        profile: { color: string };
                        state?: string;
                        userId: string;
                        username: string;
                    }[]
                  | (
                        | {
                              isMe?: boolean;
                          }[]
                        | LoggedInAmigoType[]
                    )
              );
        max:
            | FriendUserType[]
            | (
                  | {
                        firstName: string;
                        isOnline?: false;
                        lastName: string;
                        profile: { color: string };
                        state?: string;
                        userId: string;
                        username: string;
                    }[]
                  | (
                        | {
                              isMe?: boolean;
                          }[]
                        | LoggedInAmigoType[]
                    )
              );
    };
};
export type ByEpisodeIdType = Record<string, EpisodeType>;

const initHistoryData = {
    allEpisodeAirTimes: {},
    highscoreResponseList: {
        byEpisodeId: {},
    },
};

const LoggedIn = () => {
    const accountContext: AccountContextLoggedInType =
        useContext(AccountContext);
    const friendsContext: FriendsContextType = useContext(FriendsContext);

    const [historyDataState, setHistoryDataState] = useState<{
        loaded: boolean;
        error: boolean;
        data: {
            allEpisodeAirTimes: AirTimeType;
            highscoreResponseList: {
                byEpisodeId: ByEpisodeIdType;
            };
        };
    }>({
        loaded: false,
        error: false,
        data: initHistoryData,
    });

    function _getAllEpisodeAirTimes() {
        const allEpisodeAirTimesList: AirTimeType = {};
        const projectSettings: Record<string, number> =
            LViS.Project.getSettings();

        let episodeIdCounter = 1;
        while (projectSettings[`episode${episodeIdCounter}`]) {
            const airTime = projectSettings[`episode${episodeIdCounter}`];

            allEpisodeAirTimesList[episodeIdCounter] = {
                airTime: airTime,
                hasAired: LViS.Date.now() > airTime,
            };
            episodeIdCounter++;
        }

        return allEpisodeAirTimesList;
    }

    function _getTeamsData(customFields: {
        episode_number: string;
        train_1_image: string;
        train_1_name: string;
        train_1_points: number;
        train_2_image: string;
        train_2_name: string;
        train_2_points: number;
        vision_id: string;
    }) {
        const defaultTeamData = {
            userId: "Team",
            username: null,
            isTeam: true,
            firstName: null,
            lastName: null,
            state: null,
            avatar: null,
            profile: {
                color: "#ccc",
            },
            score: null,
            rank: 0,
        };

        const teams: TeamsType[] = [];

        [1, 2].forEach((teamNumber) => {
            teams.push({
                ...defaultTeamData,
                username:
                    customFields[
                        `train_${teamNumber}_name` as
                            | "train_1_name"
                            | "train_2_name"
                    ],
                score: customFields[
                    `train_${teamNumber}_points` as
                        | "train_1_points"
                        | "train_2_points"
                ],
                avatar: `https:${
                    customFields[
                        `train_${teamNumber}_image` as
                            | "train_1_image"
                            | "train_2_image"
                    ]
                }`,
            });
        });

        return teams;
    }

    const _getLvisEvents = useCallback(() => {
        const lvisEventList:
            | {
                  episode: number;
                  season: number;
                  teams: TeamsType[];
              }
            | Record<string, unknown> = {};
        const events = LViS.Listings.getList();
        for (let i = events.length - 1; i >= 0; i--) {
            if (events[i].getState() !== LViS.Event.STATE_UPCOMING) {
                const customFields = events[i].getCustomFields();

                lvisEventList[parseInt(customFields.episode_number, 10)] = {
                    season: LViS.Project.getSettings().season_number,
                    episode: customFields.episode_number,
                    teams: _getTeamsData(customFields),
                };
            }
        }

        return lvisEventList as Record<
            string,
            { episode: number; season: number; teams: TeamsType[] }
        >;
    }, []);

    const _getHighScoreEpisodesPromise = useCallback(
        (
            allEpisodeAirTimes: Record<
                string,
                { airTime: number; hasAired: boolean }
            >,
            allElvisEvents: Record<
                string,
                { episode: number; season: number; teams: TeamsType[] }
            >,
        ) => {
            const airedEpisodeNumbers: string[] = [];

            Object.keys(allEpisodeAirTimes).forEach((key) => {
                if (allEpisodeAirTimes[key].hasAired && allElvisEvents[key]) {
                    airedEpisodeNumbers.push(
                        allElvisEvents[key].episode.toString(),
                    );
                }
            });

            if (airedEpisodeNumbers.length === 0) {
                return null;
            }

            return duoCom.Highscore.fetch({
                season: LViS.Project.getSettings().season_number.toString(),
                episode: airedEpisodeNumbers,
                postal_code:
                    accountContext.userData.amigo.demographic?.postal_code ??
                    undefined,
            });
        },
        [accountContext.userData.amigo.demographic?.postal_code],
    );

    const _getHighScoreListByEpisodeId = useCallback(
        (
            highscoreByEpisode: ByEpisodeIdType,
            lvisEventList: Record<
                string,
                { episode: number; season: number; teams: TeamsType[] }
            >,
        ) => {
            const highScoreListByEpisodeId: ByEpisodeIdType = {};

            Object.keys(highscoreByEpisode).forEach((episodeKey) => {
                const currentByEpisodeHighscore =
                    highscoreByEpisode[episodeKey];

                highScoreListByEpisodeId[episodeKey] =
                    currentByEpisodeHighscore;

                if (highScoreListByEpisodeId[episodeKey].friends) {
                    highScoreListByEpisodeId[episodeKey].friends.max =
                        highScoreListByEpisodeId[episodeKey].friends.max.map(
                            (friend) => {
                                if (
                                    friend.userId ===
                                    accountContext.userData.amigo?.userId
                                ) {
                                    return {
                                        ...friend,
                                        ...accountContext.userData.amigo,
                                        isMe: true,
                                    };
                                }
                                return {
                                    ...friend,
                                    ...friendsContext.friends[friend.userId],
                                };
                            },
                        );
                    highScoreListByEpisodeId[episodeKey].friends.first_submit =
                        highScoreListByEpisodeId[
                            episodeKey
                        ].friends?.first_submit.map((friend) => {
                            if (
                                friend.userId ===
                                accountContext.userData.amigo?.userId
                            ) {
                                return {
                                    ...friend,
                                    ...accountContext.userData.amigo,
                                    isMe: true,
                                };
                            }
                            return {
                                ...friend,
                                ...friendsContext.friends[friend.userId],
                            };
                        });
                }

                highScoreListByEpisodeId[episodeKey].teams =
                    lvisEventList[episodeKey].teams;
            });

            return highScoreListByEpisodeId;
        },
        [accountContext.userData.amigo, friendsContext?.friends],
    );

    const getHistory = useCallback(async () => {
        const allEpisodeAirTimesList = _getAllEpisodeAirTimes();
        const lvisEventList = _getLvisEvents();
        const highScoreEpisodesPromise = _getHighScoreEpisodesPromise(
            allEpisodeAirTimesList,
            lvisEventList,
        );

        if (highScoreEpisodesPromise) {
            highScoreEpisodesPromise
                .then((highscoreEpisodesResponse) => {
                    if (import.meta.env.VITE_ENVIRONMENT === "staging") {
                        duoCom.log({
                            message: "highscore response",
                            data: highscoreEpisodesResponse,
                        });
                    }
                    const highscoreEpisodesResponseClone = _cloneDeep(
                        highscoreEpisodesResponse,
                    ); //Clone since we don't want to mutate the original object

                    const episodesById: ByEpisodeIdType = {};
                    highscoreEpisodesResponseClone.episodes.forEach(
                        (episode) => {
                            episodesById[episode.episode] = episode;
                        },
                    );

                    const highscoreList: {
                        byEpisodeId: ByEpisodeIdType;
                    } = {
                        byEpisodeId:
                            initHistoryData.highscoreResponseList.byEpisodeId,
                    };

                    highscoreList.byEpisodeId = _getHighScoreListByEpisodeId(
                        episodesById,
                        lvisEventList,
                    );

                    setHistoryDataState((currentHighscoreState) => ({
                        ...currentHighscoreState,
                        loaded: true,
                        error: false,
                        data: {
                            highscoreResponseList: highscoreList,
                            allEpisodeAirTimes: allEpisodeAirTimesList,
                        },
                    }));
                })
                .catch((error) => {
                    if (import.meta.env.DEV) {
                        console.log(
                            "🚀 ~ file: loggedIn.tsx ~ getHistory ~ error:",
                            error,
                        );
                    }

                    if (import.meta.env.VITE_SENTRY_ENABLED === "true") {
                        Sentry.captureException(error);
                    }

                    setHistoryDataState((currentHighscoreState) => ({
                        ...currentHighscoreState,
                        loaded: true,
                        error: true,
                        data: initHistoryData,
                    }));
                });
        } else {
            setHistoryDataState({
                loaded: true,
                error: false,
                data: {
                    allEpisodeAirTimes: allEpisodeAirTimesList,
                    highscoreResponseList: {
                        byEpisodeId:
                            initHistoryData.highscoreResponseList.byEpisodeId,
                    },
                },
            });
        }
    }, [
        _getHighScoreEpisodesPromise,
        _getHighScoreListByEpisodeId,
        _getLvisEvents,
    ]);

    useEffect(() => {
        getHistory();
    }, [getHistory]);

    return (
        <ContentStatus
            errorHTMLText={
                "Det gick tyvärr inte att ladda in historiken. <br/> Ladda om appen om du vill försöka igen"
            }
            loaded={historyDataState.loaded && friendsContext?.loaded}
            error={historyDataState.error}
        >
            {() => (
                <>
                    <EpisodeList
                        allEpisodeAirTimesByEpisodeId={
                            historyDataState.data.allEpisodeAirTimes
                        }
                        episodeHighScoreList={
                            historyDataState.data.highscoreResponseList
                                .byEpisodeId
                        }
                    />
                </>
            )}
        </ContentStatus>
    );
};

export default LoggedIn;
