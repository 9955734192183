import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import {
    THE_LIST_INITIAL,
    QUESTIONS_SET_CORRECT_ANSWER,
} from "../../../redux/actions";
import TheListAnswer from "./theListAnswer";
import pointsSender from "../../../helpers/pointsSender";
import { getQuestionIndex } from "../../../helpers/questionHelpers";
import { getStore } from "../../../redux/configureStore";

function TheList(props) {
    const questionState = useSelector(
        (state) => state.questions[props.element.id],
    );
    const store = getStore();
    const dispatch = useDispatch();

    useEffect(
        function setCorrectAnswer() {
            const theListData = store.getState().theList;

            if (theListData.id !== props.element.id) {
                let options = [];
                let noToSelect = 0;

                for (
                    let i = 0;
                    i < props.element.the_list_options.length;
                    i++
                ) {
                    let isCorrect =
                        props.element.the_list_options[i].is_correct === true;

                    options.push({
                        option: props.element.the_list_options[i].option,
                        isCorrect: isCorrect,
                        selected: false,
                    });

                    if (isCorrect) {
                        noToSelect++;
                    }
                }

                dispatch({
                    type: THE_LIST_INITIAL,
                    options: options,
                    noToSelect: noToSelect,
                    id: props.element.id,
                });

                if (questionState.correctAnswer === null) {
                    dispatch({
                        type: QUESTIONS_SET_CORRECT_ANSWER,
                        id: props.element.id,
                        correctAnswer: options,
                    });
                }
            }

            return () => {
                pointsSender.sendOne(props.element.id, getQuestionIndex());
            };
        },
        [
            dispatch,
            props.element.id,
            props.element.the_list_options,
            questionState.correctAnswer,
            store,
        ],
    );

    return (
        <TheListAnswer element={props.element} questionState={questionState} />
    );
}

export default TheList;

TheList.propTypes = {
    element: PropTypes.object.isRequired,
};
