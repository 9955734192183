import DuoCom from "@svt/duo-communication";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";

import { ADD_UNLOCKED_ACHIEVEMENT } from "../redux/actions";
import { AchievementType } from "../redux/reducers/unlockedAchievements";

export default function useUnlockedAchievement() {
    const dispatch = useDispatch();

    const handleUnlockAchievement = useCallback(
        (achievement: { detail: AchievementType }) => {
            dispatch({
                type: ADD_UNLOCKED_ACHIEVEMENT,
                payload: achievement.detail,
            });
        },
        [dispatch],
    );

    useEffect(() => {
        DuoCom.events.addEventListener(
            "achievement:unlocked",
            handleUnlockAchievement,
        );

        return () => {
            DuoCom.events.removeEventListener(
                "achievement:unlocked",
                handleUnlockAchievement,
            );
        };
    }, [handleUnlockAchievement]);

    return null;
}
