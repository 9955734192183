import Star from "../star";
import StarsRow from "../starsRow";

export default function CustomStarScoreToplistColumn({
    latestQuestionScore = 0,
    latestQuestionStarLevel = 0,
    totalStars = 0,
    shouldShowTotalStarLevel = false,
}: {
    latestQuestionScore?: number;
    latestQuestionStarLevel?: number;
    totalStars?: number;
    shouldShowTotalStarLevel: boolean;
}) {
    if (shouldShowTotalStarLevel) {
        return (
            <div className="svt_star__total-stars-wrapper">
                {totalStars}
                {totalStars > 0 ? (
                    <Star width={12} height={12} modifier="-full-moon" />
                ) : (
                    <Star
                        width={12}
                        height={12}
                        modifier="-empty-star-class-toplist"
                    />
                )}
            </div>
        );
    }
    return (
        <div>
            {latestQuestionScore}
            <StarsRow
                starLevel={latestQuestionStarLevel}
                width={10}
                height={10}
                emptyStarClass="-empty-star-class-toplist"
            />
        </div>
    );
}
