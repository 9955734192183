import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import PropTypes from "prop-types";

import {
    QUESTIONS_SET_ANSWER,
    QUESTIONS_RESET_ANSWER,
} from "../../../redux/actions";
import DndBox from "./dndBox";
import DndOption from "./dndOption";
import DndDragPortal from "./dndDragPortal";
import calculateStarLevel from "../../../helpers/calculateStarLevel";
import ResetImage from "../../../assets/icons/reset.svg?react";
import { getStore } from "../../../redux/configureStore";

function DndView(props) {
    const dnd = useSelector((state) => state.dnd);
    const store = getStore();
    const dispatch = useDispatch();

    const setAnswer = useCallback(() => {
        const hasAnswers = dnd.boxes.some(
            (boxData) => boxData.droppedItem !== null,
        );

        if (hasAnswers) {
            let noOfCorrect = 0;
            let answer = [];

            for (let i = 0; i < dnd.boxes.length; i++) {
                let boxAnswer = dnd.boxes[i].droppedItem
                    ? dnd.boxes[i].droppedItem.label
                    : "-";
                let isCorrect =
                    boxAnswer &&
                    boxAnswer.toLowerCase().trim() ===
                        props.questionState.correctAnswer[i].correctAnswer
                            .toLowerCase()
                            .trim();

                if (isCorrect) {
                    noOfCorrect++;
                }

                answer.push({
                    label: props.questionState.correctAnswer[i].label,
                    answer: boxAnswer,
                    correctAnswer:
                        props.questionState.correctAnswer[i].correctAnswer,
                    isCorrect: isCorrect,
                });
            }

            const storeState = store.getState();
            const answerTime =
                storeState.countdown.duration - storeState.countdown.now;
            const allCorrect = Boolean(noOfCorrect === 3);

            dispatch({
                type: QUESTIONS_SET_ANSWER,
                id: props.element.id,
                answer: answer,
                isCorrect: Boolean(noOfCorrect > 0),
                allCorrect: allCorrect,
                points: noOfCorrect,
                answerTime: answerTime * 1000,
                starLevel: allCorrect
                    ? calculateStarLevel(answerTime, props.element)
                    : 0,
            });
        } else {
            dispatch({
                type: QUESTIONS_RESET_ANSWER,
                id: props.element.id,
            });
        }
    }, [
        dispatch,
        dnd.boxes,
        props.element,
        props.questionState.correctAnswer,
        store,
    ]);

    const { boxes, options } = dnd;

    let noOfDropped = 0;
    for (var i = 0; i < boxes.length; i++) {
        if (boxes[i].droppedItem) {
            noOfDropped++;
        }
    }

    return (
        <div className="svt_dnd">
            <h1 className="svt_dnd__header">{props.element.question}</h1>

            {props.element.image && (
                <img
                    className="svt_dnd__image"
                    src={props.element.image}
                    alt=""
                />
            )}

            <p className="svt_dnd__select-info">
                {noOfDropped === 0 ? (
                    <span>Dra och släpp de 3 rätta svaren</span>
                ) : (
                    <span>Du har svarat på {noOfDropped} av 3</span>
                )}
            </p>

            <DndProvider
                backend={TouchBackend}
                options={{ enableMouseEvents: true }}
            >
                <DndDragPortal />

                <ul className="svt_dnd__box-list js-selector-dnd-boxes">
                    {boxes.map((item, index) => {
                        return (
                            <DndBox
                                setAnswer={setAnswer}
                                key={index}
                                index={index}
                                label={item.label}
                                accepts={item.accepts}
                                droppedItem={item.droppedItem}
                                dispatch={dispatch}
                            />
                        );
                    })}
                </ul>

                <ul className="svt_dnd__options-list">
                    {options.map((item, index) => {
                        return (
                            <DndOption
                                key={index}
                                index={index}
                                label={item.label}
                                type={item.type}
                                isDropped={item.dropped}
                            />
                        );
                    })}
                </ul>
            </DndProvider>

            <div>
                <button
                    type="button"
                    className="svt_dnd__reset"
                    disabled={noOfDropped === 0}
                    onClick={props.resetClickHandler}
                >
                    <ResetImage className="svt_dnd__reset-icon" /> Återställ
                    alla
                </button>
            </div>
        </div>
    );
}

DndView.propTypes = {
    resetClickHandler: PropTypes.func.isRequired,
    element: PropTypes.object.isRequired,
    questionState: PropTypes.object.isRequired,
};

export default DndView;
