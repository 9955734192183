import { useEffect, useRef } from "react";
import gsap from "gsap";
import { hideToMatchImageSnapshotClass } from "../../helpers/imageTest";

export default function CountdownBar({
    durationLeft,
    duration,
}: {
    durationLeft: number;
    duration: number;
}) {
    const barRef = useRef(null);

    useEffect(() => {
        const timeLeft = durationLeft;
        const percentageLeft = `${(durationLeft / duration) * 100}%`;

        gsap.fromTo(
            barRef.current,
            { width: percentageLeft },
            {
                duration: timeLeft,
                width: "0%",
                ease: "none",
            },
        );
    }, [duration, durationLeft]);

    return (
        <div className="svt_countdown">
            <div
                ref={barRef}
                className={`svt_countdown__bar ${hideToMatchImageSnapshotClass}`}
            ></div>
        </div>
    );
}
