import React from "react";
import PropTypes from "prop-types";
import { FreetextToParagraphs } from "@svt/duo-shared-components";

function Info(props) {
    return (
        <div className="info-wrapper">
            <h1 className="info-heading">{props.element.heading}</h1>

            <FreetextToParagraphs freetext={props.element.text} />

            <p className="info-text-footer">
                Njut av programmet så länge. Vi burrar med telefonen när spelet
                sätter igång.
            </p>
        </div>
    );
}

Info.propTypes = {
    element: PropTypes.object.isRequired,
};

export default Info;
