import { useCallback, useContext, useEffect, useRef } from "react";
import duoCom from "@svt/duo-communication";
import LViS from "@monterosa/lvis-api";
import { FriendsContext } from "@svt/duo-shared-components";

import useInterval from "../helpers/useInterval";
import { getEventOffsetSeconds } from "../helpers/eventOffset";

function createEventsObject(eventsObject, items) {
    items.forEach((item) => {
        if (!eventsObject[item.unoId]) {
            eventsObject[item.unoId] = {};
        }

        eventsObject[item.unoId][item.segment] = item.data;
    });

    return eventsObject;
}

function CityEvents() {
    const friendsContext = useContext(FriendsContext);
    const lockCityRef = useRef({});
    const timeoutRef = useRef({});

    const presentNotification = useCallback(
        ({ unoId, pointsLevel }) => {
            if (friendsContext.friends[unoId]) {
                if (duoCom.supports("playSound")) {
                    duoCom.playSound({
                        id: "tuta_dressin",
                    });
                }

                duoCom.presentNotification(
                    {
                        title: `${friendsContext.friends[unoId].username} drog på`,
                        message: `${pointsLevel} poäng`,
                        imageUrl: duoCom.Amigo.getAvatarUrlForUnoId({
                            unoId: unoId,
                        }),
                    },
                    () => {
                        //Do nothing
                    },
                );
            }
        },
        [friendsContext.friends],
    );

    useEffect(() => {
        let isMounted = false; // keep track of the mounted state (To not dubble subscribe)
        let isSubscribed = false; // keep track of the subscribe state (To not unnecessary unsubscribe)

        function subscribeToLockCity(interaction) {
            const eventOffsetSeconds = getEventOffsetSeconds();
            const diffFromFriend =
                eventOffsetSeconds - interaction.data.answerTime;

            if (diffFromFriend <= 10 && diffFromFriend >= 0) {
                presentNotification({
                    unoId: interaction.unoId,
                    pointsLevel: interaction.data.pointsLevel,
                });

                //If eventOffsetSeconds - interaction.data.answerTime would be 0 we might run presentNotification twice
                timeoutRef.current = setTimeout(() => {
                    lockCityRef.current = createEventsObject(
                        lockCityRef.current,
                        [interaction],
                    );
                }, 1000);
            } else {
                lockCityRef.current = createEventsObject(lockCityRef.current, [
                    interaction,
                ]);
            }
        }

        if (duoCom.Interactions) {
            isMounted = true;
            duoCom.Interactions.get({
                season: LViS.Project.getSettings().season_number.toString(),
                episode: LViS.getEvent()
                    .getCustomFields()
                    .episode_number.toString(),
                type: "LOCK_CITY",
                selection: "friends",
                sortAscending: true,
            })
                .then((response) => {
                    if (isMounted) {
                        lockCityRef.current = createEventsObject(
                            lockCityRef.current,
                            response.items,
                        );

                        isSubscribed = true;
                        duoCom.Interactions.subscribe(
                            {
                                season: LViS.Project.getSettings().season_number.toString(),
                                episode: LViS.getEvent()
                                    .getCustomFields()
                                    .episode_number.toString(),
                                type: "LOCK_CITY",
                                selection: "friends",
                            },
                            subscribeToLockCity,
                        );
                    }
                })
                .catch(() => {
                    // Do nothing
                });
        }

        return () => {
            isMounted = false;
            if (duoCom.Interactions && isSubscribed) {
                duoCom.Interactions.unsubscribe(subscribeToLockCity);
            }

            clearTimeout(timeoutRef.current);
        };
    }, [presentNotification]);

    useInterval(() => {
        const eventOffsetSeconds = getEventOffsetSeconds();

        Object.keys(lockCityRef.current).forEach((unoId) => {
            Object.keys(lockCityRef.current[unoId]).forEach((segment) => {
                if (
                    eventOffsetSeconds ===
                    lockCityRef.current[unoId][segment].answerTime
                ) {
                    presentNotification({
                        unoId: unoId,
                        pointsLevel:
                            lockCityRef.current[unoId][segment].pointsLevel,
                    });
                }
            });
        });
    }, 1000);

    return null;
}

export default CityEvents;
