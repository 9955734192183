import React from "react";
import PropTypes from "prop-types";
import { analytics, AccountPromotion } from "@svt/duo-shared-components";

import WaitingAnim from "./waitingAnim";
import ToplistWrapper from "../../shared/toplist/toplistwrapper";
import ProblemInfo from "../../shared/problemInfo";
import withAccountContext from "../../shared/withAccountContext";

class Waiting extends React.Component {
    componentDidMount() {
        analytics.trackViewEvent("väntar");
    }

    render() {
        const shouldRenderToplist =
            import.meta.env.VITE_PLAY_WITH_FRIENDS === "true";

        return (
            <div className="svt_waiting">
                <h1 className="svt_waiting__header">Väntar på nästa fråga</h1>
                <WaitingAnim />
                <p className="svt_waiting__text">
                    Sitt lugnt i kupén, du är i synk med programmet. Vi burrar
                    med telefonen vid nästa fråga.
                </p>

                {shouldRenderToplist && (
                    <div>
                        <div className="svt_waiting__seperator" />
                        <ToplistWrapper listName="Poängställning vänner" />
                        <ProblemInfo />
                        {
                            // Hide account promotion if HIDE_ADD_FRIENDS is true and user is logged in
                            import.meta.env.VITE_HIDE_ADD_FRIENDS === "true" &&
                            this.props.accountContext.userData
                                .isLoggedIn ? null : (
                                <AccountPromotion
                                    inlineStyles={{
                                        button: { backgroundColor: "#5C3E7E" },
                                    }}
                                    viewName="vantar"
                                />
                            )
                        }
                    </div>
                )}
            </div>
        );
    }
}

Waiting.propTypes = {
    accountContext: PropTypes.object.isRequired,
};

export default withAccountContext(Waiting);
