import duoCom from "@svt/duo-communication";
import { clearEvent } from "../index";
import { action as toggleMenu } from "redux-burger-menu";

export default function backToStart(isEndScreen, isLoggedIn, dispatch, signUp) {
    if (duoCom.supports("verify")) {
        if (isEndScreen) {
            if (isLoggedIn) {
                dispatch(toggleMenu(false));
                clearEvent(true);
            }

            if (!isLoggedIn) {
                duoCom.verify(
                    {
                        title: "Vill du avsluta spelet?",
                        message:
                            "Ditt resultat kommer gå förlorat. Om du skapar ett konto innan du avslutar så sparar vi ditt resultat.",
                        declineButton: "Till start",
                        approveButton: "Skapa konto",
                    },
                    (response) => {
                        switch (response.userResponse) {
                            case true: {
                                signUp();
                                break;
                            }
                            case false: {
                                dispatch(toggleMenu(false));
                                clearEvent(true);
                                break;
                            }
                            default: {
                                break;
                            }
                        }
                    },
                );
            }
        } else {
            duoCom.verify(
                {
                    title: "Vill du avsluta spelet?",
                    message:
                        "Vi sparar dina poäng i en timme om du vill fortsätta spela.",
                    declineButton: "Avbryt",
                    approveButton: "Till start",
                },
                (response) => {
                    if (response.userResponse) {
                        dispatch(toggleMenu(false));
                        clearEvent(true);
                    }
                },
            );
        }
    }
}
