import React from "react";
import duoCom from "@svt/duo-communication";
import { analytics } from "@svt/duo-shared-components";

export default class Paused extends React.Component {
    componentDidMount() {
        analytics.trackViewEvent("pausad");
    }

    _startAudiosync() {
        if (duoCom.supports("audiosyncStart")) {
            duoCom.audiosyncStart();
            analytics.trackClickEvent("audiosync:resume");
        }
    }

    render() {
        return (
            <div className="svt_paused">
                <h1 className="svt_paused__heading">Spelet är pausat!</h1>
                <p>
                    Du måste även pausa avsnittet i SVT Play ifall du tittar
                    där. Tryck på &quot;Återuppta&quot; när du vill fortsätta.
                </p>
                <button
                    type="button"
                    className="svt_paused__start-button"
                    onClick={this._startAudiosync}
                >
                    Återuppta
                </button>
            </div>
        );
    }
}
