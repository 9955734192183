import LViS from "@monterosa/lvis-api";
import { useSelector } from "react-redux";
import questionTypes from "./questionTypes";

export default function useGetScore() {
    const questionState = useSelector((state) => state.questions);
    const numbersOfQuestionsToCount = LViS.getEvent()
        .getHistory()
        .filter(
            (el) => el.getContentType() === "question_answers_points",
        ).length;
    const questionElements = LViS.getEvent()
        .getHistory()
        .filter((el) => questionTypes[el.getContentType()]);
    let score = 0;
    for (let i = 0; i < numbersOfQuestionsToCount; i++) {
        if (
            questionElements[i].getDurationLeft() === 0 &&
            questionState[questionElements[i].getId()]
        ) {
            let questionScore =
                questionState[questionElements[i].getId()].points;
            score = score + questionScore;
            if (score < 0) {
                score = 0;
            }
        }
    }
    return score;
}
