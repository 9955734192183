import duoCom from "@svt/duo-communication";
import { analytics } from "@svt/duo-shared-components";

import MhLogo from "../../../assets/icons/mhLogo.svg?react";

function SendToMusikhjalpen() {
    function goToMusikhjalpen() {
        analytics.trackCustomEvent("send to musikhjälpen", "musikhjälpen");

        duoCom.Duo.launchSubAppWithId(import.meta.env.VITE_MUSIKHJALPEN_ID, {
            payload: {},
        })
            .then(() => {
                // subapp is launched
            })
            .catch(() => {
                // Do nothing
            });
    }

    return (
        <button
            className="svt_send-to-musikhjalpen"
            type="button"
            onClick={() => {
                goToMusikhjalpen();
            }}
        >
            <MhLogo />
            <div className="svt_send-to-musikhjalpen__header">
                Nästa station: Musikhjälpen
            </div>
            <div>
                Önska dina favoritlåtar och bidra till årets tema “Alla har rätt
                att överleva sin graviditet”.
            </div>
            <div className="svt_send-to-musikhjalpen__fake-button">
                önska en låt!
            </div>
        </button>
    );
}

export default SendToMusikhjalpen;
