import { ResultsType } from "../types";

type ResultComparisonType = "betterResult" | "worseResult" | "sameResult";

export function getTotalNumberOfPlayers(allPlayerPoints: ResultsType | null) {
    let totalNumberOfPlayers = 0;

    if (allPlayerPoints) {
        allPlayerPoints.forEach((allPlayerPointsAtIndex) => {
            if (allPlayerPointsAtIndex.votes) {
                totalNumberOfPlayers += allPlayerPointsAtIndex.votes;
            }
        });
    }

    return totalNumberOfPlayers;
}

export function getNumberOfPlayersBetterThan(
    userPointsIndex: number,
    allPlayerPoints: ResultsType,
) {
    let numberOfBetterThan = 0;

    for (let i = userPointsIndex + 1; i < allPlayerPoints.length; i++) {
        if (allPlayerPoints[i].votes) {
            numberOfBetterThan += allPlayerPoints[i].votes;
        }
    }

    return numberOfBetterThan;
}

export function getNumberOfPlayersWorseThan(
    userPointsIndex: number,
    allPlayerPoints: ResultsType,
) {
    let numberOfWorseThan = 0;

    for (let i = userPointsIndex - 1; i >= 0; i--) {
        if (allPlayerPoints[i].votes) {
            numberOfWorseThan += allPlayerPoints[i].votes;
        }
    }

    return numberOfWorseThan;
}

function getNumberOfPlayersSameResult(
    userPointsIndex: number,
    allPlayerPoints: ResultsType,
) {
    if (allPlayerPoints[userPointsIndex]?.votes) {
        return allPlayerPoints[userPointsIndex].votes;
    }
    return 0;
}

export default function calculatePointsPercentage(
    allPlayerPoints: ResultsType,
    userPointsIndex: number,
    resultComparison: ResultComparisonType,
) {
    const totalNumberOfPlayers = getTotalNumberOfPlayers(allPlayerPoints);

    let partOfPlayers = 0;
    switch (resultComparison) {
        case "betterResult":
            partOfPlayers = getNumberOfPlayersBetterThan(
                userPointsIndex,
                allPlayerPoints,
            );
            break;
        case "worseResult":
            partOfPlayers = getNumberOfPlayersWorseThan(
                userPointsIndex,
                allPlayerPoints,
            );
            break;
        default:
            partOfPlayers = getNumberOfPlayersSameResult(
                userPointsIndex,
                allPlayerPoints,
            );
    }

    return Math.round((partOfPlayers / totalNumberOfPlayers) * 100);
}
