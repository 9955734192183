import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import TheListAnswerOption from "./theListAnswerOption";
import {
    THE_LIST_TOGGLE_OPTION,
    QUESTIONS_SET_ANSWER,
    QUESTIONS_RESET_ANSWER,
} from "../../../redux/actions";
import calculateStarLevel from "../../../helpers/calculateStarLevel";
import { getStore } from "../../../redux/configureStore";

function TheListAnswer(props) {
    const theList = useSelector((state) => state.theList);
    const dispatch = useDispatch();

    useEffect(
        function setAnswer() {
            if (theList.noToSelect === theList.selected) {
                let isCorrect = true;

                for (let i = 0; i < theList.options.length; i++) {
                    if (
                        theList.options[i].isCorrect !==
                        theList.options[i].selected
                    ) {
                        isCorrect = false;
                    }
                }

                const storeState = getStore().getState();
                const answerTime =
                    storeState.countdown.duration - storeState.countdown.now;

                dispatch({
                    type: QUESTIONS_SET_ANSWER,
                    id: props.element.id,
                    answer: theList.options,
                    isCorrect: isCorrect,
                    allCorrect: isCorrect,
                    points: isCorrect ? 3 : 0,
                    answerTime: answerTime * 1000,
                    starLevel: isCorrect
                        ? calculateStarLevel(answerTime, props.element)
                        : 0,
                });
            } else {
                dispatch({
                    type: QUESTIONS_RESET_ANSWER,
                    id: props.element.id,
                });
            }
        },
        [
            dispatch,
            props.element,
            theList.noToSelect,
            theList.options,
            theList.selected,
        ],
    );

    const _onChange = useCallback(
        (index, selected) => {
            let direction = selected ? 1 : -1;

            dispatch({
                type: THE_LIST_TOGGLE_OPTION,
                index: index,
                selected: selected,
                direction: direction,
            });
        },
        [dispatch],
    );

    let canSelect = theList.noToSelect > theList.selected;

    return (
        <div className="svt_the-list">
            <h1 className="svt_the-list__header">{props.element.question}</h1>

            <p className="svt_the-list__select-info">
                {theList.selected === 0 ? (
                    <span>Markera de {theList.noToSelect} rätta svaren</span>
                ) : (
                    <span>
                        Du har markerat {theList.selected} av{" "}
                        {theList.noToSelect}
                    </span>
                )}
            </p>

            <ul className="svt_the-list__list">
                {theList.options.map((item, i) => {
                    return (
                        <TheListAnswerOption
                            key={i}
                            index={i}
                            item={item}
                            canSelect={canSelect}
                            changeHandler={_onChange}
                        />
                    );
                })}
            </ul>
        </div>
    );
}

export default TheListAnswer;

TheListAnswer.propTypes = {
    element: PropTypes.object.isRequired,
};
