import React from "react";
import PropTypes from "prop-types";
import duoCom from "@svt/duo-communication";
import * as Sentry from "@sentry/react";

export default class ErrorBoundary extends React.Component {
    constructor() {
        super();

        this.state = { hasError: false };
    }

    componentDidCatch(error, errorInfo) {
        if (import.meta.env.DEV) {
            console.log(error);
        }

        if (import.meta.env.VITE_SENTRY_ENABLED === "true") {
            Sentry.withScope((scope) => {
                scope.setExtras(errorInfo);
                Sentry.captureException(error);
            });
        }

        this.setState({ hasError: true });
    }

    _close() {
        if (duoCom.supports("disableActiveMode")) {
            duoCom.disableActiveMode();
        }

        if (duoCom.supports("closeView")) {
            duoCom.closeView();
        }
    }

    render() {
        if (this.state.hasError) {
            return (
                <div style={{ textAlign: "center" }}>
                    {this.props.errorTextComponent ? (
                        this.props.errorTextComponent
                    ) : (
                        <h1>Hoppsan där spårade vi ur</h1>
                    )}
                    {this.props.showCloseGame && (
                        <button type="button" onClick={this._close}>
                            Stäng spelet
                        </button>
                    )}
                </div>
            );
        }

        return this.props.children;
    }
}

ErrorBoundary.defaultProps = {
    showCloseGame: true,
};

ErrorBoundary.propTypes = {
    children: PropTypes.node.isRequired,
    showCloseGame: PropTypes.bool,
    errorTextComponent: PropTypes.node,
};
