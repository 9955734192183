import React from "react";
import PropTypes from "prop-types";

export default class QuestionAnswersTheList extends React.Component {
    render() {
        let options;
        let noOfCorrect = 0;
        let noOfUserCorrect = 0;
        let allCorrect = false;

        if (this.props.question.hasAnswered) {
            options = this.props.question.answer;

            for (var i = 0; i < options.length; i++) {
                if (options[i].isCorrect) {
                    noOfCorrect++;

                    if (options[i].selected) {
                        noOfUserCorrect++;
                    }
                }
            }

            allCorrect = noOfUserCorrect === noOfCorrect;
        } else {
            options = this.props.question.correctAnswer;
        }

        let header;

        if (allCorrect) {
            header = `Du grejade det, ${this.props.question.points} poäng!`;
        } else if (this.props.question.hasAnswered) {
            header = "Typiskt! För poäng krävs alla rätt.";
        } else {
            header = "Inget svar, ingen poäng!";
        }

        return (
            <div className="svt_the-list">
                <h1 className="svt_the-list__results-header">{header}</h1>

                <ul className="svt_the-list__list">
                    {options.map((item, j) => {
                        let modifier;

                        if (this.props.question.hasAnswered) {
                            if (item.selected) {
                                if (item.isCorrect) {
                                    if (allCorrect) {
                                        modifier = "-selected-all-correct";
                                    } else {
                                        modifier = "-selected-correct";
                                    }
                                } else {
                                    modifier = "-selected-incorrect";
                                }
                            } else if (item.isCorrect) {
                                modifier = "-not-selected-correct";
                            } else {
                                modifier = "-not-selected-incorrect";
                            }
                        } else if (item.isCorrect) {
                            modifier = "-no-answer-correct";
                        } else {
                            modifier = "-no-answer-incorrect";
                        }

                        return (
                            <li key={j} className="svt_the-list__list-item">
                                <div
                                    className={
                                        "svt_the-list__label " + modifier
                                    }
                                >
                                    {item.option}
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }
}

QuestionAnswersTheList.propTypes = {
    question: PropTypes.object.isRequired,
};
