import React from "react";
import PropTypes from "prop-types";

import AnswerBoxDnd from "./answerBox/answerBoxDnd";

export default class QuestionAnswersDnd extends React.Component {
    render() {
        const { correctAnswer, answer } = this.props.question;

        return (
            <div className="svt_dnd__results">
                <ul className="svt_dnd__results-list">
                    {correctAnswer.map((singleCorrectAnswer, i) => {
                        return (
                            <li className="svt_dnd__results-list-item" key={i}>
                                <AnswerBoxDnd
                                    label={singleCorrectAnswer.label}
                                    answer={answer ? answer[i].answer : null}
                                    correctAnswer={
                                        singleCorrectAnswer.correctAnswer
                                    }
                                    isCorrect={
                                        answer ? answer[i].isCorrect : false
                                    }
                                />
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }
}

QuestionAnswersDnd.propTypes = {
    question: PropTypes.object.isRequired,
};
