import * as React from "react";
const SvgClosestWins = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 42, height: 42, className: "svt_icon", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M21 42A21 21 0 1 0 0 21a21 21 0 0 0 21 21m0-2A19 19 0 1 0 2 21a19 19 0 0 0 19 19", style: {
  fill: "#45346b",
  fillRule: "evenodd"
} }), /* @__PURE__ */ React.createElement("path", { d: "M21 22.39a4.25 4.25 0 1 1 4.25-4.25A4.25 4.25 0 0 1 21 22.39m0-7a2.75 2.75 0 1 0 2.75 2.75A2.75 2.75 0 0 0 21 15.39", style: {
  fill: "#7a6b96"
} }), /* @__PURE__ */ React.createElement("path", { d: "m21 34.08-.54-.56c-.38-.39-9.21-9.67-9.21-15.52a9.75 9.75 0 0 1 19.5 0c0 5.9-8.83 15.13-9.21 15.52Zm0-24.33A8.26 8.26 0 0 0 12.75 18c0 4.48 6.34 11.79 8.25 13.9 1.92-2.1 8.25-9.38 8.25-13.9A8.26 8.26 0 0 0 21 9.75", style: {
  fill: "#7a6b96"
} }));
export default SvgClosestWins;
