import { HighscoreAggregatedResponseType } from "@svt/duo-communication/dist/api/highscore-types";

import TopListShared from "../../../shared/toplist/shared";
import TopListCategory from "../../../shared/toplist/category";
import { ToplistResponseSeasonPostCodeType } from "../../../../types";
import useGeography from "./useGeography";

const TopListSeason = ({
    bestSeasonList,
}: {
    bestSeasonList: HighscoreAggregatedResponseType;
}) => {
    // @ts-expect-error - fix type
    const geography = useGeography({
        county: bestSeasonList.county,
        municipality: bestSeasonList.municipality,
        view: "start",
    } as ToplistResponseSeasonPostCodeType);

    return (
        <div className="history-rows -season">
            <h2 className="history-rows__title">
                Säsongstopplista i ditt område
            </h2>

            <TopListCategory
                categories={[
                    ...geography,
                    {
                        header: "Sverige",
                        name: "Sverige",
                        toplist: (
                            <>
                                <TopListShared
                                    topList={bestSeasonList.list}
                                    user={bestSeasonList.user}
                                />
                            </>
                        ),
                    },
                ]}
            />
        </div>
    );
};

export default TopListSeason;
