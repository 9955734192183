import LViS from "@monterosa/lvis-api";
import questionTypes from "./questionTypes";

const resultTypes = {
    question_answers_points: true,
};

type Option = {
    option: string;
    is_correct: boolean;
};

type QuestionTypes = {
    multi_choice_type: string;
    star_3: number;
    star_2: number;
    star_1: number;
    question_1: string;
    options_1: Option[];
    question_2: string;
    options_2: Option[];
    question_3: string;
    options_3: Option[];
    train_1_points: number;
    train_2_points: number;
};

type GetHistoryType = {
    id: string;
    publishedAt: number;
    updatedAt: number;
    duration: number;
    durationLeft: number;
    getId: () => string;
    getContentType: () =>
        | "city"
        | "multi_choice"
        | "dnd"
        | "the_list"
        | "closest_wins"
        | "question_answers_points"
        | "user_points_statistics_total"
        | "user_points_statistics_city_1"
        | "user_points_statistics_city_2"
        | "user_points_statistics_city_3";
    getDurationLeft: () => number;
    getCustomFields: () => QuestionTypes;
    contentType: string;
};

export function getQuestionElements() {
    const questionElements = LViS.getEvent()
        .getHistory()
        .filter(
            (el) =>
                questionTypes[
                    el.getContentType() as
                        | "city"
                        | "multi_choice"
                        | "dnd"
                        | "the_list"
                        | "closest_wins"
                ],
        );

    return questionElements;
}

export function getResultElements() {
    const resultElements = LViS.getEvent()
        .getHistory()
        .filter(
            (el) =>
                resultTypes[el.getContentType() as "question_answers_points"],
        );

    return resultElements;
}

export function getPreviousQuestion() {
    let lastQuestion: GetHistoryType | null = null;

    const questionElements = getQuestionElements();

    if (questionElements.length > 0) {
        //@ts-expect-error - fix type
        lastQuestion = questionElements[questionElements.length - 1];
    }

    return lastQuestion;
}

export function getQuestionIndex() {
    if (LViS.getEvent()) {
        //If we go back to start from menu, lvis event is null and getQuestionIndex will fail
        return getQuestionElements().length;
    }

    return null;
}

export function getCityIndex() {
    const cityElements = LViS.getEvent()
        .getHistory()
        .filter((el) => el.getContentType() === "city");

    return cityElements.length;
}

export function getElementIndex() {
    const elements = LViS.getEvent().getHistory();

    return elements.length;
}
