import IllustrationStars from "../../../../assets/icons/illustrationStars.svg?react";

export default function StarsSlide() {
    return (
        <>
            <IllustrationStars />
            <h1 className="onBoarding__title">Sikta mot stjärnorna</h1>
            <p>
                Ju snabbare du svarar desto fler stjärnor får du.
                <br />
                <br /> Om du eller dina vänner får många stjärnor så betyder det
                att ni har kunnat svaren utan ledtrådar från burarna.
            </p>
        </>
    );
}
