import { useState } from "react";

import PlayModal from "./playModal";

export default function UserNotPlayed({
    episodeNumber,
}: {
    episodeNumber: string;
}) {
    const [statePlayModalShow, setStatePlayModalShow] = useState(false);

    function _showModalPlay() {
        setStatePlayModalShow(true);
    }

    function hideModalPlay() {
        setStatePlayModalShow(false);
    }

    return (
        <>
            <button
                type="button"
                onClick={() => {
                    _showModalPlay();
                }}
                className="episodeList__buttonPlay"
                data-testid={`play-episode-button-${episodeNumber}`}
            >
                Spela avsnitt
            </button>
            {statePlayModalShow && (
                <PlayModal
                    hideModalPlay={() => {
                        hideModalPlay();
                    }}
                    episodeNumber={episodeNumber}
                />
            )}
        </>
    );
}
