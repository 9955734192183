import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { analytics } from "@svt/duo-shared-components";
import SearchIcon from "@svt/duo-shared-assets/assets/icons/search.svg?react";
import duoCom from "@svt/duo-communication";
import LViS from "@monterosa/lvis-api";

import {
    CITY_SET,
    CITY_CLEAR,
    CITY_MOVE,
    IS_TYPING_TRUE,
    IS_TYPING_FALSE,
    QUESTIONS_SET_ANSWER,
    QUESTIONS_LOCK_ANSWER,
} from "../../../redux/actions";
import { getStore } from "../../../redux/configureStore";
import a11yAnnouncer from "../../../helpers/a11yAnnouncer";
import AutoCompleteOption from "./cityAutoCompleteOption";
import calculateStarLevel from "../../../helpers/calculateStarLevel";
import { getQuestionIndex } from "../../../helpers/questionHelpers";
import cityHandBreakAnimation from "./cityHandBreakAnimation";
import { getEventOffsetSeconds } from "../../../helpers/eventOffset";
import { findStatisticsCityElement } from "../../../helpers/findStatisticsElement";

let keyCodes = {
    ENTER: 13,
    UP_ARROW: 38,
    DOWN_ARROW: 40,
    ESC: 27,
};

class CityAutoComplete extends React.Component {
    constructor() {
        super();

        this.hasLocked = false;
        this.inputRef = React.createRef();
        this.buttonRef = React.createRef();

        this._search = this._search.bind(this);
        this._onKeyUp = this._onKeyUp.bind(this);
        this._onFocus = this._onFocus.bind(this);
        this._onBlur = this._onBlur.bind(this);
        this._enterKey = this._enterKey.bind(this);
        this._onClick = this._onClick.bind(this);
        this._lockAnswer = this._lockAnswer.bind(this);
    }

    componentDidUpdate() {
        let announceMessage;

        if (this.props.city.result.length) {
            if (this.props.city.selectedIndex === 0) {
                announceMessage =
                    this.props.city.result.length + " träffar visas";
            } else {
                announceMessage =
                    this.props.city.result[this.props.city.selectedIndex - 1];
            }
        }

        if (announceMessage) {
            a11yAnnouncer(announceMessage, "assertive");
        }
    }

    componentWillUnmount() {
        let eventValues = {};
        if (
            !this.props.questionState.answer &&
            this.inputRef.current.value === ""
        ) {
            eventValues.typed = "false";
        } else {
            eventValues.typed = "true";
        }

        if (this.props.questionState.answer) {
            eventValues.answered = "true";
        } else {
            eventValues.answered = "false";
        }

        if (this.hasLocked) {
            eventValues.locked = "true";
        } else {
            eventValues.locked = "false";
        }

        analytics.trackCustomEvent("city", "question", { eventValues });
        this._lockAnswer();

        // In case user was typing
        this.props.dispatch({
            type: IS_TYPING_FALSE,
        });

        // Force iOS to hide keyboard
        this.inputRef.current.blur();
    }

    _getIsCorrect(answer) {
        let isCorrect = false;
        let trimmedAnswer = answer.toLowerCase().trim();

        // Array of correct answers
        for (
            var i = 0;
            i < this.props.questionState.correctAnswer.length && !isCorrect;
            i++
        ) {
            if (
                trimmedAnswer ===
                this.props.questionState.correctAnswer[i].toLowerCase().trim()
            ) {
                isCorrect = true;
            }
        }

        return isCorrect;
    }

    _search() {
        let value = this.inputRef.current.value;

        this.props.dispatch({
            type: CITY_SET,
            data: value,
        });
    }

    _clear(keepInput = false) {
        this.props.dispatch({
            type: CITY_CLEAR,
        });

        if (!keepInput) {
            this.inputRef.current.value = "";
        }
    }

    _enterKey() {
        if (
            this.props.city.result.length &&
            this.props.city.selectedIndex !== 0
        ) {
            this._setAnswer(
                this.props.city.result[this.props.city.selectedIndex - 1].name,
            );
        }
    }

    _onClick(value) {
        this._setAnswer(value);
    }

    _setAnswer(value) {
        const isCorrect = this._getIsCorrect(value);

        this.props.dispatch({
            type: QUESTIONS_SET_ANSWER,
            id: this.props.element.id,
            answer: value,
            isCorrect: isCorrect,
            allCorrect: isCorrect,
            points: 0, // is set at lock
            answerTime: null, // is set at lock
            starLevel: null, // is set at lock
        });

        this._clear();
    }

    _sendScoreToStatisticsCityAnswers(cityLevel) {
        const cityElement = findStatisticsCityElement();
        if (!cityElement) {
            return;
        }
        const scoreIndex = this.props.questionState.isCorrect
            ? cityLevel / 2
            : 0;

        cityElement.vote({ [scoreIndex]: 1 });
    }

    _lockAnswer() {
        if (!this.hasLocked && this.props.questionState.answer) {
            this.hasLocked = true;

            const storeState = getStore().getState();
            this._sendScoreToStatisticsCityAnswers(storeState.cityLevel);
            const answerTime =
                new Date().getTime() -
                this.props.stateStartTime +
                (getStore().getState().countdown.duration -
                    getStore().getState().countdown.durationLeft) *
                    1000; //We take the answer time and add elapsed time of element if the person has synced in to the element

            this.props.dispatch({
                type: QUESTIONS_LOCK_ANSWER,
                id: this.props.element.id,
                points: this.props.questionState.isCorrect
                    ? storeState.cityLevel
                    : 0,
                answerTime: answerTime,
                starLevel: this.props.questionState.allCorrect
                    ? calculateStarLevel(answerTime / 1000, this.props.element)
                    : 0,
            });

            if (duoCom.Interactions) {
                duoCom.Interactions.submit({
                    season: LViS.Project.getSettings().season_number.toString(),
                    episode: LViS.getEvent()
                        .getCustomFields()
                        .episode_number.toString(),
                    segment: getQuestionIndex().toString(),
                    type: "LOCK_CITY",
                    data: {
                        answerTime: getEventOffsetSeconds(),
                        pointsLevel: storeState.cityLevel,
                    },
                }).catch(() => {
                    // Do nothing
                });
            }
        }
    }

    _keyMove(direction) {
        let currentSelection = this.props.city.selectedIndex;
        let newSelection = currentSelection + direction;

        if (newSelection < 1) {
            newSelection = this.props.city.result.length;
        } else if (newSelection > this.props.city.result.length) {
            newSelection = 1;
        }

        this.props.dispatch({
            type: CITY_MOVE,
            data: newSelection,
        });
    }

    _onFocus() {
        this.props.dispatch({
            type: IS_TYPING_TRUE,
        });

        this._search();
    }

    _onKeyUp(keyEvent) {
        let key = keyEvent.keyCode;

        switch (key) {
            case keyCodes.ESC:
                this._clear();
                break;
            case keyCodes.UP_ARROW:
                this._keyMove(-1);
                break;
            case keyCodes.DOWN_ARROW:
                this._keyMove(1);
                break;
            case keyCodes.ENTER:
                this._enterKey();
                break;
            default:
                this._search();
        }
    }

    _onBlur() {
        setTimeout(() => {
            this.props.dispatch({
                type: IS_TYPING_FALSE,
            });

            this._clear(true);
            if (this.props.questionState.answer !== null) {
                cityHandBreakAnimation(this.buttonRef.current);
            }
        }, 200);
    }

    render() {
        let selectedIndex = this.props.city.selectedIndex;
        let hasResults = this.props.city.result.length > 0;
        let acContent = null;

        if (hasResults) {
            acContent = (
                <ul
                    className="svt_ac__suggest-list"
                    role="listbox"
                    aria-label="Sökresultat"
                >
                    {this.props.city.result.map((item, i) => {
                        let active = selectedIndex === i + 1;

                        return (
                            <AutoCompleteOption
                                key={item.name}
                                item={item.name}
                                clickHandler={this._onClick}
                                active={active}
                            />
                        );
                    })}
                </ul>
            );
        } else if (
            this.props.city.searchText &&
            this.props.city.searchText.length > 0 &&
            this.props.city.result.length === 0
        ) {
            acContent = (
                <p className="svt_ac__no-result">
                    Platsen finns inte, har du stavat rätt?
                </p>
            );
        }

        return (
            <div className="svt_city-input">
                <h1 className="svt_city-input__header">Sök och välj plats</h1>

                {!this.props.isTyping && (
                    <p className="svt_city-input__info">
                        Glöm inte att låsa in ditt svar!
                    </p>
                )}

                <div className="svt_ac">
                    <label className="svt_ac__label" htmlFor="svt_ac__input">
                        Sök plats, sökförslag kommer att visas under fältet:
                    </label>

                    <div className="svt_ac__wrapper">
                        <div className="svt_ac__input-wrapper">
                            <input
                                ref={this.inputRef}
                                id="svt_ac__input"
                                className="svt_ac__input"
                                placeholder={
                                    this.props.questionState.answer
                                        ? "Ändra plats"
                                        : "Välj plats"
                                }
                                onKeyUp={this._onKeyUp}
                                onFocus={this._onFocus}
                                onBlur={this._onBlur}
                                autoComplete="off"
                                role="combobox"
                                aria-controls="svt_ac__suggest"
                                aria-autocomplete="list"
                                aria-expanded={hasResults}
                                aria-owns="svt_ac__suggest"
                                aria-activedescendant={
                                    selectedIndex > 0
                                        ? "svt_ac__suggest-active"
                                        : null
                                }
                            />

                            <SearchIcon className="svt_ac__search-icon" />
                        </div>

                        <div id="svt_ac__suggest" className="svt_ac__suggest">
                            {acContent}
                        </div>
                    </div>
                </div>

                {this.props.isTyping ? (
                    <p>
                        {this.props.questionState.answer ? (
                            <>
                                För att ändra ditt svar,
                                <br /> välj en ny plats i listan
                            </>
                        ) : (
                            <>
                                Du har inte angett ett svar än.
                                <br /> Välj plats för att fortsätta.
                            </>
                        )}
                    </p>
                ) : (
                    <div>
                        {this.props.questionState.answer ? (
                            <p className="svt_city-input__selected">
                                {this.props.questionState.answer}
                            </p>
                        ) : (
                            <p className="svt_city-input__no-answer">
                                Du har inte angett ett svar
                            </p>
                        )}

                        <button
                            type="button"
                            className="svt_city-input__submit"
                            onClick={this._lockAnswer}
                            disabled={!this.props.questionState.answer}
                            ref={this.buttonRef}
                        >
                            Lås in svar
                        </button>
                    </div>
                )}
            </div>
        );
    }
}

CityAutoComplete.propTypes = {
    element: PropTypes.object.isRequired,
    stateStartTime: PropTypes.number.isRequired,
    city: PropTypes.object.isRequired,
    questionState: PropTypes.object.isRequired,
    isTyping: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
};

export default connect((state) => ({
    city: state.city,
    isTyping: state.isTyping,
}))(CityAutoComplete);
