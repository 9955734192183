import React from "react";
import PropTypes from "prop-types";
import LoaderImg from "@svt/duo-shared-assets/assets/icons/loader.svg?react";

import { hideToMatchImageSnapshotClass } from "../../helpers/imageTest";

export default class ContentStatus extends React.Component {
    render() {
        if (this.props.error) {
            return (
                <div className="svt_content-status">
                    <p
                        dangerouslySetInnerHTML={{
                            __html: this.props.errorHTMLText,
                        }}
                    />
                </div>
            );
        }

        if (!this.props.loaded) {
            return (
                <div className="svt_content-status">
                    <h1>Laddar innehåll</h1>
                    <LoaderImg className={hideToMatchImageSnapshotClass} />
                </div>
            );
        }

        return this.props.children();
    }
}

ContentStatus.defaultProps = {
    errorHTMLText: "Uh oh! Något gick fel<br />Du kan väl testa igen?",
};

ContentStatus.propTypes = {
    loaded: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    children: PropTypes.func.isRequired,
    errorHTMLText: PropTypes.string,
};

/*
Usage:

<ContentStatus loaded={} error={}>
    {() => {
        return (
            <div>
                hej
            </div>
        )
    }}
</ContentStatus>
*/
