import { CLEAR_EVENT, SET_SENT_HIGHSCORE } from "../actions";

const initialState = false;

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case CLEAR_EVENT:
            return initialState;
        case SET_SENT_HIGHSCORE:
            return true;
        default:
            return state;
    }
}
