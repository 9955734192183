import { useCallback, useState } from "react";
import { useKeenSlider } from "keen-slider/react";
import { localStorageHelper } from "@svt/duo-shared-components";
import Cross from "@svt/duo-shared-assets/assets/icons/cross.svg?react";
import ArrowTail from "@svt/duo-shared-assets/assets/icons/arrowTail.svg?react";

import AudioSyncSlide from "./audioSyncSlide";
import StarsSlide from "./starsSlide";
import FriendsSlide from "./friendsSlide";
import AchievementsSlide from "./achievementsSlide";
export const onboardingKey = "seenOnboarding2024";

export default function OnBoarding({
    setShowOnboarding,
}: {
    setShowOnboarding: (value: boolean) => void;
}) {
    const [loaded, setLoaded] = useState(false);

    const [stateSlideIndex, setStateSlideIndex] = useState(0);
    const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
        rubberband: false,
        slideChanged: (ev) => {
            setStateSlideIndex(ev.track.details.rel);
        },
        created() {
            setLoaded(true);
        },
    });
    const nextSlide = useCallback(() => {
        if (instanceRef.current) {
            instanceRef.current.next();
        }
    }, [instanceRef]);

    const finished = useCallback(() => {
        localStorageHelper.setState(onboardingKey, true);
        setShowOnboarding(false);
    }, [setShowOnboarding]);

    return (
        <div className="onBoarding__wrapper">
            <div ref={sliderRef} className={"keen-slider onBoarding__slider"}>
                <div className="keen-slider__slide onBoarding__slide">
                    <AudioSyncSlide />
                </div>
                <div className="keen-slider__slide onBoarding__slide">
                    <StarsSlide />
                </div>
                <div className="keen-slider__slide onBoarding__slide">
                    <FriendsSlide />
                </div>
                <div className="keen-slider__slide onBoarding__slide">
                    <AchievementsSlide />
                </div>
            </div>

            <button className="onBoarding__buttonClose" onClick={finished}>
                <span className="onBoarding__buttonText">Stäng</span>{" "}
                <Cross width={13} height={13} />
            </button>

            {loaded && instanceRef.current && (
                <div className="onBoarding__footer">
                    {stateSlideIndex ===
                    (instanceRef.current?.track.details.slides.length ?? 0) -
                        1 ? (
                        <div>
                            <button
                                className="onBoarding__buttonNext"
                                onClick={finished}
                            >
                                Okej
                                <ArrowTail
                                    width={16}
                                    height={16}
                                    className="onBoarding__buttonIcon"
                                />
                            </button>
                            <div className="onBoarding__dotWrapper">
                                {[
                                    ...Array(
                                        instanceRef.current?.track.details
                                            .slides.length,
                                    ).keys(),
                                ].map((idx) => {
                                    return (
                                        <div
                                            className={`${
                                                stateSlideIndex === idx
                                                    ? "onBoarding__dotActive"
                                                    : "onBoarding__dot"
                                            }`}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    ) : (
                        <div>
                            <button
                                className="onBoarding__buttonNext"
                                onClick={nextSlide}
                            >
                                Nästa
                                <ArrowTail
                                    width={16}
                                    height={16}
                                    className="onBoarding__buttonIcon"
                                />
                            </button>
                            <div className="onBoarding__dotWrapper">
                                {[
                                    ...Array(
                                        instanceRef.current?.track.details
                                            .slides.length,
                                    ).keys(),
                                ].map((idx) => {
                                    return (
                                        <div
                                            className={`${
                                                stateSlideIndex === idx
                                                    ? "onBoarding__dotActive"
                                                    : "onBoarding__dot"
                                            }`}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
